<div class="collection-view">
    <div class="collection-header">
        <img class="collection-cover" [src]="helper.cdn + 'collections/' + userInfo.id + '/' + collection.cover" alt="">
        <div class="collection-userinfo">
            <img [src]="helper.returnAvatar(userInfo.id,userInfo.avatar)" alt="">
            <h3>{{userInfo.firstname}} {{userInfo.lastname}}<svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
            <span class="small-username">@{{userInfo.name}}</span>
        </div>
    </div>
    <div class="collection-about">
        <h3>{{collection.name}}</h3>
        <p>{{collection.about}}</p>
    </div>
    <div class="collection-media">
        <ng-container *ngFor="let media of collection.media">
            <div class="collection-image" *ngIf="media.file.includes('.jpg')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
                <img [src]="helper.cdn + 'collections/'+userInfo.id + '/photos/'+media.file" alt="">
            </div>
            <div class="collection-video" *ngIf="media.file.includes('.mp4')">
                <svg-icon src="assets/icons/video.svg"></svg-icon>
                <video [src]="helper.cdn + 'collections/'+userInfo.id + '/videos/'+media.file" autoplay [muted]="muted" playsinline loop></video>
            </div>
            <div class="collection-audio" *ngIf="media.file.includes('.mp3')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
            </div>
            <div class="collection-file" *ngIf="media.file && !media.file.includes('.jpg') && !media.file.includes('.mp4') && !media.file.includes('.mp3')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
            </div>
        </ng-container>
    </div>
    <div class="collection-media">
        <ng-container *ngFor="let media of collection.media">
            <div class="collection-image" *ngIf="media.file.includes('.jpg')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
                <img [src]="helper.cdn + 'collections/'+userInfo.id + '/photos/'+media.file" alt="">
            </div>
            <div class="collection-video" *ngIf="media.file.includes('.mp4')">
                <svg-icon src="assets/icons/video.svg"></svg-icon>
                <video [src]="helper.cdn + 'collections/'+userInfo.id + '/videos/'+media.file" autoplay [muted]="muted" playsinline loop></video>
            </div>
            <div class="collection-audio" *ngIf="media.file.includes('.mp3')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
            </div>
            <div class="collection-file" *ngIf="media.file && !media.file.includes('.jpg') && !media.file.includes('.mp4') && !media.file.includes('.mp3')">
                <svg-icon src="assets/icons/post.svg"></svg-icon>
            </div>
        </ng-container>
    </div>
    <button class="buy-button">
        <span>Купить эту коллекцию навсегда</span>
        <span>Всего: € {{collection.price}}</span>
    </button>
</div>
<!--
<div class="close-button" [mat-dialog-close]="true">
    <svg-icon src="assets/icons/close.svg"></svg-icon>
</div>
-->
