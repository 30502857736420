import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {SocketService} from "../../services/socket.service";
import {AuthenticationService} from "../../services/authentication.service";
import {SelectUserMessagesComponent} from "../select-user-messages/select-user-messages.component";
import {Message} from "../../chat/chat.component";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-chat-dialog',
  templateUrl: './chat-dialog.component.html',
  styleUrls: ['./chat-dialog.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity:0}))
      ])
    ])
  ]
})
export class ChatDialogComponent implements OnInit {
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  private printsStatusTimeout = null;
  emojis = require('src/assets/json/emoji.json');
  message: string = '';
  chat_id: number;
  printing: boolean = false;
  viewEmojis: boolean = false;
  viewChat: boolean = false;
  sendMessageLoading: boolean = false;
  isLoading: boolean = true;
  muted: boolean = true;
  files: any[] = [];
  user_id: any;
  chat_name: any;
  chat_username: any;
  chat_avatar: any;
  messages: any;
  filessize: number = 0;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
      public helper: HelperService,
      private socketService: SocketService,
      public authService: AuthenticationService,
  ) {
  }

  async ngOnInit() {
    this.viewChat = true;
    this.user_id = this.data.userinfo.id;
    this.chat_name = this.data.userinfo.firstname + ' ' +this.data.userinfo.lastname;
    this.chat_username = this.data.userinfo.name;
    this.chat_avatar = this.data.userinfo.avatar;

    const res = await this.helper.getMessages(this.user_id).toPromise()
    if (res.status){
      this.messages = res.messages;
      this.chat_id = res.chat_id;
      this.socketService.connectToRoom(res.chat_id);
      this.scrollToBottom('auto');
      this.isLoading = false;
      this.socketService.getNewMessageChat().subscribe(async (data)=> {
        if (this.chat_id === data.chat_id){
          this.messages.push(data.message)
          this.scrollToBottom('smooth');
          if (data.message.user_id !== this.authService.currentUser.id){
            await this.readMessages(data.message.id,data.chat_id)
          }
        }
      });
      this.socketService.userPrintsMessage().subscribe(res => {
        if (res.chat_id === this.chat_id) {
          this.printing = true;
        }
        clearTimeout(this.printsStatusTimeout);
        this.printsStatusTimeout = setTimeout(() => {
          this.printing = false;
        }, 1500);
      });
      this.socketService.delMessageChat().subscribe(async (data)=> {
        const indexHereChat = this.messages.findIndex(e => e.id === data.messageid)
        if (indexHereChat>=0){
          this.messages.splice(indexHereChat,1);
        }
      });
    }
  }

  backButton() {
    this.socketService.disConnectToRoom(this.chat_id);
    this.chat_id = null;
    this.user_id = null;
    this.chat_avatar = null;
    this.chat_username = null;
    this.chat_name = null;
    this.files = [];
    this.message = '';
    this.viewChat = !this.viewChat;
    this.isLoading = true;
    this.filessize = 0;
    this.messages = [];
  }

  scrollToGroup(group) {
    let el = document.getElementById(group);
    el.scrollIntoView({behavior: 'auto', block: 'start', inline: 'nearest'});
  }

  printingMessage() {
    this.socketService.printsMessage({
      chat_id: this.chat_id,
      user_id: this.authService.currentUser.id,
    });
  }

  onChange(ev) {
    if ((this.files.length + ev.target.files.length) <= 5) {
      for (let row of ev.target.files) {
        const reader = new FileReader();
        console.log(row)
        reader.onloadend = () => {
          this.files.push({
            preview: reader.result,
            file: row,
            size: row.size,
            type: row.type.split('/')[0],
          });
        }
        reader.readAsDataURL(row);
      }
      for (let file of this.files) {
        this.filessize = this.filessize + file.size;
      }
      this.scrollToBottom('smooth')
    } else {
      let otherText = '';
      if (5 - this.files.length > 0) {
        otherText = 'Вы можете добавить еще ' + (5 - this.files.length).toString()
      }
      this.helper.alertMessage('Ошибка!', 'В одном сообщении Вы можете отправить только 5 медиафалов.' + otherText)
    }
  }

  attachFile() {
    let fileInput = document.getElementById('fileInput');
    this.clearFileInput(document.getElementById('fileInput'));
    console.log(fileInput);
    if (fileInput) fileInput.click();
    else console.log('ERROR: cannot find file input');
  }

  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch (ex) {
    }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }

  async deleteMessage(messageid, chat_id) {
    const indexChat = this.helper.chats.findIndex(e => e.id === chat_id)
    if (indexChat >= 0) {
      const indexMessage = this.helper.chats[indexChat].messages.findIndex(e => e.id === messageid)
      if (indexMessage >= 0) {
        this.helper.chats[indexChat].messages.splice(indexMessage, 1);
      }
    }
    const indexHereChat = this.messages.findIndex(e => e.id === messageid)
    if (indexHereChat >= 0) {
      this.messages.splice(indexHereChat, 1)
    }
    await this.helper.delMessage(messageid, chat_id).toPromise()
  }

  async sendMessage() {
    if ((this.message.length || this.files.length) && !this.sendMessageLoading) {
      this.sendMessageLoading = true;
      let formData = new FormData();
      for (let row of this.files) {
        formData.append("files", row.file);
      }
      let randomid = Math.floor(1000000000 + Math.random() * 8999999999);
      let messageData = {
        randomid: randomid,
        message: this.message,
        user_id: this.user_id,
      }
      const message: Message = {
        id: randomid,
        chat_id: this.chat_id,
        user_id: this.authService.currentUser.id,
        text: messageData.message,
        file: this.files,
        type: 1,
        edit: 0,
        report: 0,
        date_create: new Date().toString(),
        date_read: null
      };
      console.log(message)
      this.messages.push(message);
      this.files = [];
      this.filessize = 0;
      this.scrollToBottom('smooth');
      this.sendMessageLoading = false;

      this.message = '';
      formData.append('data', JSON.stringify(messageData))
      const res = await fetch("https://coc.lol:9125/main/sendMesssage", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + AuthenticationService.jwt,
          'Authorizationuuid': `${AuthenticationService.uuid}`,
        }
      });
      let resData = await res.json();
      if (resData.status) {
        const index = this.messages.findIndex(e => e.id === randomid)
        if (index >= 0) {
          this.messages[index].id = resData.id;
          this.messages[index].file = resData.file;

          const indexChat = this.helper.chats.findIndex(e => e.id === this.chat_id)
          if (indexChat >= 0) {
            this.helper.chats[indexChat].messages.unshift({
              id: this.messages[index].id,
              chat_id: this.chat_id,
              user_id: this.authService.currentUser.id,
              text: messageData.message,
              file: this.messages[index].file,
              type: 1,
              edit: 0,
              report: 0,
              date_create: new Date().toString(),
              date_read: null
            })
          } else {
            this.helper.chats.unshift({
              chat_image: this.chat_avatar,
              chat_name: this.chat_name,
              date_create: new Date().toISOString(),
              id: this.chat_id,
              image: '',
              lastmessage: new Date().toISOString(),
              messageCount: 1,
              messages: [{
                id: this.messages[index].id,
                chat_id: this.chat_id,
                user_id: this.authService.currentUser.id,
                text: messageData.message,
                file: this.messages[index].file,
                type: 1,
                edit: 0,
                report: 0,
                date_create: new Date().toString(),
                date_read: null
              }],
              name: this.chat_name,
              status: 1,
              type: 1,
              userCount: 2,
              user_id: this.user_id,
              userstatus: 0,
              view: 1
            })
          }

        }
      }
    }
  }

  addToComment(char) {
    this.message = this.message + char
  }

  deleteFile(index) {
    this.files.splice(index, 1)
  }

  viewEmogi() {
    this.viewEmojis = !this.viewEmojis
  }

  async readMessages(messageid, chat_id) {
    const indexChat = this.helper.chats.findIndex(e => e.id === chat_id)
    if (indexChat >= 0) {
      const indexMessage = this.helper.chats[indexChat].messages.findIndex(e => e.id === messageid)
      if (indexMessage >= 0) {
        this.helper.chats[indexChat].messages[indexMessage].date_read = new Date().toISOString();
      }
    }
    const indexHereChat = this.messages.findIndex(e => e.id === messageid)
    if (indexHereChat >= 0) {
      this.messages[indexHereChat].date_read = new Date().toISOString();
    }
    await this.helper.readMessage(messageid).toPromise()
  }

  scrollToBottom(behavior) {
    setTimeout(() => {
      try {
        this.chatContainer.nativeElement.scrollTo({
          behavior: behavior,
          block: "start",
          inline: "nearest",
          top: this.chatContainer.nativeElement.scrollHeight
        })
      } catch (err) {
      }
    }, 100)
  }

  viewMoreMessage(enable, id) {
    if (id < 1000000000) {
      let container: any = document.getElementById(id);
      if (container && !enable) {
        container.style.opacity = 0
      } else {
        container.style.opacity = 1
      }
    }
  }
}
