import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {SelectUserMessagesComponent} from "../../dialogs/select-user-messages/select-user-messages.component";

@Component({
  selector: 'app-faqhackacc',
  templateUrl: './faqhackacc.component.html',
  styleUrls: ['./faqhackacc.component.scss']
})
export class FaqhackaccComponent implements OnInit {

  constructor(
      private location: Location,
      public dialog: MatDialog,
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  back(){
    this.location.back();
  }

  ngOnInit(): void {

  }

}
