import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {FormsModule} from "@angular/forms";
import {ApiInterceptor} from "./services/api.interceptor";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import {AngularSvgIconModule} from "angular-svg-icon";
import { BroadcastsComponent } from './broadcasts/broadcasts.component';
import { FlicksComponent } from './flicks/flicks.component';
import {InViewportModule} from "ng-in-viewport";
import { SearchComponent } from './search/search.component';
import { CreateBroadcastComponent } from './create-broadcast/create-broadcast.component';
import { CreatePostComponent } from './create-post/create-post.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule} from "@angular/material/dialog";
import { ImageCropperModule } from 'ngx-image-cropper';
import { DickchComponent } from './dickch/dickch.component';
import { ChatComponent } from './chat/chat.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { NotifyComponent } from './notify/notify.component';
import {AngularCropperjsModule} from "angular-cropperjs";
import { CreateFlickComponent } from './create-flick/create-flick.component';
import { UserComponent } from './user/user.component';
import { ViewPostComponent } from './dialogs/view-post/view-post.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from "@angular/material/input";

import {SwiperModule} from "swiper/angular";
import { ViewFlickComponent } from './dialogs/view-flick/view-flick.component';
import { SelectUserMessagesComponent } from './dialogs/select-user-messages/select-user-messages.component';

import {LottieModule, provideLottieOptions} from 'ngx-lottie';
import player from 'lottie-web';
import {MatMenuModule} from '@angular/material/menu';
import {NgPipesModule} from 'ngx-pipes';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ViewMediaComponent } from './dialogs/view-media/view-media.component';
import { ViewAttachComponent } from './dialogs/view-attach/view-attach.component';
import { PopularComponent } from './popular/popular.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConnectBroadcastComponent } from './connect-broadcast/connect-broadcast.component';
import { RecommendedComponent } from './components/recommended/recommended.component';
import { XxxComponent } from './xxx/xxx.component';
import { CreateCollectionsComponent } from './create-collections/create-collections.component';
import { NgxFileDropModule } from "ngx-file-drop";
import { CollectionsComponent } from './collections/collections.component';
import { ViewCollectionComponent } from './dialogs/view-collection/view-collection.component';
import { RtmpComponent } from './rtmp/rtmp.component';
import { BectoauthorComponent } from './bectoauthor/bectoauthor.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import {MatSelectModule} from '@angular/material/select';
import { PriceandpostComponent } from './settings/priceandpost/priceandpost.component';
import { SendMoneyComponent } from './dialogs/send-money/send-money.component';
import { ChangeEmailComponent } from './settings/change-email/change-email.component';
import { MyDevicesComponent } from './settings/my-devices/my-devices.component';
import { FaqhackaccComponent } from './settings/faqhackacc/faqhackacc.component';
import { DelAccountComponent } from './settings/del-account/del-account.component';
import { ChatDialogComponent } from './dialogs/chat-dialog/chat-dialog.component';
import { SupportComponent } from './support/support.component';
import { PaymentsComponent } from './settings/payments/payments.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { SafetyComponent } from './settings/safety/safety.component';
import { AccountComponent } from './settings/account/account.component';
import { SettingComponent } from './setting/setting.component';
import { InformationComponent } from './settings/information/information.component';
import {LAZYLOAD_IMAGE_HOOKS, LazyLoadImageModule, ScrollHooks} from "ng-lazyload-image";
import { AddLinkComponent } from './dialogs/add-link/add-link.component';
import {DragDropModule} from "@angular/cdk/drag-drop";
import { SubscribersComponent } from './subscribers/subscribers.component';
import {NgxMaskModule} from "ngx-mask";
import { ChangePhoneComponent } from './settings/change-phone/change-phone.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        HomeComponent,
        SidebarComponent,
        BroadcastsComponent,
        FlicksComponent,
        SearchComponent,
        CreateBroadcastComponent,
        CreatePostComponent,
        DickchComponent,
        ChatComponent,
        FavoritesComponent,
        NotifyComponent,
        CreateFlickComponent,
        UserComponent,
        SelectUserMessagesComponent,
        PopularComponent,
        ConnectBroadcastComponent,
        RecommendedComponent,
        XxxComponent,
        CreateCollectionsComponent,
        CollectionsComponent,
        ViewPostComponent,
        ViewFlickComponent,
        ProfileComponent,
        ViewMediaComponent,
        ViewAttachComponent,
        ViewCollectionComponent,
        RtmpComponent,
        BectoauthorComponent,
        AccordionComponent,
        PriceandpostComponent,
        SendMoneyComponent,
        ChangeEmailComponent,
        MyDevicesComponent,
        FaqhackaccComponent,
        DelAccountComponent,
        ChatDialogComponent,
        SupportComponent,
        PaymentsComponent,
        SafetyComponent,
        AccountComponent,
        SettingComponent,
        InformationComponent,
        AddLinkComponent,
        SubscribersComponent,
        ChangePhoneComponent
    ],
    imports: [
        LazyLoadImageModule,
        InfiniteScrollModule,
        NgPipesModule,
        ImageCropperModule,
        MatSnackBarModule,
        BrowserModule,
        MatDialogModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatSelectModule,
        MatNativeDateModule,
        MatInputModule,
        MatMenuModule,
        AppRoutingModule,
        HttpClientModule,
        NgxFileDropModule,
        FormsModule,
        AngularSvgIconModule.forRoot(),
        LottieModule,
        InViewportModule,
        AngularCropperjsModule,
        BrowserAnimationsModule,
        SwiperModule,
        DragDropModule,
        NgxMaskModule.forRoot()
    ],
    providers: [
        MatDatepickerModule,
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'ru-RU'
        },
        { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true,
        },
        provideLottieOptions({
            player: () => player,
        }),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
