import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {HomeComponent} from "./home/home.component";
import {BroadcastsComponent} from "./broadcasts/broadcasts.component";
import {FlicksComponent} from "./flicks/flicks.component";
import {SearchComponent} from "./search/search.component";
import {CreateBroadcastComponent} from "./create-broadcast/create-broadcast.component";
import {CreatePostComponent} from "./create-post/create-post.component";
import {CanDeactivateGuard} from "./guards/can-deactivate.guard";
import {DickchComponent} from "./dickch/dickch.component";
import {ChatComponent} from "./chat/chat.component";
import {FavoritesComponent} from "./favorites/favorites.component";
import {NotifyComponent} from "./notify/notify.component";
import {CreateFlickComponent} from "./create-flick/create-flick.component";
import {UserComponent} from "./user/user.component";
import {PopularComponent} from "./popular/popular.component";
import {ConnectBroadcastComponent} from "./connect-broadcast/connect-broadcast.component";
import {CreateCollectionsComponent} from "./create-collections/create-collections.component";
import {CollectionsComponent} from "./collections/collections.component";
import {RtmpComponent} from "./rtmp/rtmp.component";
import {BectoauthorComponent} from "./bectoauthor/bectoauthor.component";
import {PriceandpostComponent} from "./settings/priceandpost/priceandpost.component";
import {ChangeEmailComponent} from "./settings/change-email/change-email.component";
import {MyDevicesComponent} from "./settings/my-devices/my-devices.component";
import {FaqhackaccComponent} from "./settings/faqhackacc/faqhackacc.component";
import {DelAccountComponent} from "./settings/del-account/del-account.component";
import {ChatDialogComponent} from "./dialogs/chat-dialog/chat-dialog.component";
import {SupportComponent} from "./support/support.component";
import {SettingComponent} from "./setting/setting.component";
import {SubscribersComponent} from "./subscribers/subscribers.component";
import {ChangePhoneComponent} from "./settings/change-phone/change-phone.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'Главная',
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Авторизация',
  },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Главная',
  },
  {
    path: 'flicks',
    component: FlicksComponent,
    title: 'Flicks',
  },
  {
    path: 'search',
    component: SearchComponent,
    title: 'Поиск',
  },
  {
    path: 'createBroadcast',
    component: CreateBroadcastComponent,
    title: 'Новая трансляция',
  },
  {
    path: 'createCollections',
    component: CreateCollectionsComponent,
    canDeactivate: [CanDeactivateGuard],
    title: 'Новая коллекция',
  },
  {
    path: 'setting',
    component: SettingComponent,
    title: 'Профиль',
  },
  {
    path: 'broadcasts',
    component: BroadcastsComponent,
    title: 'Профиль',
  },
  {
    path: 'createPost',
    component: CreatePostComponent,
    canDeactivate: [CanDeactivateGuard],
    title: 'Новый пост',
  },
  {
    path: 'createFlick',
    component: CreateFlickComponent,
    canDeactivate: [CanDeactivateGuard],
    title: 'Новый Flick',
  },
  {
    path: 'dickch',
    component: DickchComponent,
    title: 'ДикЧеккер',
  },
  {
    path: 'chat',
    component: ChatComponent,
    title: 'Чат',
  },
  {
    path: 'favorites',
    component: FavoritesComponent,
    title: 'Избранное',
  },
  {
    path: 'notify',
    component: NotifyComponent,
    title: 'Уведомления',
  },
  {
    path: 'popular',
    component: PopularComponent,
    title: 'Популярное'
  },
  {
    path: 'post/:id',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'flick/:id',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'b/:id',
    component: ConnectBroadcastComponent,
    pathMatch: 'full'
  },
  {
    path: 'collections',
    component: CollectionsComponent,
    pathMatch: 'full'
  },
  {
    path: 'rtmp',
    component: RtmpComponent,
    pathMatch: 'full'
  },
  {
    path: 'bectoauthor',
    component: BectoauthorComponent,
    pathMatch: 'full'
  },
  {
    path: 'priceandpost',
    component: PriceandpostComponent,
    pathMatch: 'full'
  },
  {
    path: 'changeEmail',
    component: ChangeEmailComponent,
    pathMatch: 'full'
  },
  {
    path: 'changePhone',
    component: ChangePhoneComponent,
    pathMatch: 'full'
  },
  {
    path: 'myDevices',
    component: MyDevicesComponent,
    pathMatch: 'full'
  },
  {
    path: 'faqhackacc',
    component: FaqhackaccComponent,
    pathMatch: 'full'
  },
  {
    path: 'delacc',
    component: DelAccountComponent,
    pathMatch: 'full'
  },
  {
    path: 'chatDialog',
    component: ChatDialogComponent,
    pathMatch: 'full'
  },
  {
    path: 'support',
    component: SupportComponent,
    pathMatch: 'full'
  },
  {
    path: 'subscribers',
    component: SubscribersComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: UserComponent,
    pathMatch: 'full'
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
