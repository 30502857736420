<div class="profile" id="scrollContainer">
    <h3 class="m-0">Информация</h3>
    <p>
        Если у вас возникли проблемы с COC.LOL, обновите приложение. Если это не
        помогло, пожалуйста, напишите в службу поддержки. Если вам не удаётся найти кнопку поддержки, вы также можете
        связаться с нами по адресу <a href="mailto:support@coc.lol">support@coc.lol</a>.
    </p>
    <div class="three-grid">
        <button class="danger-button" (click)="helper.openChat(helper.supportInfo)">Служба поддержки</button>
    </div>
    <h3 class="mt-20">Обновление приложения</h3>
    <p>Версия приложения 0.4.3</p>
    <div class="two-grid">
        <button class="primary-button" (click)="helper.reloadApp()">Обновить приложение</button>
    </div>
</div>
