import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {catchError, map} from 'rxjs/operators';
import {HelperService} from "./helper.service";

@Injectable()
export class ApiInterceptor implements HttpInterceptor  {
  constructor(
    private authService: AuthenticationService,
    private helper: HelperService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.url.indexOf('upload') > 0) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${AuthenticationService.jwt}`,
          Authorizationuuid: `${AuthenticationService.uuid}`,
        },
      });
    } else {
      req = req.clone({
        setHeaders: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${AuthenticationService.jwt}`,
          Authorizationuuid: `${AuthenticationService.uuid}`,
        },
      });
    }
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (!req.url.includes('assets'))
          console.log(`\n${req.method}: ${req.url}`, req.body, '\nRESPONSE:', event.body);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const data = {
          reason: error && error.error.reason ? error.error.reason : '',
          status: error.status,
          error: error.error
        };
        if (data.status === 403) {
          this.helper.alertMessage('Ошибка',error.error.error)
          this.authService.logout();
        }else {
          this.helper.alertMessage('Ошибка',error.error.message)
        }
        return throwError(error);
      }));
  }

}
