import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(150, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(150, style({opacity:0}))
      ])
    ])
  ]
})
export class SidebarComponent implements OnInit {
  active:string = '';
  constructor(
      private router: Router,
      public dialog: MatDialog,
      public authService:AuthenticationService,
      public helper:HelperService,
  ) { }

  ngOnInit(): void {
    this.active = this.router.url.slice(1);
  }
  async logOut(){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите выйти?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const res = await dialogRef.afterClosed().toPromise();
    if (res){
      this.authService.logout();
    }
  }
  async goToPage(page){
    await this.router.navigate([page], { replaceUrl: true });
    this.active = this.router.url.slice(1);
  }
  getClick(ev){
    if (ev.detail === 6){
      this.helper.xxx = true
    }
  }
}
