<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Номер телефона</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Добавить номер телефона</h3>
                <input type="text" class="main-input mt-20" [disabled]="sendcodeverify" placeholder="Номер телефона" prefix="+7 " mask="(000) 000 00 00" [(ngModel)]="phone">
                <small class="color-disabled mt-10 db" *ngIf="sendcodeverify">Проверочный код был отправлен на номер телефона <span class="link">+7 {{phone | mask:'(000) 000 00 00'}}</span> введите его в поле ниже для подтверждения.</small>
                <input type="text" class="main-input mt-20" *ngIf="sendcodeverify" placeholder="Проверочный код" [(ngModel)]="verifycode">
                <div class="three-grid">
                    <button class="primary-button mt-20" *ngIf="!sendcodeverify" (click)="changePhone()" [disabled]="phone === authService.currentUser.phone || phone.length !== 10">Добавить</button>
                    <button class="primary-button mt-20" *ngIf="sendcodeverify" (click)="verifyNewPhone()" [disabled]="verifycode.length !== 4">Подтвердить</button>
                </div>
                <h3 class="mt-20">Номера телефонов которым разрешен вход в аккаунт</h3>
                <div class="emails">
                    <div class="shadow-button mt-20" *ngFor="let row of authService.currentUser.authdata">
                        <p>{{row.phone | mask: '+0 (000) 000 00 00'}}
                            <br>
                            <small>Добавлен: {{helper.returnCurrentDateAndTime(row.date_create)}}</small>
                        </p>
                        <svg-icon class="danger-color" src="assets/icons/close.svg" (click)="delPhone(row.phone)"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button active" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
