<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Главная</h3>
            <div class="button-header" [routerLink]="'/createPost'">Новый пост</div>
        </div>
        <div class="home-page">
            <div class="posts pt-25"
                 id="scrollContainer"
                 infiniteScroll
                 [infiniteScrollDistance]="2"
                 [infiniteScrollThrottle]="100"
                 (scrolled)="onScroll()"
                 [scrollWindow]="false"
            >
                <div class="filters">
                    <div class="filter" [ngClass]="viewPostType === 'all' ? 'active':''" (click)="viewPostType = 'all'">
                        Все
                    </div>
                    <div class="filter"  [ngClass]="viewPostType === 'my' ? 'active':''" (click)="viewPostType = 'my'">
                        Подписки
                    </div>
                </div>
                <ng-container *ngFor="let row of helper.posts">
                    <div class="post" (click)="helper.viewPost(row,$event)">
                        <div class="user-info">
                            <div class="user-main-info" (click)="helper.viewUser(row.username,$event)">
                                <img [src]="helper.returnAvatar(row.user_id,row.avatar)" alt="avatar">
                                <div class="other-info-user">
                                    <span class="user-name">{{row.userfirstname}} {{row.userlastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></span>
                                    <span class="user-date">{{helper.returnDate(row.date_create)}}</span>
                                </div>
                            </div>
                            <div class="user-buttons">
                                <div class="subscribe" *ngIf="authService.currentUser.id !== row.user_id && !helper.detectSubscriber(row.user_id)" (click)="helper.subscribeUser(row.user_id,$event)">{{helper.detectSubscriber(row.user_id) ? 'Вы подписаны':'Отслеживать'}}</div>
                                <svg-icon src="assets/icons/more.svg"></svg-icon>
                            </div>
                        </div>
                        <div [ngClass]="helper.returnClass(row.image_three ? 3 : (row.image_two ? 2 : 1))"  class="images-post">
                            <!--<div class="hidden-container"></div>-->
                            <ng-container *ngIf="row.image_one">
                                <ng-container *ngIf="row.image_one.includes('data:image/png;base64')">
                                    <img class="img1" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_one_full" alt="{{row.image_one}}">
                                </ng-container>
                                <ng-container *ngIf="!row.image_one.includes('data:image/png;base64')">
                                    <video class="video-blur" [src]="helper.cdn + 'videos/blur/' + row.image_one" playsinline [muted]="muted" loop autoplay></video>
                                    <video [id]="'media1'+row.id"
                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_one_full"
                                           playsinline
                                           [muted]="muted"
                                           loop
                                           class="img1"
                                           inViewport
                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                           (inViewportAction)="onIntersection($event)">
                                    </video>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="row.image_two">
                                <ng-container *ngIf="row.image_two.includes('data:image/png;base64')">
                                    <img class="img2" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_two_full" alt="{{row.image_two}}">
                                    <!--<img class="img2" [src]="row.image_two" alt="{{row.image_two}}">-->
                                </ng-container>
                                <ng-container *ngIf="!row.image_two.includes('data:image/png;base64')">
                                    <video [id]="'media2'+row.id"
                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_two_full"
                                           playsinline
                                           [muted]="muted"
                                           loop
                                           class="img2"
                                           inViewport
                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                           (inViewportAction)="onIntersection($event)"></video>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="row.image_three">
                                <ng-container *ngIf="row.image_three.includes('data:image/png;base64')">
                                    <img class="img3" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_three_full" alt="{{row.image_three}}">
                                </ng-container>
                                <ng-container *ngIf="!row.image_three.includes('data:image/png;base64')">
                                    <!--[src]="helper.cdn + 'videos/blur/' + row.image_three"-->
                                    <video [id]="'media3'+row.id"
                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_three_full"
                                           playsinline
                                           [muted]="muted"
                                           loop
                                           class="img3"
                                           inViewport
                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                           (inViewportAction)="onIntersection($event)"></video>
                                </ng-container>
                            </ng-container>

                        </div>
                        <div class="post-info" *ngIf="row.description">
                            {{row.description}}
                        </div>
                        <div class="post-tags" *ngIf="row.tags">
                            {{row.tags}}
                        </div>
                        <div class="post-buttons" >
                            <div class="likers-buttons">
                                <div class="button-post" [ngClass]="helper.detectLikedPost(row.id) ? 'active':''" (click)="helper.likePosts(row.id,$event)">
                                    <svg-icon src="assets/icons/heart.svg"></svg-icon> <span *ngIf="row.likes">{{row.likes}}</span>
                                </div>
                                <div class="button-post" *ngIf="row.enable_comments">
                                    <svg-icon src="assets/icons/comment.svg"></svg-icon> <span *ngIf="row.comments && row.comments.length">{{row.comments.length}}</span>
                                </div>
                                <div class="button-post repost" *ngIf="row.enable_repost">
                                    <svg-icon src="assets/icons/repost.svg"></svg-icon>
                                </div>
                            </div>
                            <div class="views">
                                <div class="button-post" *ngIf="row.views">
                                    <svg-icon src="assets/icons/view.svg"></svg-icon> <span >{{row.views}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <app-recommended class="pt-25"></app-recommended>
        </div>

    </div>
</div>
