import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";



@Component({
  selector: 'app-broadcasts',
  templateUrl: './broadcasts.component.html',
  styleUrls: ['./broadcasts.component.scss']
})
export class BroadcastsComponent implements OnInit {

  constructor(
      public helper:HelperService
  ) { }

  ngOnInit(): void {
  }

}
