<div class="attach-page">
    <div class="filters">
        <div class="filter" [ngClass]="filter === 'all' ? 'active':''"  (click)="filter = 'all'">
            Все
        </div>
        <div class="filter" [ngClass]="filter === 'image' ? 'active':''" (click)="filter = 'image'">
            Фото
        </div>
        <div class="filter" [ngClass]="filter === 'video' ? 'active':''" (click)="filter = 'video'">
            Видео
        </div>
    </div>
    <div class="attached-files">
        <ng-container *ngFor="let row of helper.returnArray(messages) | reverse">
            <span class="message-file" *ngIf="row.includes('png') && (filter === 'image' || filter === 'all')">
                 <img (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)" [src]="helper.cdn + 'chats/' + chat_id + '/'+row" alt="image">
            </span>
            <span class="message-file" *ngIf="row.includes('mp4') && (filter === 'video' || filter === 'all')">
                 <video (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)" [src]="helper.cdn + 'chats/' + chat_id + '/'+row" autoplay playsinline loop [muted]="muted"></video>
            </span>
        </ng-container>
    </div>
    <div class="close-button" [mat-dialog-close]="true">
        <svg-icon src="assets/icons/close.svg"></svg-icon>
    </div>
</div>
