import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {BroadcasterService} from "../services/broadcaster.service";
import {AuthenticationService} from "../services/authentication.service";
import {SocketService} from "../services/socket.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {HelperService} from "../services/helper.service";

declare var RTCMultiConnection: any;

declare var flvjs: any;
@Component({
  selector: 'app-connect-broadcast',
  templateUrl: './connect-broadcast.component.html',
  styleUrls: ['./connect-broadcast.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity:0}))
      ])
    ])
  ]
})
export class ConnectBroadcastComponent implements OnInit,OnDestroy {
  flvPlayer:any;
  streamAudio: any[] = [];
  isLoading:boolean = true;
  muted:boolean = true;
  mutedPost:boolean = true;
  viewinfo:boolean = true;
  broadcastInfo:any;
  userInfo:any;

  users: any[] = [];
  message:string = '';
  messages:any[] = [];
  constructor(
      public broadcaster: BroadcasterService,
      public router: Router,
      private socketService: SocketService,
      public authService: AuthenticationService,
      public helper: HelperService,
      private route: ActivatedRoute
      ) { }

  async ngOnInit() {
    this.broadcaster.room_id = this.route.snapshot.paramMap.get('id');
    const broadcastInfo = await this.helper.getBroadcastInfo(this.broadcaster.room_id).toPromise()
    if (broadcastInfo.status){
      this.userInfo = broadcastInfo.user;
      this.broadcastInfo = broadcastInfo.broadcast;
      this.messages = broadcastInfo.messages;
      if (this.broadcastInfo.type === 'web'){
        await this.initMyRTCConnect()
        await this.broadcaster.connection.join(this.broadcaster.room_id);
      }else if(this.broadcastInfo.type === 'obs'){
        this.isLoading = false;
        console.log(this.userInfo.media_server_key)
        setTimeout(()=>{
          let videoElement = document.getElementById('videoElement');
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: 'https://coc.lol:8443/live/'+this.userInfo.media_server_key+'.flv'
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play().then((res)=>{
            console.log(res)
          });
        },1000)
      }
      const res = await this.helper.connectUserBroadcast(this.broadcaster.room_id).toPromise()
      if (res.status){
        this.users.push(
            this.authService.currentUser
        )
        this.socketService.userBroadcastConnect(this.broadcaster.room_id).subscribe(async (data)=> {
          const index = this.users.findIndex(e => e.name === data.name)
          if (index>=0){
            this.users.splice(index,1)
          }
          this.users.push(data)
          this.messages.push({
            name:data.name,
            type:2
          })
        });
        this.socketService.userBroadcastDisconnect(this.broadcaster.room_id).subscribe(async (data)=> {
          const index = this.users.findIndex(e => e.name === data.name)
          if (index>=0){
            this.users.splice(index,1)
          }
        });
        this.socketService.getMessagesBroadcast(this.broadcaster.room_id).subscribe(async (data)=> {
          console.log(data)
          if (data){
            this.messages.push(data)
          }
        });
      }
    }else {
      await this.router.navigate(['broadcasts'], { replaceUrl: true });
      await this.helper.alertMessage('Увы','Трансляция больше недоступна')
    }
  }
  async ngOnDestroy(){
    await this.helper.disconnectUserBroadcast(this.broadcaster.room_id).toPromise()
    if (this.broadcastInfo.type === 'web'){
      await this.broadcaster.connection.closeSocket();
    }
    this.broadcaster.room_id = '';
  }
  async initMyRTCConnect() {
    try {
      this.broadcaster.connection = new RTCMultiConnection();
      this.broadcaster.connection.socketURL = 'https://coc.lol:9001/';
      this.broadcaster.connection.socketMessageEvent = 'video-broadcast-demo';
      this.broadcaster.connection.userid = this.authService.currentUser.id;
      this.broadcaster.connection.extra = {
        creator: this.authService.currentUser.id,
      };
      this.broadcaster.connection.setDefaultEventsForMediaElement = true;
      this.broadcaster.connection.autoCloseEntireSession = true;
      this.broadcaster.connection.getExternalIceServers = true;
      this.broadcaster.connection.iceServers = [];
      this.broadcaster.connection.iceServers.push({
        urls:'stun:stun.coc.lol:3478'
      });
      this.broadcaster.connection.iceServers.push({
        urls: "turn:turn.coc.lol:3478",
        username: "f2030212c710a9058bdded6f",
        credential: "L1N0jVMwJwyHOQm6",
      });
      this.broadcaster.connection.session = {
        audio: true,
        video: true,
        oneway: true,
      };
      this.broadcaster.connection.codecs = {
        video: 'H264',
        audio: 'opus'
      };
      this.broadcaster.connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
      };
      this.broadcaster.connection.onstream = (event:any) => {
        console.log(event)
        this.streamAudio = [...new Map(this.streamAudio.map((m) => [m.id, m])).values()];
        this.streamAudio.unshift({
          id: event.streamid,
          url: event.stream,
          type: event.type,
          muted: false,
          userid: event.userid,
          extra: event.extra,
        });
        this.streamAudio = [...new Map(this.streamAudio.map((m) => [m.id, m])).values()];
        console.log(this.streamAudio)
        this.isLoading = false;
        setTimeout(()=> {
          document.getElementById(this.streamAudio[0].id).setAttribute("style","z-index:0");
          this.checkPlay()
        },1000)
      };
      this.broadcaster.connection.onstreamended = (event:any) => {
        console.log('onstreamended',event)
        const index = this.streamAudio.findIndex(e => e.userid === event.userid)
        if (index>=0){
          this.broadcaster.connection.deletePeer(event.userid);
          this.streamAudio.splice(index,1)
        }
        let mediaElement = document.getElementById(event.streamid);
        if (mediaElement) {
          mediaElement.parentNode.removeChild(mediaElement);
        }
      };
    }catch (err:any){
      console.log('catch')
    }finally {
      console.log('finally')
    }
  }
  playVideo(){
    let video: any = document.getElementById(this.streamAudio[0].id);
    video.play();
  }
  async checkPlay(){
    let video: any = document.getElementById(this.streamAudio[0].id);
    if (video.paused || video.ended) {
      await this.helper.alertMessage('Воспроизвести','К сожалению нам требуется от вас разрешение на воспроизведение видео');
      this.playVideo();
    }
  }
  async sendMessageBroadCast(){
    if (this.message){
      const res = await this.helper.sendMessageBroadCast(this.message,this.broadcaster.room_id).toPromise()
      if (res.status){
        this.message = '';
      }
    }
  }
  soundEnable(pos){
    this.muted = pos
  }
}
