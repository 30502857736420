import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-view-media',
  templateUrl: './view-media.component.html',
  styleUrls: ['./view-media.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity:0}))
      ])
    ])
  ]
})
export class ViewMediaComponent implements OnInit {
  viewClose:boolean = false;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public helper: HelperService,
      public authService: AuthenticationService,
      ) { }

  ngOnInit(): void {
    console.log(this.data)
  }

}
