import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-safety',
  templateUrl: './safety.component.html',
  styleUrls: ['./safety.component.scss']
})
export class SafetyComponent implements OnInit {

  countriesBlocked:any[]=[];
  countries:any[]=[];
  constructor(
      public authService: AuthenticationService,
      public dialog: MatDialog,
      public helper: HelperService
  ) { }

  ngOnInit(): void {
    this.countriesBlocked = this.authService.currentUser.countries_blocked.split(',');
    let countries = this.helper.countries
    this.countries = countries.filter(e => !this.countriesBlocked.includes(e.alpha_2_code));
  }
  searchCountry(ev) {
    if (ev.target.value){
      this.countries = this.helper.countries.filter(e => e.en_short_name.toLowerCase().includes(ev.target.value.toLowerCase()) && !this.countriesBlocked.includes(e.alpha_2_code))
    }else {
      this.countries = this.helper.countries.filter(e => !this.countriesBlocked.includes(e.alpha_2_code));
    }
  }
  async updateStatusHide(){
    const res = await this.helper.updateStatusHide(this.authService.currentUser.hide_status).toPromise();
    if (res.status){
      this.authService.currentUser.hide_status = this.authService.currentUser.hide_status ? 0:1;
    }
  }
  async addCountryToBlocked(item){
    this.countriesBlocked.push(item.alpha_2_code)
    this.countries = this.helper.countries.filter(e => !this.countriesBlocked.includes(e.alpha_2_code));
    await this.helper.saveCountryBlocked(this.countriesBlocked.toString()).toPromise();
  }
  async delCountry(item){
    const index = this.countriesBlocked.findIndex(e => e === item)
    if (index>=0){
      this.countriesBlocked.splice(index,1)
      this.countries = this.helper.countries.filter(e => !this.countriesBlocked.includes(e.alpha_2_code));
      await this.helper.saveCountryBlocked(this.countriesBlocked.toString()).toPromise();
    }
  }
}
