import { Component, OnInit } from '@angular/core';
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {AuthenticationService} from "../services/authentication.service";
import {DomSanitizer} from "@angular/platform-browser";
import {base64ToFile} from "ngx-image-cropper";

@Component({
  selector: 'app-create-flick',
  templateUrl: './create-flick.component.html',
  styleUrls: ['./create-flick.component.scss']
})
export class CreateFlickComponent implements OnInit {
  loading:boolean = false;
  canExit:boolean = false;
  one_image_event:any;
  one_image:any = '';
  data = {
    about:'',
    nfsw:false
  }
  constructor(
      public dialog: MatDialog,
      public router: Router,
      public authService: AuthenticationService,
      private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  async canDeactivate() {
    if (!this.canExit){
      let dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'main-modal',
        data:{header:'Подтверждение',text:'Ваш пост не будет сохранен! Вы уверены что хотите покинуть страницу создания поста?',
          buttons:[{text:'Нет',status:false,},{text:'Да',status:true,},]
        }
      });
      return await dialogRef.afterClosed().toPromise();
    }
  }

  public initUpload(num) {
    let fileInput = document.getElementById('fileInput' + num);
    this.clearFileInput(document.getElementById('fileInput' + num));
    console.log(fileInput);
    if (fileInput) fileInput.click();
    else console.log('ERROR: cannot find file input');
  }
  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  onChange(ev){
    const reader = new FileReader();
    let file = ev.target.files[0];
    reader.onloadend = e => this.one_image = reader.result;
    this.one_image_event = ev.target.files[0];
    reader.readAsDataURL(file);
  }
  checkPost(){
    if (this.one_image && this.one_image !== ''){
      return true
    }else{
      let dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'main-modal',
        data:{header:'Ошибка',text:'Добавьте видео',
          buttons:[{text:'Ок',status:true,},]
        }
      });
      dialogRef.afterClosed().toPromise();
      return false
    }
  }
  async createPost(){
    if (this.checkPost()){
      this.loading = true;
      let formData = new FormData();
      if (this.one_image && this.one_image !== '' ){
        formData.append("videos", this.one_image_event);
      }
      formData.append('data',JSON.stringify(this.data))
      const res = await fetch("https://coc.lol:9125/main/createFlicks", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + AuthenticationService.jwt,
          'Authorizationuuid': `${AuthenticationService.uuid}`,
        }
      });
      let data = await res.json();
      if (data.status){
        this.loading = false;
        this.canExit = true;
        await this.router.navigate(['flicks'], { replaceUrl: true });
      }
    }
  }

}
