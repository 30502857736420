import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {base64ToFile, ImageCroppedEvent, LoadedImage} from "ngx-image-cropper";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthenticationService} from "../services/authentication.service";
import heic2any from "heic2any";
import { CropperComponent } from 'angular-cropperjs';
import {Router} from "@angular/router";
import { DateAdapter } from '@angular/material/core';
import {addDays} from 'date-fns';
import {HelperService} from "../services/helper.service";
import {CdkDragDrop, DragRef, Point} from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss']
})

export class CreatePostComponent implements OnInit {
  @ViewChild('angularCropper') public angularCropper: CropperComponent;
  minDate = new Date();
  maxDate = new Date(addDays(new Date(),+183)).toISOString();
  muted:boolean = true;
  loading:boolean = false;
  canExit:boolean = false;
  edit:boolean = false;
  image:any = '';
  one_image:any = '';
  one_type_image:boolean = true;
  one_image_event:any;
  two_image:any = '';
  two_type_image:boolean = true;
  two_image_event:any;
  three_image:any = '';
  three_type_image:boolean = true;
  three_image_event:any;
  data = {
    about:'',
    tags:'',
    audit:1,
    price:'',
    nfsw:false,
    comment:true,
    repost:true,
    date:new Date(),
  }
  config = {
    aspectRatio : 4/5,
    autoCropArea: 1,
    minCropBoxHeight: 100,
    dragMode : 'move',
    background : false,
    movable: false,
    rotatable : true,
    scalable: true,
    zoomable: false,
    viewMode: 2,
    checkImageOrigin : true,
    checkCrossOrigin: true
  };

  files: any[]=[];
  filessize: number = 0;

  constructor(
      public dialog: MatDialog,
      public router: Router,
      private dateAdapter: DateAdapter<Date>,
      public helper: HelperService,
      public authService: AuthenticationService,
      private sanitizer: DomSanitizer
      ) {
  }
  onDragStarted(ev): void {
    //console.log('onDragStarted', ev);
  }

  onDragEnded({ distance }): void {
    console.log(distance);
  }
  onDragMoved(ev): void {
    //console.log('onDragMoved', ev);
  }
  indexOver: number = -1;
  drop(event: CdkDragDrop<any>) {
    if (event.previousContainer != event.container) {
      if (event.container.data.src !== undefined) { //we are drop on "board"
        if (event.previousContainer.data.src != undefined) { //we are dragging an element of "board"
          event.container.data.src = event.previousContainer.data.src;
          event.previousContainer.data.src = null;
        } else {  //we are dragging an element of "side"
          event.container.data.src = event.previousContainer.data;
        }
      } else {
        if (
            event.container.data.src === undefined &&
            event.previousContainer.data.src !== undefined
        ) //we are drop an img from "board" on the "side"
          event.previousContainer.data.src = null;
      }
    }
  }
  ngOnInit(): void {
    this.dateAdapter.setLocale('ru-RU');
    this.dateAdapter.getFirstDayOfWeek = () => { return 1; }
  }
  setAudit(num){
    this.data.audit = num
  }
  setNSFW(ev){
    this.data.nfsw = ev.target.checked
  }
  setComment(ev){
    this.data.comment = ev.target.checked
  }
  setRepost(ev){
    this.data.repost = ev.target.checked
  }

  async canDeactivate() {
    if (!this.canExit){
      let dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'main-modal',
        data:{header:'Подтверждение',text:'Ваш пост не будет сохранен! Вы уверены что хотите покинуть страницу создания поста?',
          buttons:[{text:'Нет',status:false,},{text:'Да',status:true,},]
        }
      });
      return await dialogRef.afterClosed().toPromise();
    }
  }

  onChange(ev,num) {
    const reader = new FileReader();
    if(ev.target.files[0].type.includes('image')){
      if(ev.target.files[0] && ev.target.files[0].name.includes(".HEIC")){
        let file = ev.target.files[0];
        heic2any({
          blob: file,
          toType: "image/png",
        }).then((convertHeicTopng: any) => {
          reader.onloadend = () => {
            if (num === 1){
              this.image = reader.result;
            }else if (num === 2){
              this.image = reader.result;
            }else {
              this.image = reader.result;
            }
          }
          reader.readAsDataURL(convertHeicTopng);
        })
      }else {
        let file = ev.target.files[0];
        if (num === 1){
          reader.onloadend = e => this.image = reader.result;
        }else if (num === 2){
          reader.onloadend = e => this.image = reader.result;
        }else {
          reader.onloadend = e => this.image = reader.result;
        }
        reader.readAsDataURL(file);
      }
    }else {
      let file = ev.target.files[0];
      if (num === 1){
        reader.onloadend = e => this.one_image = reader.result;
        this.one_image_event = ev.target.files[0];
        this.one_type_image = false;
      }else if (num === 2){
        reader.onloadend = e => this.two_image = reader.result;
        this.two_image_event = ev.target.files[0];
        this.two_type_image = false;
      }else {
        reader.onloadend = e => this.three_image = reader.result;
        this.three_image_event = ev.target.files[0];
        this.three_type_image = false;
      }
      reader.readAsDataURL(file);
    }
  }

  editImage(num){
    this.edit = num;
  }
  clearImage(index){
    this.files.splice(index,1)
  }
  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  async createPost(){
    if (this.files.length){
      this.loading = true;
      let formData = new FormData();
      for (let row of this.files){
        if (row.type === 'image'){
          formData.append("files", base64ToFile(row.preview));
        }else {
          formData.append("files", row.file);
        }
      }
      formData.append('data',JSON.stringify(this.data))
      const res = await fetch("https://coc.lol:9125/main/createPost", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + AuthenticationService.jwt,
          'Authorizationuuid': `${AuthenticationService.uuid}`,
        }
      });
      let data = await res.json();
      if (data.status){
        this.loading = false;
        this.canExit = true;
        // @ts-ignore
        await this.router.navigate('/'+this.authService.currentUser.name, { replaceUrl: true });
      }
    }else {
      await this.helper.alertMessage('Ошибка','Добавьте хотя бы один медиафайл')
    }
  }
  replaceComme(ev){
    let text = ev.target.value
    this.data.tags = text.split(/[ #]+/).join('#')
  }

  attachFile(){
    let fileInput = document.getElementById('fileInput');
    this.clearFileInput(document.getElementById('fileInput'));
    console.log(fileInput);
    if (fileInput) fileInput.click();
    else console.log('ERROR: cannot find file input');
  }

  onChangeFile(ev) {
    for (let row of ev.target.files){
      const reader = new FileReader();
      console.log(row)
      reader.onloadend = () => {
        this.files.push({
          preview:reader.result,
          file:row,
          size:row.size,
          type:row.type.split('/')[0],
        });
        if (reader.result.toString().includes('image')){
          this.image = reader.result;
          this.edit = true;
        }
      }
      reader.readAsDataURL(row);
    }
    for (let file of this.files){
      this.filessize = this.filessize + file.size;
    }
  }

  saveImage(){
    const index = this.files.findIndex(e => e.preview === this.image)
    if (index>=0){
      this.files[index].preview = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    }
    this.edit = false;
  }
  cancelEdit(){
    const index = this.files.findIndex(e => e.preview === this.image)
    if (index>=0){
      this.files.splice(index,1);
    }
    this.edit = false;
  }

}
