import { Component, OnInit } from '@angular/core';
import {InViewportMetadata} from "ng-in-viewport";
import {HelperService} from "../services/helper.service";
@Component({
  selector: 'app-flicks',
  templateUrl: './flicks.component.html',
  styleUrls: ['./flicks.component.scss']
})
export class FlicksComponent implements OnInit {
  muted:boolean = true;
  mutedAnimation:boolean = false;
  constructor(
      public helper: HelperService
  ) { }

  ngOnInit(): void {
  }
  onIntersection(ev){
    const { [InViewportMetadata]: { entry }, target } = ev;
    const ratio = entry.intersectionRatio;
    //let video = <HTMLVideoElement> document.getElementById(target.id)
    //console.log(video)
    //console.log(target)
    if(ratio >= 0.5){
      setTimeout(() => {
        target.play()
      }, 310);
    }else if (ratio < 0.5){
      setTimeout(() => {
        target.pause();
        target.currentTime = 0;
      }, 310);
    }
  }
  public changeVideoAudio(ev,id) {
    ev.stopPropagation();
    this.mutedAnimation = true;
    let vid: any = document.getElementById('media'+id);
    vid.muted = !vid.muted;
    this.muted = !this.muted;
    setTimeout(()=> {
      this.mutedAnimation = false;
    },500)
  }

  videoPaused(ev,id) {
    console.log(ev)
    ev.stopPropagation();
    let vid: any = document.getElementById('media'+id);
    vid.pause();
  }
  videoUnPaused(ev,id) {
    ev.stopPropagation();
    let vid: any = document.getElementById('media'+id);
    vid.play();
  }

}
