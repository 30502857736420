<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Трансляции</h3>
            <div class="button-header" [routerLink]="'/createBroadcast'">Начать трансляцию</div>
        </div>
        <div class="no-items-with-recommended" *ngIf="helper.broadcasts && !helper.broadcasts.length">
            <div class="no-item-text">
                На данный момент нет активных прямых трянсляций
            </div>
            <app-recommended></app-recommended>
        </div>

        <div class="broadcasts-page pt-25" *ngIf="helper.broadcasts && helper.broadcasts.length">
            <!--<div class="filters">
                <div class="filter active">
                    Популярные
                </div>
                <div class="filter">
                    Девушки
                </div>
                <div class="filter">
                    Парни
                </div>
                <div class="filter">
                    Трансы
                </div>
                <div class="filter">
                    Новые
                </div>
            </div>-->
            <div class="broadcasts">
                <ng-container *ngFor="let row of helper.broadcasts">
                    <div class="broadcast" [routerLink]="'/b/'+row.room_id">
                        <img [src]="helper.returnAvatar(row.user_id,row.avatar)" alt="">
                        <div class="broadcast-info">
                            <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> {{row.audience}}
                            </span>
                            </div>
                            <div class="user-info">
                                <b>{{row.firstname}} {{row.lastname}}</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
