export class User {
    public id: number;
    public username: string;
    public firstname: string;
    public lastname: string;
    public about: string;
    public email: string;
    public phone: string;
    public avatar: string;
    public background: string;
    public hasher: string;
    public coin: string;
    public country: string;
    public media_server_key: string;
    public authdata: any;
    public sessions: any;
    public links: any;
    public countries_blocked: string;
    public verify: number;
    public hide_status: number;
    constructor(user: any) {
        this.id = +user.id;
        this.username = user.username;
        this.firstname = user.firstname;
        this.lastname = user.lastname;
        this.about = user.about;
        this.email = user.email;
        this.phone = user.phone;
        this.hasher = user.hasher;
        this.avatar = user.avatar;
        this.background = user.background;
        this.coin = user.coin;
        this.country = user.country;
        this.media_server_key = user.media_server_key;
        this.authdata = user.authdata;
        this.sessions = user.sessions;
        this.links = user.links;
        this.countries_blocked = user.countries_blocked;
        this.verify = +user.verify;
        this.hide_status = +user.hide_status;
    }
}
