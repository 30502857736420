<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Что делать, если аккаунт взломали</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Признаки незащищенного аккаунта</h3>
                <div class="danger-container-info mt-20">
                    <svg-icon src="assets/icons/warning.svg"></svg-icon>
                    <p class="m-0">Друзья и знакомые получают от вас сообщения, отправленные не вами</p>
                </div>
                <div class="danger-container-info mt-20">
                    <svg-icon src="assets/icons/warning.svg"></svg-icon>
                    <p class="m-0">В вашем профиле COC.LOL появились записи и фото,опубликованные не вами</p>
                </div>
                <div class="danger-container-info mt-20">
                    <svg-icon src="assets/icons/warning.svg"></svg-icon>
                    <p class="m-0">В истории активности обнаружены данные о входе с неизвестных устройств и из незнакомых мест</p>
                </div>
                <div class="danger-container-info mt-20">
                    <svg-icon src="assets/icons/warning.svg"></svg-icon>
                    <p class="m-0">Кто-то изменил личную информацию и настройки аккаунта без вашего ведома</p>
                </div>
                <button class="danger-button mt-20 h-40" (click)="helper.openChat(this.helper.supportInfo)">Обратиться в поддержку</button>
            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button active" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
