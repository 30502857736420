import { Component, OnInit } from '@angular/core';
import {DialogComponent} from "../../dialogs/dialog/dialog.component";
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
      public authService: AuthenticationService,
      public dialog: MatDialog,
      public helper: HelperService
  ) { }

  ngOnInit(): void {
  }
  async logoutAllDevices(){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите завершить другие сеансы?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const resd = await dialogRef.afterClosed().toPromise();
    if (resd){
      const res = await this.helper.logoutAllDevices().toPromise();
      if (res.status){
        for (let row of this.authService.currentUser.sessions){
          if (row.uuid !== this.authService.getUUID()){
            const index = this.authService.currentUser.sessions.findIndex(e => e.id === row.id)
            if (index>=0){
              this.authService.currentUser.sessions.splice(index,1)
            }
          }
        }
        await this.helper.alertMessage('Отлично','Другие сеансы успешно завершены')
      }
    }
  }

}
