import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../services/authentication.service";
import {HelperService} from "../services/helper.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email:string = '';
  phone:string = '';
  code:string = '';
  validateEmail:boolean = false;
  validateCode:boolean = false;
  error:boolean = false;
  step:number = 1;

  constructor(
      public authService: AuthenticationService,
      public router: Router,
      public helper: HelperService,
  ) { }

  async ngOnInit() {
    this.helper.isLoading = false;
    if (this.authService.isAuthenticated()){
      await this.router.navigate(['home'], { replaceUrl: true });
    }
  }
  async loginEmail(){
    const res = await this.authService.loginUser(this.email).toPromise();
    if (res.status){
      this.step = 2;
    }
  }
  async loginPhone(){
    const res = await this.authService.loginUserPhone(this.phone).toPromise();
    if (res.status){
      this.step = 2;
    }
  }
  async verifyCode(){
    const res = await this.authService.verifyCode(this.email,this.code).toPromise();
    if (res.status){
      this.authService.setJwt(res.token);
      this.helper.isLoading = true;
    }else {
      this.error = true;
    }
  }
  async verifyCodePhone(){
    const res = await this.authService.verifyCodePhone(this.phone,this.code).toPromise();
    if (res.status){
      this.authService.setJwt(res.token);
      this.helper.isLoading = true;
    }else {
      this.code = '';
      await this.helper.alertMessage('Ошибка','Проверочный код введен НЕ верно!')
      this.error = true;
    }
  }
  checkCode(ev){
    this.error = false;
    let code = ev.target.value;
    this.validateCode = code.length === 4;
  }
  checkEmail(ev){
    let email = ev.target.value;
    let reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    this.validateEmail = reEmail.test(email) != false;
  }
}
