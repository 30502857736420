<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Новый пост</h3>
        </div>
        <div class="create-post-page" id="scrollContainer">
            <div class="create-post pt-25">
                <div class="select-photo">

                    <input id="fileInput" style="display:none" type="file" accept="image/png,image/jpg,image/heic, video/*" (change)="onChangeFile($event)">

                    <div class="photo-selected" (click)="!files[0] ? attachFile():false">
                        <ng-container *ngIf="!files[0]">
                            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                            <p>Добавить фото <br> или видео</p>
                        </ng-container>
                        <ng-container *ngIf="files[0]">
                            <img *ngIf="files[0].type === 'image'" [src]="files[0].preview" alt="one_image">
                            <video *ngIf="files[0].type === 'video'" [src]="files[0].preview" autoplay loop [muted]="muted"></video>
                            <div class="edit-image">
                                <svg-icon class="delete-image" (click)="clearImage(0)" src="assets/icons/delete.svg"></svg-icon>
                                <div *ngIf="files[0].type === 'image'" class="open-editor" (click)="editImage(0)">
                                    <svg-icon src="assets/icons/edit-image.svg"></svg-icon> <span>Изменить размер</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="photo-selected" (click)="!files[1] ? attachFile():false">
                        <ng-container *ngIf="!files[1]">
                            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                            <p>Добавить фото <br> или видео</p>
                        </ng-container>
                        <ng-container *ngIf="files[1]">
                            <img *ngIf="files[1].type === 'image'" [src]="files[1].preview" alt="one_image">
                            <video *ngIf="files[1].type === 'video'" [src]="files[1].preview" autoplay loop [muted]="muted"></video>
                            <div class="edit-image">
                                <svg-icon class="delete-image" (click)="clearImage(1)" src="assets/icons/delete.svg"></svg-icon>
                                <div *ngIf="files[1].type === 'image'" class="open-editor" (click)="editImage(1)">
                                    <svg-icon src="assets/icons/edit-image.svg"></svg-icon> <span>Изменить размер</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="photo-selected" (click)="!files[2] ? attachFile():false">
                        <ng-container *ngIf="!files[2]">
                            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                            <p>Добавить фото <br> или видео</p>
                        </ng-container>
                        <ng-container *ngIf="files[2]">
                            <img *ngIf="files[2].type === 'image'" [src]="files[2].preview" alt="one_image">
                            <video *ngIf="files[2].type === 'video'" [src]="files[2].preview" autoplay loop [muted]="muted"></video>
                            <div class="edit-image">
                                <svg-icon class="delete-image" (click)="clearImage(2)" src="assets/icons/delete.svg"></svg-icon>
                                <div *ngIf="files[2].type === 'image'" class="open-editor" (click)="editImage(2)">
                                    <svg-icon src="assets/icons/edit-image.svg"></svg-icon> <span>Изменить размер</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <h3>Информация</h3>
                <div class="textarea-container">
                    <textarea rows="3" placeholder="Описание"  class="main-textarea" [(ngModel)]="data.about"></textarea>
                    <span class="charecter-count" [ngClass]="data.about.length > 255 ? 'danger-color':''">{{data.about.length}}/255</span>
                </div>
                <input placeholder="Теги" class="main-input" style="margin-bottom: 20px" [(ngModel)]="data.tags" (input)="replaceComme($event)">
            </div>
            <div class="more-information pt-25">
                <h3 style="margin-top: 0;">Дополнительно</h3>
                <div class="info">
                    <div class="settings">
                        <div class="small-text">* ”NSFW контент” на данном ресурсе подразумевает изображения или видео содержащие исключительно наготу, порнографию и сексуальные сцены.</div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb1" [checked]="data.nfsw" (change)="setNSFW($event)">
                            <label for="cb1">NSFW контент </label>
                        </div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb2" [checked]="data.comment" (change)="setComment($event)">
                            <label for="cb2">Включить комментарии </label>
                        </div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb3" [checked]="data.repost" (change)="setRepost($event)">
                            <label for="cb3">Включить репост </label>
                        </div>
                        <div class="date-selected-post" (click)="picker.open()">
                            <svg-icon src="assets/icons/calendar.svg"></svg-icon>
                            <span>Запланировать пост </span>
                            <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
                        </div>
                        <div class="small-text" style="margin-top: 10px">Дата поста: {{helper.returnCurrentDate(data.date)}}</div>
                    </div>
                    <mat-form-field class="main-form-field-date">
                        <input  type="text" matInput [min]="minDate" [max]="maxDate" [(ngModel)]="data.date" [matDatepicker]="picker" hidden>
                        <mat-datepicker
                            #picker
                        ></mat-datepicker>
                    </mat-form-field>
                    <div class="audit">
                        <h3>Аудитория</h3>
                        <div class="checkbox-setting">
                            <input type="radio" id="cb4" name="audit" [checked]="data.audit === 2" (change)="setAudit(2)">
                            <label for="cb4">Только для фанатов </label>
                        </div>
                        <div class="checkbox-setting">
                            <input type="radio" id="cb5" name="audit" [checked]="data.audit === 3" (change)="setAudit(3)">
                            <label for="cb5">Доступ после покупки </label>
                        </div>
                        <div class="checkbox-setting">
                            <input type="radio" id="cb6" name="audit" [checked]="data.audit === 1" (change)="setAudit(1)">
                            <label for="cb6">Свободный контент </label>
                        </div>
                        <input *ngIf="data.audit === 3" id="price" placeholder="Сумма покупки" class="main-input" type="number" style="margin-bottom: 10px;margin-top: 25px" [(ngModel)]="data.price">
                        <div class="small-text" *ngIf="data.audit === 3" style="margin-top: 5px">Укажите стоимость поста. Максимальная сумма €499</div>
                    </div>
                    <button class="primary-button" (click)="createPost()" style="width: 100%">
                        <svg-icon class="loading-svg" src="assets/icons/loading.svg" *ngIf="loading"></svg-icon>
                        {{!loading ? 'Опубликовать':''}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="image-cropper-modal-backdrop" *ngIf="edit">
        <div class="image-cropper-modal" >
            <div class="image-cropper">
                <angular-cropper #angularCropper [cropperOptions]="config" [imageUrl]="image"></angular-cropper>
            </div>
            <div class="button-cropper">
                <button class="other-button" (click)="cancelEdit()">Отмена</button>
                <button class="primary-button" (click)="saveImage()">Сохранить</button>
            </div>
        </div>
    </div>


</div>

<div class="loading" *ngIf="loading">

</div>
