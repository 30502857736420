<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3 *ngIf="helper.settingType === 'profile'">Профиль</h3>
            <h3 *ngIf="helper.settingType === 'account'">Учетная запись</h3>
            <h3 *ngIf="helper.settingType === 'safety'">Конфиденциальность и безопасность</h3>
            <h3 *ngIf="helper.settingType === 'payment'">Платежи</h3>
            <h3 *ngIf="helper.settingType === 'info'">Информация</h3>
        </div>

        <div class="profile-page">
            <app-profile *ngIf="helper.settingType === 'profile'"></app-profile>

            <app-account *ngIf="helper.settingType === 'account'"></app-account>

            <app-safety *ngIf="helper.settingType === 'safety'"></app-safety>

            <app-payments *ngIf="helper.settingType === 'payment'"></app-payments>

            <app-information *ngIf="helper.settingType === 'info'"></app-information>


            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" [ngClass]="helper.settingType === 'profile' ? 'active':''" (click)="helper.settingType = 'profile'">Профиль</div>
                    <div class="profile-type-button" [ngClass]="helper.settingType === 'account' ? 'active':''" (click)="helper.settingType = 'account'">Учетная запись</div>
                    <div class="profile-type-button" [ngClass]="helper.settingType === 'safety' ? 'active':''" (click)="helper.settingType = 'safety'">Конф. и безопасность</div>
                    <div class="profile-type-button" [ngClass]="helper.settingType === 'payment' ? 'active':''" (click)="helper.settingType = 'payment'">Платежи</div>
                    <div class="profile-type-button" [ngClass]="helper.settingType === 'info' ? 'active':''" (click)="helper.settingType = 'info'">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
