<div class="post-page">
    <swiper [config]="configslider" class="myswiper">
        <ng-template swiperSlide>
            <div class="imageSwiper">
                <ng-container *ngIf="post.image_one && post.image_one.includes('data:image/png;base64')">
                    <img [src]="helper.cdn + 'photos/'+ post.user_id + '/' + post.image_one_full" alt="">
                </ng-container>
                <ng-container *ngIf="post.image_one && !post.image_one.includes('data:image/png;base64')">
                    <video class="video-blur" [src]="helper.cdn + 'videos/blur/' + post.image_one" playsinline [muted]="muted" loop autoplay></video>
                    <video [src]="helper.cdn + 'videos/'+ post.user_id + '/' + post.image_one_full" autoplay [muted]="muted" loop playsinline
                           inViewport
                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                           (inViewportAction)="onIntersection($event)"
                    ></video>
                </ng-container>
                <div class="right-arrow-container" (click)="swipeNext()" *ngIf="post.image_two_full && post.image_two_full.length">
                    <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide *ngIf="post.image_two">
            <div class="imageSwiper">
                <div class="left-arrow-container" (click)="swipePrev()" *ngIf="post.image_one_full && post.image_one_full.length">
                    <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
                </div>
                <ng-container *ngIf="post.image_two && post.image_two.includes('data:image/png;base64')">
                    <img [src]="helper.cdn + 'photos/'+ post.user_id + '/' + post.image_two_full" alt="">
                </ng-container>
                <ng-container *ngIf="post.image_two && !post.image_two.includes('data:image/png;base64')">
                    <video class="video-blur" [src]="helper.cdn + 'videos/blur/' + post.image_two" playsinline [muted]="muted" loop autoplay></video>
                    <video [src]="helper.cdn + 'videos/'+ post.user_id + '/' + post.image_two_full" autoplay [muted]="muted" loop playsinline
                           inViewport
                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                           (inViewportAction)="onIntersection($event)"
                    ></video>
                </ng-container>
                <div class="right-arrow-container" (click)="swipeNext()" *ngIf="post.image_three_full && post.image_three_full.length">
                    <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
                </div>
            </div>
        </ng-template>
        <ng-template swiperSlide *ngIf="post.image_three">
            <div class="imageSwiper">
                <div class="left-arrow-container" (click)="swipePrev()">
                    <svg-icon src="assets/icons/left-arrow.svg"></svg-icon>
                </div>
                <ng-container *ngIf="post.image_three && post.image_three.includes('data:image/png;base64')">
                    <img [src]="helper.cdn + 'photos/'+ post.user_id + '/' + post.image_three_full" alt="">
                </ng-container>
                <ng-container *ngIf="post.image_three && !post.image_three.includes('data:image/png;base64')">
                    <video class="video-blur" [src]="helper.cdn + 'videos/blur/' + post.image_three" playsinline [muted]="muted" loop autoplay></video>
                    <video [src]="helper.cdn + 'videos/'+ post.user_id + '/' + post.image_three_full" autoplay [muted]="muted" loop playsinline
                           inViewport
                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                           (inViewportAction)="onIntersection($event)"
                    ></video>
                </ng-container>
            </div>
        </ng-template>
    </swiper>
    <div class="other-info">
        <div class="other-info-top">
            <div class="user-info">
                <div class="user-avatar">
                    <img [src]="helper.returnAvatar(post.user_id,post.avatar)" alt="">
                    <div class="user-name">
                        <h3>{{post.userfirstname}} {{post.userlastname}}</h3>
                        <span>{{helper.returnDate(post.date_create)}}</span>
                    </div>
                </div>
            </div>
            <div class="comments-post">
                <div class="list-comments">

                    <div class="description-post">
                        <p class="description">{{post.description}}</p>
                        <p class="tags">{{post.tags}}</p>
                    </div>
                    <ng-container *ngIf="post.enable_comments && isLoading">
                        <div class="loading-comment">
                            <svg-icon class="loading-svg" src="assets/icons/loading.svg"></svg-icon>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="post.enable_comments && !isLoading">
                        <div class="comment"  *ngFor="let row of comments">
                            <div class="user-avatar" *ngIf="!row.to_comment_id">
                                <img [src]="row.avatar ? row.avatar : '../assets/images/noimage.jpg'" alt="">
                                <div class="user-name">
                                    <h3>{{row.username}}</h3>
                                    <p class="comment" [innerHTML]="highlight(row.text)"></p>
                                    <span class="more-comment">{{helper.returnDate(row.date_create)}} <span [ngClass]="detectLikeComment(row.id) ? 'active':''" (click)="likeComment(row.id,$event)">Нравится{{row.likes ? ': '+row.likes:''}}</span> <span (click)="to_user = row.user_id;to_comment = row.id;addUserToComment(row.username)">Ответить</span></span>
                                </div>
                            </div>
                            <ng-container *ngFor="let subcomment of findToComment(row.id)">
                                <div class="sub-user-avatar">
                                    <img [src]="subcomment.avatar ? subcomment.avatar : '../assets/images/noimage.jpg'" alt="">
                                    <div class="sub-user-name">
                                        <h3>{{subcomment.username}}</h3>
                                        <p class="sub-comment" [innerHTML]="highlight(subcomment.text)"></p>
                                        <span class="sub-more-comment">{{helper.returnDate(subcomment.date_create)}} <span [ngClass]="detectLikeComment(subcomment.id) ? 'active':''" (click)="likeComment(subcomment.id,$event)">Нравится{{subcomment.likes ? ': '+subcomment.likes:''}}</span> <span (click)="to_user = subcomment.user_id;to_comment = row.id;addUserToComment(subcomment.username)">Ответить</span></span>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </ng-container>

                </div>
            </div>
        </div>
        <div class="other-info-bottom">
            <div class="emojis" *ngIf="viewEmojis" [@fadeInOut]>
                <div class="haader-emoji">
                    <svg-icon src="assets/icons/emojis/love.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/pets.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/icecream.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/run.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/car.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/light.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/heart.svg"></svg-icon>
                    <svg-icon src="assets/icons/emojis/flags.svg" (click)="scrollToGroup('Flags')"></svg-icon>
                </div>
                <div class="emojis-char">
                    <div *ngFor="let row of emojis" [id]="row.group" (click)="addToComment(row.char)">{{row.char}}</div>
                </div>
            </div>
            <div class="post-buttons">
                <div class="likers-buttons">
                    <div class="button-post" [ngClass]="helper.detectLikedPost(post.id) ? 'active':''" (click)="helper.likePosts(post.id,$event)">
                        <svg-icon src="assets/icons/heart.svg"></svg-icon> <span *ngIf="post.likes">{{post.likes}}</span>
                    </div>
                    <div class="button-post repost">
                        <svg-icon src="assets/icons/repost.svg"></svg-icon>
                    </div>
                </div>
                <div class="views">
                    <div class="button-post"  *ngIf="post.views">
                        <svg-icon src="assets/icons/view.svg"></svg-icon> <span>{{post.views}}</span>
                    </div>
                </div>
            </div>
            <div class="input-chat" *ngIf="post.enable_comments">
                <svg-icon style="cursor: pointer;color:{{viewEmojis ? 'grey':'white'}}" (click)="viewEmogi()" src="assets/icons/emojis.svg"></svg-icon>
                <input class="main-input" placeholder="Добавить комментарий" type="text" [(ngModel)]="comment" (input)="detectUser($event)" (keyup.enter)="sendComment()">
                <svg-icon src="assets/icons/send.svg" style="cursor: pointer;color:{{!comment.length ? 'grey':'white'}}" (click)="sendComment()"></svg-icon>
            </div>
        </div>
    </div>
    <div class="close-button" [mat-dialog-close]="true">
        <svg-icon src="assets/icons/close.svg"></svg-icon>
    </div>
</div>
