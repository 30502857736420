<div class="wrapper" id="scrollContainer">
    <div class="main-page" *ngIf="broadcastInfo">
        <div class="header" >
            <h3>{{broadcastInfo.name}}</h3>
        </div>

        <div class="full-broadcast-page pt-25" *ngIf="broadcastInfo.type === 'web'">
            <div class="create-broadcasts-page">

                <div class="camera-settings" (mouseover)="viewinfo = true" (mouseleave)="viewinfo = false">
                    <div class="video-container-info" *ngIf="viewinfo" @fadeInOut>
                        <div class="broadcast-info-with-user">
                            <div class="viewers">
                                <div class="container-broadcast-user-info">
                                    <img [src]="helper.returnAvatar(userInfo.id,userInfo.avatar)" alt="">
                                    <h3>{{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                                </div>
                                <div class="viewers-info">
                                    <h3>Зрителей: {{users ? users.length : 0}}</h3>
                                    <div class="live">LIVE</div>
                                </div>
                            </div>
                            <div class="buttons-broadcast">
                                <div class="button-broadcast" *ngIf="muted" (click)="soundEnable(false)">
                                    <svg-icon src="assets/icons/sound-on.svg"></svg-icon>
                                </div>
                                <div class="button-broadcast" *ngIf="!muted" (click)="soundEnable(true)">
                                    <svg-icon src="assets/icons/sound-off.svg"></svg-icon>
                                </div>
                                <div class="button-broadcast" (click)="helper.copyMessage('https://coc.lol/b/'+broadcaster.room_id)"><svg-icon src="assets/icons/repost.svg"></svg-icon></div>
                            </div>
                        </div>
                        <div class="video-container-buttons">
                            <div class="tags">{{broadcastInfo.tags}}</div>
                            <button class="custom-button">Предложить приват</button>
                        </div>
                    </div>
                    <video *ngIf="!isLoading" autoplay playsinline [muted]="muted" [id]="streamAudio[0].id" [srcObject]="streamAudio[0].url"></video>
                </div>

                <div class="chat-broadcast">
                    <div class="chat-info">
                        <h3>Чат</h3>
                        <button class="danger-button"><svg-icon src="assets/icons/money.svg"></svg-icon>Отправить донат</button>
                    </div>
                    <div class="chat">
                        <div class="donate" style="background: linear-gradient(90deg, #EC3131 0%, #0B0B0D 0%);">
                            <p>{{broadcastInfo.donate_name}}: €0</p>
                        </div>
                        <div class="chat-messages">
                            <ng-container *ngFor="let row of messages">
                                <div class="message" *ngIf="row.type === 1">
                                    <span>{{row.name}}:</span> {{row.message}}
                                </div>
                                <div class="message-connect" *ngIf="row.type === 2">
                                    {{row.name}}: присоединился(ась)
                                </div>
                            </ng-container>
                        </div>
                        <div class="input-chat">
                            <svg-icon src="assets/icons/emojis.svg"></svg-icon>
                            <input class="main-input" type="text" placeholder="Написать в чат" [(ngModel)]="message"  (keyup.enter)="sendMessageBroadCast()">
                            <svg-icon src="assets/icons/send.svg" (click)="sendMessageBroadCast()"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="broadcast-info">
                <div class="container-broadcast-info">
                    <div class="container-broadcast-info-header">
                        <h3>Информация: {{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                        <div class="container-broadcast-info-header-buttons">
                            <h3>Фанатов: 15,3к</h3>
                            <button class="white-button"><svg-icon src="assets/icons/favorite.svg"></svg-icon>Отслеживать</button>
                        </div>
                    </div>
                    <p>{{broadcastInfo.description}}</p>
                    <h3>Соц. сети</h3>
                    <div class="social-network">
                        <span>
                            <svg-icon src="assets/icons/socials/telegram.svg"></svg-icon>
                            t.me
                        </span>
                        <span>
                            <svg-icon src="assets/icons/socials/instagram.svg"></svg-icon>
                            instagram
                        </span>
                    </div>
                </div>
                <h3>Посты автора</h3>
                <div class="posts">
                    <ng-container *ngFor="let row of userInfo.posts">
                        <div class="post"  (click)="helper.viewPost(row,$event)">
                            <ng-container *ngIf="row.image_one.includes('data:image/png;base64')">
                                <img class="img1" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_one_full" alt="{{row.image_one}}">
                            </ng-container>
                            <ng-container *ngIf="!row.image_one.includes('data:image/png;base64')">
                                <video [id]="'media1'+row.id"
                                       [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_one_full"
                                       playsinline
                                       autoplay
                                       [muted]="mutedPost"
                                       loop>
                                </video>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="full-broadcast-page pt-25" *ngIf="broadcastInfo.type === 'obs'">
            <div class="create-broadcasts-page">

                <div class="camera-settings" (mouseover)="viewinfo = true" (mouseleave)="viewinfo = false">
                    <div class="video-container-info" *ngIf="viewinfo" @fadeInOut>
                        <div class="broadcast-info-with-user">
                            <div class="viewers">
                                <div class="container-broadcast-user-info">
                                    <img [src]="helper.returnAvatar(userInfo.id,userInfo.avatar)" alt="">
                                    <h3>{{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                                </div>
                                <div class="viewers-info">
                                    <h3>Зрителей: {{users ? users.length : 0}}</h3>
                                    <div class="live">LIVE</div>
                                </div>
                            </div>
                            <div class="buttons-broadcast">
                                <div class="button-broadcast" *ngIf="muted" (click)="soundEnable(false)">
                                    <svg-icon src="assets/icons/sound-on.svg"></svg-icon>
                                </div>
                                <div class="button-broadcast" *ngIf="!muted" (click)="soundEnable(true)">
                                    <svg-icon src="assets/icons/sound-off.svg"></svg-icon>
                                </div>
                                <div class="button-broadcast" (click)="helper.copyMessage('https://coc.lol/b/'+broadcaster.room_id)"><svg-icon src="assets/icons/repost.svg"></svg-icon></div>
                            </div>
                        </div>
                        <div class="video-container-buttons">
                            <div class="tags">{{broadcastInfo.tags}}</div>
                            <button class="custom-button">Предложить приват</button>
                        </div>
                    </div>
                    <video id="videoElement"></video>
                </div>

                <div class="chat-broadcast">
                    <div class="chat-info">
                        <h3>Чат</h3>
                        <button class="danger-button"><svg-icon src="assets/icons/money.svg"></svg-icon>Отправить донат</button>
                    </div>
                    <div class="chat">
                        <div class="donate" style="background: linear-gradient(90deg, #EC3131 0%, #0B0B0D 0%);">
                            <p>{{broadcastInfo.donate_name}}: €0</p>
                        </div>
                        <div class="chat-messages">
                            <ng-container *ngFor="let row of messages">
                                <div class="message" *ngIf="row.type === 1">
                                    <span>{{row.name}}:</span> {{row.message}}
                                </div>
                                <div class="message-connect" *ngIf="row.type === 2">
                                    {{row.name}}: присоединился(ась)
                                </div>
                            </ng-container>
                        </div>
                        <div class="input-chat">
                            <svg-icon src="assets/icons/emojis.svg"></svg-icon>
                            <input class="main-input" type="text" placeholder="Написать в чат" [(ngModel)]="message"  (keyup.enter)="sendMessageBroadCast()">
                            <svg-icon src="assets/icons/send.svg" (click)="sendMessageBroadCast()"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="broadcast-info">
                <div class="container-broadcast-info">
                    <div class="container-broadcast-info-header">
                        <h3>Информация: {{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                        <div class="container-broadcast-info-header-buttons">
                            <h3>Фанатов: 15,3к</h3>
                            <button class="white-button"><svg-icon src="assets/icons/favorite.svg"></svg-icon>Отслеживать</button>
                        </div>
                    </div>
                    <p>{{broadcastInfo.description}}</p>
                    <h3>Соц. сети</h3>
                    <div class="social-network">
                        <span>
                            <svg-icon src="assets/icons/socials/telegram.svg"></svg-icon>
                            t.me
                        </span>
                        <span>
                            <svg-icon src="assets/icons/socials/instagram.svg"></svg-icon>
                            instagram
                        </span>
                    </div>
                </div>
                <h3>Посты автора</h3>
                <div class="posts">
                    <ng-container *ngFor="let row of userInfo.posts">
                        <div class="post"  (click)="helper.viewPost(row,$event)">
                            <ng-container *ngIf="row.image_one.includes('data:image/png;base64')">
                                <img class="img1" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_one_full" alt="{{row.image_one}}">
                            </ng-container>
                            <ng-container *ngIf="!row.image_one.includes('data:image/png;base64')">
                                <video [id]="'media1'+row.id"
                                       [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_one_full"
                                       playsinline
                                       autoplay
                                       [muted]="mutedPost"
                                       loop>
                                </video>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

    </div>
</div>
