import {Component, Inject, OnInit} from '@angular/core';
import {HelperService} from "../../services/helper.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-select-user-messages',
  templateUrl: './select-user-messages.component.html',
  styleUrls: ['./select-user-messages.component.scss']
})
export class SelectUserMessagesComponent implements OnInit {
  searchtext:string = '';
  noitem:boolean = false;
  loadingFind: boolean = false;
  users:any[]=[];
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialogRef<SelectUserMessagesComponent>,
      public helper:HelperService
  ) { }

  async ngOnInit() {
    if (this.data.type){
      this.searchtext = 'isset'
      this.users = await this.helper.getValanterUsers().toPromise();
      this.loadingFind = false;
    }
  }
  selectUser(user){
    this.dialog.close({
      user_id:user.id,
      avatar:user.avatar,
      firstname:user.firstname,
      lastname:user.lastname,
      name:user.name
    })
  }
  searchInput(ev){
    console.log(ev.target.value)
    this.loadingFind = true;
    this.searchtext = ev.target.value.replace('@','');
    setTimeout(async ()=> {
      if (this.loadingFind){
        this.users = await this.helper.findUsersChat(this.searchtext.toLowerCase()).toPromise();
        this.loadingFind = false;
      }
      if (this.searchtext === ''){
        this.loadingFind = false;
      }
    },1000)
  }

}
