<div class="wrapper">
    <div class="main-page">
        <div class="loading-user" *ngIf="isLoading" @fadeInOut>
            <ng-lottie [options]="emptyAdsAnim"></ng-lottie>
        </div>
        <ng-container *ngIf="!isLoading && no_user">
            <div class="header" @fadeInOut>
                <h3>{{username}}</h3>
            </div>
            <div class="no-items-with-recommended pt-25" @fadeInOut>
                <div class="no-item-text">
                    Пользователь не найден или недоступен для просмотра в Вашей стране! <br> Ваша страна {{helper.returnCountry(authService.currentUser.country)}}
                </div>
                <div class="recommended">
                    <h3>Рекомендации</h3>
                    <div class="recommended-profiles" >
                        <ng-container *ngFor="let row of helper.recommended">
                            <div class="recommended-profile" (click)="helper.viewUser(row.username,$event)" [ngStyle]="{'background-image': 'url(' + helper.returnBackground(row.id,row.background) + ')'}">
                                <div class="background-filter">
                                    <img [src]="helper.returnAvatar(row.id,row.avatar)" alt="">
                                    <div class="recommended-user">
                                        <div class="recommended-user-name">{{row.firstname}} {{row.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon> <span class="live-user" *ngIf="row.status">LIVE</span></div>
                                        <div class="recommended-user">@{{row.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>

        <div [hidden]="isLoading || no_user" @fadeInOut>
            <div class="header" >
                <h3 *ngIf="userInfo"><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>{{userInfo.firstname}} {{userInfo.lastname}}</h3>
            </div>
            <div class="user-page">
                <div class="full-user-page pt-25" id="scrollContainer">
                    <div class="header-user" *ngIf="userInfo">
                        <img [src]="helper.returnBackground(userInfo.id,userInfo.background)" alt="">
                        <div class="user-avatar-buttons">
                            <img [src]="helper.returnAvatar(userInfo.id,userInfo.avatar)" (click)=helper.viewMediaFile(helper.returnAvatar(userInfo.id,userInfo.avatar),$event) alt="" class="avatar">
                            <div class="user-buttons" *ngIf="authService.currentUser.id !== userInfo.id">
                                <div class="user-button" [ngClass]="helper.detectSubscriber(userInfo.id) ? 'color-disabled':'active'" (click)="helper.subscribeUser(userInfo.id,$event)">
                                    <svg-icon *ngIf="!helper.detectSubscriber(userInfo.id)" src="assets/icons/favorite.svg"></svg-icon>
                                    <svg-icon *ngIf="helper.detectSubscriber(userInfo.id)" src="assets/icons/unfavorite.svg"></svg-icon>
                                    <span>{{helper.detectSubscriber(userInfo.id) ? 'Отслеживаю':'Отслеживать'}}</span>
                                </div>
                                <div class="user-button" (click)="helper.sendMoney(userInfo,1)">
                                    <svg-icon src="assets/icons/money.svg"></svg-icon>
                                    <span>Чаевые</span>
                                </div>
                                <div class="user-button" (click)="helper.openChat(userInfo)"><svg-icon src="assets/icons/create-message.svg"></svg-icon></div>
                                <div class="user-button" (click)="helper.copyMessage('https://coc.lol/'+userInfo.name)"><svg-icon src="assets/icons/repost.svg"></svg-icon></div>
                                <svg-icon class="more-svg" src="assets/icons/more.svg" [matMenuTriggerFor]="menuBefore"></svg-icon>
                                <mat-menu #menuBefore="matMenu" [overlapTrigger]="true" xPosition="before" class="more-panel-header">
                                    <div class="more-panel-buttons">
                                        <div class="more-panel-button-danger">Пожаловаться!</div>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                    <div class="about-user" *ngIf="userInfo">
                        <div class="user-page-user-name">
                            <h3>{{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon><span class="doth"></span><span class="type-user">{{helper.returnCurrentTypeUser(userInfo.type)}}</span></h3>
                        </div>
                        <div class="user-page-user-username">
                            <div class="user-username-info">
                            <span class="user-username-info-status">
                                <span>@{{userInfo.name}}</span>
                                <span class="doth"></span>
                                <span [ngClass]="userInfo.status ? 'online':''">сейчас {{userInfo.status ? 'онлайн':'офлайн'}}</span>
                                <span class="doth"></span>
                                <span>NSFW 18+</span>
                            </span>
                                <span class=user-info-subscribers>
                                <b>117,8k</b> фанатов <b>193k</b> лайков
                            </span>
                            </div>
                            <div class="user-info-about-text">
                                <span [innerText]="userInfo.about ? userInfo.about:'Пользователь пока ничего о себе не написал'"></span>
                            </div>
                            <div class="social-networks mt-20" *ngIf="userInfo.links">
                                <div *ngFor="let row of userInfo.links" class="socialnetwork" (click)="helper.openLink(row.link)">
                                    <svg-icon src="assets/icons/socials/{{row.icon}}.svg"></svg-icon>
                                    <p class="m-0">{{row.name_link}}</p>
                                </div>
                            </div>
                            <div class="accordion mt-20">
                                <div class="acc-header" (click)="setViewContainer()">
                                    <h3 [ngClass]="viewContainer ? 'acc-opened':''">Подписаться</h3>
                                    <svg-icon *ngIf="!viewContainer" src="assets/icons/acc-add.svg"></svg-icon>
                                    <svg-icon *ngIf="viewContainer" src="assets/icons/acc-del.svg"></svg-icon>
                                </div>
                                <div @heightInOut *ngIf="viewContainer" class="acc-content">
                                    <div class="userinfo-ads">
                                        <span>1 месяц <span class="doth"></span> доступ к закрытым постам <span class="doth"></span> 2 видео</span>
                                        <span>Всего: € 9.99 </span>
                                    </div>
                                    <div class="userinfo-ads" style="background: #8E31EC">
                                        <span>1 месяц <span class="doth"></span> доступ ко всем постам <span class="doth"></span> 10 видео <span class="doth"></span> чат</span>
                                        <span>Всего: € 19.99 </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="main-hr">
                        <div class="posts" #scrollTwo>
                            <div class="filters">
                                <div class="filter" *ngIf="userInfo.posts && userInfo.posts.length" (click)="selectedType = 'posts'" [ngClass]="selectedType === 'posts' ? 'active':''">
                                    {{userInfo.posts.length}} Постов
                                </div>
                                <div class="filter" (click)="selectedType = 'free'" [ngClass]="selectedType === 'free' ? 'active':''">
                                    Бесплатные
                                </div>
                                <div class="filter" (click)="selectedType = 'media'" [ngClass]="selectedType === 'media' ? 'active':''">
                                    Медиа
                                </div>
                                <div class="filter" (click)="selectedType = 'flicks'" [ngClass]="selectedType === 'flicks' ? 'active':''">
                                    Flicks
                                </div>
                            </div>
                            <ng-container *ngIf="userInfo.posts && userInfo.posts.length && selectedType === 'posts'">
                                <ng-container *ngFor="let row of userInfo.posts">
                                    <div class="post" (click)="helper.viewPost(row,$event)">
                                        <div class="user-info">
                                            <div class="user-main-info">
                                                <img [src]="helper.returnAvatar(userInfo.id,userInfo.avatar)" alt="avatar">
                                                <div class="other-info-user">
                                                    <span class="user-name">{{userInfo.firstname}} {{userInfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></span>
                                                    <span class="user-date">{{helper.returnDate(row.date_create)}}</span>
                                                </div>
                                            </div>
                                            <div class="user-buttons">
                                                <div class="subscribe" *ngIf="authService.currentUser.id !== row.user_id && !helper.detectSubscriber(row.user_id)" (click)="helper.subscribeUser(row.user_id,$event)">{{helper.detectSubscriber(row.user_id) ? 'Вы подписаны':'Отслеживать'}}</div>
                                                <svg-icon src="assets/icons/more.svg"></svg-icon>
                                            </div>
                                        </div>
                                        <div [ngClass]="helper.returnClass(row.image_three ? 3 : (row.image_two ? 2 : 1))"  class="images-post">
                                            <!--<div class="hidden-container"></div>-->
                                            <ng-container *ngIf="row.image_one">
                                                <ng-container *ngIf="row.image_one.includes('data:image/png;base64')">
                                                    <img class="img1" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_one_full" alt="{{row.image_one}}">
                                                </ng-container>
                                                <ng-container *ngIf="!row.image_one.includes('data:image/png;base64')">
                                                    <video class="video-blur" [src]="helper.cdn + 'videos/blur/' + row.image_one" playsinline [muted]="muted" loop autoplay></video>
                                                    <video [id]="'media1'+row.id"
                                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_one_full"
                                                           playsinline
                                                           [muted]="muted"
                                                           loop
                                                           inViewport
                                                           class="img1"
                                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                                           (inViewportAction)="onIntersection($event)">
                                                    </video>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="row.image_two">
                                                <ng-container *ngIf="row.image_two.includes('data:image/png;base64')">
                                                    <img class="img2" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_two_full" alt="{{row.image_two}}">
                                                    <!--<img class="img2" [src]="row.image_two" alt="{{row.image_two}}">-->
                                                </ng-container>
                                                <ng-container *ngIf="!row.image_two.includes('data:image/png;base64')">
                                                    <video [id]="'media2'+row.id"
                                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_two_full"
                                                           playsinline
                                                           [muted]="muted"
                                                           loop
                                                           inViewport
                                                           class="img1"
                                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                                           (inViewportAction)="onIntersection($event)"></video>
                                                </ng-container>
                                            </ng-container>

                                            <ng-container *ngIf="row.image_three">
                                                <ng-container *ngIf="row.image_three.includes('data:image/png;base64')">
                                                    <img class="img3" [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_three_full" alt="{{row.image_three}}">
                                                </ng-container>
                                                <ng-container *ngIf="!row.image_three.includes('data:image/png;base64')">
                                                    <!--[src]="helper.cdn + 'videos/blur/' + row.image_three"-->
                                                    <video [id]="'media3'+row.id"
                                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_three_full"
                                                           playsinline
                                                           [muted]="muted"
                                                           loop
                                                           inViewport
                                                           class="img1"
                                                           [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                                           (inViewportAction)="onIntersection($event)"></video>
                                                </ng-container>
                                            </ng-container>

                                        </div>
                                        <div class="post-info" *ngIf="row.description">
                                            {{row.description}}
                                        </div>
                                        <div class="post-tags" *ngIf="row.tags">
                                            {{row.tags}}
                                        </div>
                                        <div class="post-buttons" >
                                            <div class="likers-buttons">
                                                <div class="button-post" [ngClass]="helper.detectLikedPost(row.id) ? 'active':''" (click)="helper.likePosts(row.id,$event)">
                                                    <svg-icon src="assets/icons/heart.svg"></svg-icon> <span *ngIf="row.likes">{{row.likes}}</span>
                                                </div>
                                                <div class="button-post" *ngIf="row.enable_comments">
                                                    <svg-icon src="assets/icons/comment.svg"></svg-icon> <span *ngIf="row.comments && row.comments.length">{{row.comments.length}}</span>
                                                </div>
                                                <div class="button-post repost" *ngIf="row.enable_repost">
                                                    <svg-icon src="assets/icons/repost.svg"></svg-icon>
                                                </div>
                                            </div>
                                            <div class="views">
                                                <div class="button-post" *ngIf="row.views">
                                                    <svg-icon src="assets/icons/view.svg"></svg-icon> <span >{{row.views}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </div>
                <div *ngIf="userInfo" class="right-user-info pt-25">
                    <div class="collections-right" >
                        <div #scrollRecommend>
                            <ng-container *ngIf="userInfo.collections && userInfo.collections.length">
                                <h3>Коллекции <span (click)="goToCollections()">еще</span></h3>
                                <div class="collections" >
                                    <ng-container  *ngFor="let collection of userInfo.collections">
                                        <div class="collection" (click)="helper.viewCollection(userInfo,collection)">
                                            <img [src]="helper.cdn + 'collections/' + userInfo.id + '/' + collection.cover" alt="">
                                            <div class="collection-name">
                                                <p>{{collection.name}}</p>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <app-recommended></app-recommended>
                </div>
            </div>
        </div>

    </div>
</div>
