import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {HelperService} from "../services/helper.service";
import {InViewportMetadata} from "ng-in-viewport";
import {Subject, takeUntil} from "rxjs";
import {SocketService} from "../services/socket.service";
import {AnimationOptions} from "ngx-lottie";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity:0}))
      ])
    ]),
    trigger('heightInOut', [
      transition(':enter', [
        style({opacity:0,height:'0px'}),
        animate(150, style({height:'*'})),
        animate(0, style({opacity:1})),
      ]),
      transition(':leave', [
        animate(50, style({opacity:0})),
        animate(250, style({height:'0px',paddingBottom:0})),
      ])
    ])
  ]
})
export class UserComponent implements OnInit {
  isLoading:boolean = true;
  no_user:boolean = false;
  username:string = '';
  muted:boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedType:string = 'posts'
  userInfo;
  emptyAdsAnim: AnimationOptions = {
    loop: true,
    path: 'assets/lottie/loading.json',
  };
  viewContainer:boolean = false;
  constructor(
      private location: Location,
      private socketService: SocketService,
      public helper: HelperService,
      private router: Router,
      public authService: AuthenticationService,
  ) {
    router.events.subscribe(async (val) => {
      let newlink = val instanceof NavigationEnd
      if (newlink){
        this.isLoading = true;
        this.username = this.location.path().slice(1);
        this.userInfo = await this.helper.getUserInfo(this.username).toPromise();
        if (this.userInfo){
          this.no_user = false;
          this.isLoading = false;
          this.socketService.detectOnline().pipe(takeUntil(this.destroy$)).subscribe(async data => {
            if (data.userid === this.userInfo.id && data.event === 'connected'){
              this.userInfo.status = 1;
            }else if (data.userid === this.userInfo.id && data.event === 'disconnected'){
              this.userInfo.status = 0;
            }
          });
        }else {
          this.isLoading = false;
          this.no_user = true;
        }
      }
    });
  }

  async ngOnInit() {
    this.username = this.location.path().slice(1);
    this.userInfo = await this.helper.getUserInfo(this.username).toPromise();
    if (this.userInfo){
      this.no_user = false;
      this.isLoading = false;
      this.socketService.detectOnline().pipe(takeUntil(this.destroy$)).subscribe(async data => {
        if (data.userid === this.userInfo.id && data.event === 'connected'){
          this.userInfo.status = 1;
        }else if (data.userid === this.userInfo.id && data.event === 'disconnected'){
          this.userInfo.status = 0;
        }
      });
    }else {
      this.isLoading = false;
      this.no_user = true;
    }
  }
  back(){
    this.location.back();
  }

  onIntersection(ev){
    const { [InViewportMetadata]: { entry }, target } = ev;
    const ratio = entry.intersectionRatio;
    if(ratio >= 0.5){
      setTimeout(() => {
        target.play()
      }, 310);
    }else if (ratio < 0.5){
      setTimeout(() => {
        target.pause();
        target.currentTime = 0;
      }, 310);
    }
  }
  async goToCollections(){
    this.helper.userInfoView = this.userInfo;
    await this.router.navigate(['collections']);
  }
  setViewContainer(){
    this.viewContainer = !this.viewContainer;
  }

}
