import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-view-attach',
  templateUrl: './view-attach.component.html',
  styleUrls: ['./view-attach.component.scss']
})
export class ViewAttachComponent implements OnInit {
  messages:string = '';
  chat_id:string = '';
  muted:boolean = true;
  filter:string = 'all'
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public helper: HelperService,
      public authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.chat_id = this.data.messages[0].chat_id
    const messages = this.data.messages.filter(e => {
      return e.file !== '' && e.file !== null
    })
    for (let row of messages){
      this.messages = this.messages + ',' + row.file
    }
    this.messages = this.messages.slice(1)
    console.log(this.messages)
    console.log(this.chat_id)
  }

}
