import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-priceandpost',
  templateUrl: './priceandpost.component.html',
  styleUrls: ['./priceandpost.component.scss']
})
export class PriceandpostComponent implements OnInit {

  constructor(
      private location: Location,
      public helper: HelperService,
      ) { }

  ngOnInit(): void {
  }

  back(){
    this.location.back();
  }
}
