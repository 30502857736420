<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3 *ngIf="step === 1">Стать автором</h3>
            <h3 *ngIf="step === 2">Заявка на подтверждение профиля</h3>
            <h3 *ngIf="step === 3">Ваша заявка на рассмотрении</h3>
        </div>

        <div class="author-page">
            <ng-container *ngIf="step === 1">
                <div class="author" id="scrollContainer">
                    <h3 class="welcome-text">Попробуйте себя <br>
                        в качестве <span>автора</span> и начните<br>
                        преображать свое творчество<br>
                        <span>в источник дохода!</span>
                    </h3>
                    <div class="welcome-images">
                        <img src="assets/images/one-author.png" alt="">
                        <img src="assets/images/two-author.png" alt="">
                        <img src="assets/images/three-author.png" alt="">
                    </div>
                    <div class="other-info-container border-gradient-rounded">
                        <svg-icon src="assets/icons/author-bonus.svg"></svg-icon>
                        <h3>Реферальные бонусы</h3>
                        <p>Вы получите специальную ссылку, по которой клиенты смогут переходить на ваш аккаунт. Вы будете получать комиссию 1% со всех средств, которые клиент тратит на COC.LOL в течение 90 дней.
                            А также вы будете получать 5% комиссию с продаж автора, когда он или она пользуется вашим реферальным кодом автора в приложении для авторов. Вы будете зарабатывать 5% в течение года, после чего комиссия понизится до 1,5%.</p>
                    </div>
                    <div class="other-info-container border-gradient-rounded">
                        <svg-icon src="assets/icons/author-subscribe.svg"></svg-icon>
                        <h3>Платные и бесплатные подписчики</h3>
                        <p>Пользователи могут бесплатно подписаться на ваш аккаунт, и вы можете публиковать для них фото / видео / тексты или отправлять им личные сообщения. Так вы всегда будете у них на виду, напоминая пользователям, что вы создаёте контент на этой платформе, на случай, если они решат оформить на вас платную подписку.</p>
                    </div>
                    <div class="other-info-container border-gradient-rounded">
                        <svg-icon src="assets/icons/author-money.svg"></svg-icon>
                        <h3>Прибыль делится 80/20</h3>
                        <p>Будучи автором, вы зарабатываете 80% со всех ваших продаж на сайте. Пользователи могут оформить на вас платную подписку и платить ежемесячно, а также вы можете продавать медиа-контент напрямую по цене, которую задаёте каждый раз. </p>
                    </div>

                    <app-accordion [data]="{header:'Сколько я буду зарабатывать?',content:'Здесь ответ'}"></app-accordion>
                    <app-accordion [data]="{header:'Сколько я буду зарабатывать?',content:'Здесь ответ'}"></app-accordion>

                </div>
                <div class="author-types">
                    <h3>Начните зарабатывать</h3>
                    <p>Подтвердите свою учетную запись, чтобы настроить стоимость банковских операций и подписки.</p>
                    <p>Приготовьте один из своих документов и будьте готовы заполнить дополнительную информацию о себе.</p>
                    <p>Мы ждем с нетерпением начать сотрудничество с вами!</p>
                    <button class="primary-button" (click)="step = 2">Стать автором</button>
                </div>
            </ng-container>

            <ng-container *ngIf="step === 2">
                <div class="author" id="scrollContainer">
                    <h3>Личная информация</h3>
                    <div class="two-grid">
                        <input type="text" placeholder="Имя" [(ngModel)]="firstname" class="main-input">
                        <input type="text" placeholder="Фамилия" [(ngModel)]="lastname" class="main-input">
                    </div>
                    <div class="four-grid mt-20">
                        <mat-form-field class="main-form-field" floatLabel="always">
                            <mat-label>День рождения</mat-label>
                            <mat-select [(ngModel)]="day">
                                <mat-option *ngFor="let row of helper.days" [value]="row">{{row}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="main-form-field" floatLabel="always">
                            <mat-label>Месяц рождения</mat-label>
                            <mat-select [(ngModel)]="month">
                                <mat-option *ngFor="let row of helper.months" [value]="row">{{row}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="main-form-field" floatLabel="always">
                            <mat-label>Год рождения</mat-label>
                            <mat-select [(ngModel)]="year">
                                <mat-option *ngFor="let row of helper.years | reverse" [value]="row">{{row}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="main-form-field" floatLabel="always">
                            <mat-label>Пол</mat-label>
                            <mat-select [(ngModel)]="sex">
                                <mat-option value="man">Женский</mat-option>
                                <mat-option value="woman">Мужской</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="two-grid mt-20 mb-20">
                        <mat-form-field class="main-form-field" floatLabel="always">
                            <mat-label>Страна</mat-label>
                            <mat-select [(ngModel)]="country">
                                <mat-option *ngFor="let row of helper.countries" [value]="row.en_short_name">{{row.en_short_name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <input type="text" placeholder="Город проживания" [(ngModel)]="city" class="main-input">
                    </div>

                    <h3>О себе и соцсети</h3>
                    <textarea class="main-textarea" [(ngModel)]="about" placeholder="Напишите немного информации о себе и о контенте, который Вы собираетесь размещать на платформе coc.lol"></textarea>
                    <textarea class="main-textarea mt-20 mb-20" [(ngModel)]="about_social" placeholder="Предоставьте ссылки на ваши аккаунты в соцсетях для верификации, например, Instagram, TikTok, OnlyFans, Chaturbate. Эти приватные данные не будут отображаться в вашем профиле."></textarea>

                    <h3>Подтверждение личности</h3>
                    <p class="color-disabled">Для обработки вашей заявки нам потребуются от вас следующие файлы:</p>
                    <ul class="main-ul">
                        <li>Изображение лицевой стороны вашего национального удостоверения личности.</li>
                        <li>Изображение обратной стороны вашего национального удостоверения личности.</li>
                        <li>Фотография, на которой вы держите свое удостоверение личности вместе с рукописной запиской, как показано в примере ниже.</li>
                        <li>Видеозапись вашего подтверждения заявки COC.LOL. Покажите свое удостоверение личности с обеих сторон в видео, перевернув его, сохраняя при этом удостоверение личности и свое лицо четко видимыми в кадре. Обязательно либо устно упомяните хотя бы свое полное имя, COC.LOL и сегодняшнюю дату, либо покажите рукописную заметку из предыдущего шага видео.</li>
                    </ul>
                    <div class="rules mb-20">
                        <img src="assets/images/one-author.png" alt="">
                        <div class="rules-info">
                            <p class="color-disabled">Правила - пожалуйста, прочитайте их, чтобы получить подтверждение быстрее.</p>
                            <ul class="main-ul">
                                <li>Изображение должно быть чётким;</li>
                                <li>Ваше удостоверение личности должно быть полностью видно в кадре;•</li>
                                <li>Фото должно быть цветным;</li>
                                <li>Текст должен быть чётко виден;</li>
                                <li>Фон должен быть минимальным;</li>
                                <li>Изображение нельзя редактировать, нельзя менять его размер или поворачивать;</li>
                                <li>Файл изображения должен иметь расширение .png или .jpg;</li>
                                <li>Размер файла не должен превышать 7МБ;</li>
                                <li>Удостоверение личности должно быть действительным и непросроченным;</li>
                                <li>Лицо будет распознаваться, поэтому его должно быть чётко видно.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="mb-20 four-grid">
                        <div class="shadow-button">
                            <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                            <p>Паспорт</p>
                        </div>
                        <div class="shadow-button">
                            <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                            <p>Разворот</p>
                        </div>
                        <div class="shadow-button">
                            <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                            <p>Фото</p>
                        </div>
                        <div class="shadow-button">
                            <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                            <p>Видео</p>
                        </div>
                    </div>

                    <h3>Контент</h3>
                    <p class="color-disabled fs-15 m-0">Публикация откровенного контента (сексуального и / или порнографического характера)?</p>

                    <div class="select-type-content three-grid">
                        <div class="checkbox-setting">
                            <input type="radio" id="cb2" name="audit" (change)="setTypeCOntent($event)" [checked]="type === 1">
                            <label for="cb2">Да</label>
                        </div>

                        <div class="checkbox-setting">
                            <input type="radio" id="cb3" name="audit" (change)="setTypeCOntent($event)" [checked]="type === 2">
                            <label for="cb3">Нет</label>
                        </div>

                        <div class="checkbox-setting">
                            <input type="radio" id="cb4" name="audit" (change)="setTypeCOntent($event)" [checked]="type === 3">
                            <label for="cb4">Может быть</label>
                        </div>
                    </div>
                </div>

                <div class="author-types">
                    <h3>Я готов</h3>
                    <p>Устанавливая флажок, вы соглашаетесь, что прочитали и поняли <span class="link">Условия обслуживания</span>. Нарушение Условий обслуживания может привести к блокировке аккаунта.</p>
                    <div class="checkbox-setting">
                        <input type="checkbox" id="cb1">
                        <label for="cb1">Я согласен с <span class="link">правилами</span></label>
                    </div>
                    <button class="primary-button mt-20" (click)="step = 3;helper.globalLoading = true">Отправить заявку</button>
                </div>
            </ng-container>
            <ng-container *ngIf="step === 3">
                <div class="author">
                    <h3>Принято!</h3>
                    <p>Ваша заявка на подтверждение личности была отправлена. Мы рассмотрим ее в течении 24 часов. </p>
                    <p>Сообщение о решении модератора придется Вам в личные сообщения и на электронную почту.</p>
                    <p>Ожидайте.</p>
                </div>
                <app-recommended></app-recommended>
            </ng-container>
        </div>

    </div>
</div>
