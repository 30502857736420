import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-view-collection',
  templateUrl: './view-collection.component.html',
  styleUrls: ['./view-collection.component.scss']
})
export class ViewCollectionComponent implements OnInit {
  muted:boolean = true;
  userInfo:any;
  collection:any;
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public helper: HelperService,
      public authService: AuthenticationService,
      ) { }

  ngOnInit(): void {
    this.userInfo = this.data.userinfo;
    this.collection = this.data.collection;
  }

}
