import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BroadcasterService} from "../services/broadcaster.service";
import {AuthenticationService} from "../services/authentication.service";
import {HelperService} from "../services/helper.service";
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {SocketService} from "../services/socket.service";
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";

declare var RTCMultiConnection: any;

declare var flvjs: any;

@Component({
  selector: 'app-create-broadcast',
  templateUrl: './create-broadcast.component.html',
  styleUrls: ['./create-broadcast.component.scss']
})
export class CreateBroadcastComponent implements OnInit,OnDestroy {
  @ViewChild('selectTypeBroadcastDialog') selectTypeBroadcastDialog:TemplateRef<any>;
  typeBroadcast:string = '';
  flvPlayer:any;

  isStream:boolean = false;
  isLoading:boolean = true;

  muted:boolean = false;


  nsfw:boolean = false;
  private:boolean = false;
  private_price:string = '';


  donate:boolean = true;
  donate_price:string = '';
  donate_name:string = '';

  name:string = '';
  tags:string = '';
  description:string = '';



  streamAudio: any[] = [];
  users: any[] = [];
  message:string = '';
  messages:any[] = [];
  constructor(
      public broadcaster: BroadcasterService,
      public helper: HelperService,
      public dialog: MatDialog,
      public router: Router,
      private socketService: SocketService,
      private location: Location,
      public authService: AuthenticationService,
  ) { }
  ngOnInit(){

  }
  ngOnDestroy(){
    if (this.typeBroadcast){
      if (this.isStream){
        let videoEl = <HTMLVideoElement>document.getElementById(this.streamAudio[0].id);
        let stream = videoEl.srcObject;
        if ("getTracks" in stream) {
          let tracks = stream.getTracks();
          tracks.forEach(function(track) {
            track.stop();
          });
        }
        videoEl.srcObject = null;
      }else {
        let videoEl = <HTMLVideoElement>document.getElementById('stream');
        let stream = videoEl.srcObject;
        if ("getTracks" in stream) {
          let tracks = stream.getTracks();
          tracks.forEach(function(track) {
            track.stop();
          });
        }
        videoEl.srcObject = null;
      }
    }
  }
  back(){
    this.location.back();
  }
  ngAfterViewInit(): void {
    this.dialog.open(this.selectTypeBroadcastDialog, {
      disableClose:true,
      panelClass:'main-modal'
    });
  }
  selectTypeBroadcast(type: string){
    this.typeBroadcast = type;
    if(this.typeBroadcast === 'web') {
      setTimeout(()=>{
        let video = <HTMLVideoElement>document.getElementById('stream');
        console.log(video)
        console.log(navigator.mediaDevices.getUserMedia)
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ audio: true, video: { width: 595, height: 743 } })
              .then(function (stream) {
                video.srcObject = stream;
                video.onloadedmetadata = function(e) {
                  video.play();
                };
              })
              .catch(function (err0r) {
                console.log("Something went wrong!");
              });
        }
        if (!video.srcObject){
          this.helper.alertMessage('Ошибка','К сожалению Вы не можете начать трансляцию без Веб камеры! Пожалуйста подключите Веб камеру').then(()=>{
            this.back();
          })
        }
      },500)
    }else if (this.typeBroadcast === 'obs') {

    }
  }
  async createBroadcast(){
    if (this.name.length && this.tags.length && this.description.length){
      this.isLoading = true;
      let data = {
        nsfw:this.nsfw,
        private:this.private,
        private_price:this.private_price,
        donate:this.donate,
        donate_price:this.donate_price,
        donate_name:this.donate_name,
        name:this.name,
        tags:this.tags,
        description:this.description,
        type:this.typeBroadcast,
      }
      const res = await this.helper.createBroadcast(data).toPromise();
      if (res.status){
        this.broadcaster.room_id = res.roomid;
        if (this.typeBroadcast === 'web'){
          await this.initMyRTCCreate();
          await this.broadcaster.connection.openOrJoin(res.roomid);
        }else if(this.typeBroadcast === 'obs') {
          let videoElement = document.getElementById('videoElement');
          this.flvPlayer = flvjs.createPlayer({
            type: 'flv',
            url: 'https://coc.lol:8443/live/'+this.authService.currentUser.media_server_key+'.flv'
          });
          this.flvPlayer.attachMediaElement(videoElement);
          this.flvPlayer.load();
          this.flvPlayer.play().then((res)=>{
            console.log(res)
          });
          this.isStream = true;
        }
        this.socketService.userBroadcastConnect(res.roomid).subscribe(async (data)=> {
          const index = this.users.findIndex(e => e.name === data.name)
          if (index>=0){
            this.users.splice(index,1)
          }
          this.users.push(data);
          this.messages.push({
            name:data.name,
            type:2
          })
        });
        this.socketService.userBroadcastDisconnect(res.roomid).subscribe(async (data)=> {
          const index = this.users.findIndex(e => e.name === data.name)
          if (index>=0){
            this.users.splice(index,1)
          }
        });
        this.socketService.getMessagesBroadcast(res.roomid).subscribe(async (data)=> {
          if (data){
            this.messages.push(data)
          }
        });
      }
    }else {
      await this.helper.alertMessage('Заполните все поля','Пожалуйста заполните все поля! ' + (this.name.length ? '':'Название трансляции ') + (this.tags.length ? '':'Теги ') + (this.description.length ? '':'Описание трансляции ')+ (this.donate_name.length ? '':'Цель доната '))
    }

  }
  async endBroadcast(){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите остановить трансляцию?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const resDialog = await dialogRef.afterClosed().toPromise();
    if (resDialog){
      const res = await this.helper.endBroadcast(this.broadcaster.room_id).toPromise();
      if (res.status){
        this.nsfw = false;
        this.private = false;
        this.private_price = '';
        this.donate = false;
        this.donate_price = '';
        this.donate_name = '';
        this.name = '';
        this.tags = '';
        this.description = '';
        this.broadcaster.connection = null;
        this.broadcaster.connected = false;
        this.broadcaster.room_id = '';
        this.isStream = false;
        await this.router.navigate(['broadcasts'], { replaceUrl: true });
        await this.helper.alertMessage('Завершено','Трансляция завершена')
        await this.broadcaster.connection.closeSocket();
      }
    }
  }
  async initMyRTCCreate() {
    try {
      this.broadcaster.connection = new RTCMultiConnection();
      this.broadcaster.connection.userid = this.authService.currentUser.id;
      this.broadcaster.connection.extra = {
        creator: this.authService.currentUser.id,
      };
      this.broadcaster.connection.setDefaultEventsForMediaElement = true;
      this.broadcaster.connection.socketURL = 'https://coc.lol:9001/';
      this.broadcaster.connection.socketMessageEvent = 'video-broadcast-demo';
      this.broadcaster.connection.maxParticipantsAllowed = 100;
      this.broadcaster.connection.autoCloseEntireSession = true;
      this.broadcaster.connection.getExternalIceServers = true;

      this.broadcaster.connection.iceServers = [];

      this.broadcaster.connection.iceServers.push({
        urls:'stun:stun.coc.lol:3478'
      });
      this.broadcaster.connection.iceServers.push({
        urls: "turn:turn.coc.lol:3478",
        username: "f2030212c710a9058bdded6f",
        credential: "L1N0jVMwJwyHOQm6",
      });
      this.broadcaster.connection.session = {
        audio: true,
        video: true,
        oneway: true
      };
      this.broadcaster.connection.codecs = {
        video: 'H264',
        audio: 'opus'
      };
      this.broadcaster.connection.mediaConstraints = {
        audio: true,
        video: true,
      }
      this.broadcaster.connection.sdpConstraints.mandatory = {
        OfferToReceiveAudio: false,
        OfferToReceiveVideo: false
      };
      this.broadcaster.connection.onstream = (event:any) => {
        console.log(event)
        this.streamAudio = [...new Map(this.streamAudio.map((m) => [m.id, m])).values()];
        this.streamAudio.unshift({
          id: event.streamid,
          url: event.stream,
          type: event.type,
          muted: false,
          userid: event.userid,
          extra: event.extra,
        });
        this.streamAudio = [...new Map(this.streamAudio.map((m) => [m.id, m])).values()];
        console.log(this.streamAudio)
        this.isLoading = false;
        this.isStream = true;
      };


      this.broadcaster.connected = true;
      this.broadcaster.connection.onstreamended = (event:any) => {
        console.log('onstreamended',event)
        const index = this.streamAudio.findIndex(e => e.userid === event.userid)
        if (index>=0){
          this.broadcaster.connection.deletePeer(event.userid);
          this.streamAudio.splice(index,1)
        }
        let mediaElement = document.getElementById(event.streamid);
        if (mediaElement) {
          mediaElement.parentNode.removeChild(mediaElement);
        }
      };
    }catch (err:any){
      console.log('catch')
    }finally {
      console.log('finally')
    }
  }
  async sendMessageBroadCast(){
    if (this.message){
      const res = await this.helper.sendMessageBroadCast(this.message,this.broadcaster.room_id).toPromise()
      if (res.status){
        this.message = '';
      }
    }
  }
  disableMicrophone(){
    if (this.muted){
      this.muted = false;
      setTimeout(() => {
        this.broadcaster.connection.attachStreams.forEach(function(localstream) { localstream.unmute('audio');});
      }, 1000);
    }else {
      this.muted = true;
      setTimeout(() => {
        this.broadcaster.connection.attachStreams.forEach(function(localstream) { localstream.mute('audio');});
      }, 1000);
    }
  }
}
