import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";

@Component({
  selector: 'app-my-devices',
  templateUrl: './my-devices.component.html',
  styleUrls: ['./my-devices.component.scss']
})
export class MyDevicesComponent implements OnInit {

  constructor(
      private location: Location,
      public dialog: MatDialog,
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  back(){
    this.location.back();
  }

  ngOnInit(): void {
  }
  async logoutDevice(id){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите завершить этот сеанс?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const resd = await dialogRef.afterClosed().toPromise();
    if (resd){
      const res = await this.helper.logoutDevice(id).toPromise();
      if (res.status){
        const index = this.authService.currentUser.sessions.findIndex(e => e.id === id)
        if (index>=0){
          this.authService.currentUser.sessions.splice(index,1)
        }
        await this.helper.alertMessage('Отлично','Сеанс успешно завершен!')
      }
    }
  }
}
