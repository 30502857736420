import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit {

  constructor(
      public helper:HelperService
  ) { }

  ngOnInit(): void {
  }
  async viewPost(postid,ev){
    const post = await this.helper.getPost(+postid).toPromise();
    await this.helper.viewPost(post,ev)
  }
}
