import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../services/helper.service";
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {


  constructor(
      private location: Location,
      public dialog: MatDialog,
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  back(){
    this.location.back();
  }
  ngOnInit(): void {
  }

}
