import { Component, OnInit } from '@angular/core';
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {

  constructor(
      public helper: HelperService
  ) { }

  ngOnInit(): void {
  }

}
