import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {SwiperOptions} from "swiper";
import {InViewportMetadata} from "ng-in-viewport";
import {SwiperComponent} from "swiper/angular";
import {animate, style, transition, trigger} from "@angular/animations";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
    selector: 'app-view-post',
    templateUrl: './view-post.component.html',
    styleUrls: ['./view-post.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [
                style({opacity:0}),
                animate(300, style({opacity:1}))
            ]),
            transition(':leave', [
                animate(300, style({opacity:0}))
            ])
        ])
    ]
})
export class ViewPostComponent implements OnInit {
    emojis = require('src/assets/json/emoji.json');
    post: any;
    muted:true;
    isLoading:boolean = true;
    viewEmojis:boolean = false;
    loadingsend:boolean = false;
    comment:string = '';
    to_user:number = 0;
    to_comment:number = 0;
    to_username:string = '';
    configslider: SwiperOptions = {
        spaceBetween: 1,
    }
    comments;
    to_comments;
    @ViewChild(SwiperComponent) swiper: SwiperComponent;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public helper: HelperService,
        public authService: AuthenticationService,
    ) {
    }

    onIntersection(ev){
        const { [InViewportMetadata]: { entry }, target } = ev;
        const ratio = entry.intersectionRatio;
        if(ratio >= 0.5){
            setTimeout(() => {
                target.play()
            }, 310);
        }else if (ratio < 0.5){
            setTimeout(() => {
                target.pause();
                target.currentTime = 0;
            }, 310);
        }
    }
    async ngOnInit() {
        console.log(this.helper.likedComments)
        this.post = this.data.data;
        const indexLike = this.helper.posts.findIndex(e => e.id === this.post.id)
        if (indexLike>=0){
            this.helper.posts[indexLike].views = this.helper.posts[indexLike].views + 1
        }
        await this.helper.viewPostFunc(this.post.id).toPromise();
        this.comments = this.post.comments.filter(e => e.to_comment_id === 0);
        this.to_comments = this.post.comments.filter(e => e.to_comment_id !== 0);
        this.isLoading = false;
    }
    scrollToGroup(group){
        let el = document.getElementById(group);
        el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }

    swipePrev() {
        this.swiper.swiperRef.slidePrev();
    }
    swipeNext() {
        this.swiper.swiperRef.slideNext();
    }
    viewEmogi(){
        this.viewEmojis = !this.viewEmojis
    }
    addToComment(char){
        this.comment = this.comment + char
    }
    addUserToComment(username){
        this.to_username = '@'+username;
        this.comment = '';
        this.comment = this.to_username + ' '+ this.comment
    }
    highlight(text) {
        let words = text.split(" ");
        for (let i = 0; i < words.length; i++) {
            if (words[i].includes("@")) {
                words[i] = '<a class="link" href="https://coc.lol/'+words[i].replace('@','')+'" target="_blank">'+words[i]+'</a>';
            }
        }
        return words.join(" ");
    }
    async sendComment(){
        if (!this.loadingsend && this.comment.length){
            this.loadingsend = true;
            const res = await this.helper.sendComment(this.post.id,this.to_user,this.comment,this.to_comment).toPromise()
            if (res.status){
                this.post.comments.push({
                    id:res.id,
                    post_id:this.post.id,
                    user_id:this.authService.currentUser.id,
                    username:this.authService.currentUser.username,
                    useravatar:this.authService.currentUser.avatar,
                    text:this.comment,
                    to_comment:this.to_user,
                    to_comment_id:this.to_comment,
                    date_create: new Date().toString(),
                })
                this.comments = this.post.comments.filter(e => e.to_comment_id === 0);
                this.to_comments = this.post.comments.filter(e => e.to_comment_id !== 0);
                this.to_comment = 0;
                this.to_username = '';
                this.to_user = 0;
                this.comment = '';
                this.loadingsend = false;
            }
        }
    }
    findToComment(id){
        let comments = this.to_comments;
        return comments.filter(e => e.to_comment_id !== 0 && e.to_comment_id=== id)
    }
    async likeComment(id,ev){
        ev.stopPropagation();
        if (!this.loadingsend){
            this.loadingsend = true;
            const res = await this.helper.likeComment(id).toPromise()
            if (res.status){
                this.loadingsend = false;
                if (res.isset){
                    const index = this.post.comments.findIndex(e => e.id === id)
                    if (index>=0){
                        this.post.comments[index].likes = this.post.comments[index].likes + 1;
                    }
                    this.helper.likedComments.push({
                        id_comment:id,
                        user_id:this.authService.currentUser.id,
                        date_create:new Date().toString()
                    })
                }else {
                    const index = this.post.comments.findIndex(e => e.id === id)
                    if (index>=0){
                        this.post.comments[index].likes = this.post.comments[index].likes - 1;
                    }
                    const indexlikedComments = this.helper.likedComments.findIndex(e => e.id_comment === id)
                    if (indexlikedComments>=0){
                        this.helper.likedComments.splice(indexlikedComments,1)
                    }
                }
            }
        }

    }
    detectLikeComment(id){
        return this.helper.likedComments.findIndex(e => e.id_comment === id) >= 0;
    }
    detectUser(ev){
        let text = ev.target.value;
        if (!text.includes(this.to_username)){
            this.to_comment = 0;
            this.to_username = '';
            this.to_user = 0;
        }
    }

}
