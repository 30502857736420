import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogModule} from '@angular/material/dialog';
import {NgForOf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {AngularSvgIconModule} from "angular-svg-icon";
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  imports: [
    NgForOf,
    MatButtonModule,
    MatDialogModule,
    AngularSvgIconModule
  ],
  standalone: true
})
export class DialogComponent implements OnInit {
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    console.log(this.data)
  }
  sendBack(status){

  }

}
