<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Уведомления</h3>
        </div>
        <div class="notify-page pt-25">
            <div class="notifys" id="scrollContainer">
                <ng-container *ngFor="let row of helper.notify">
                    <div class="notify">
                        <div class="main-notify-user-info">
                            <img [src]="helper.returnAvatar(row.user_id,row.avatar)" alt="">
                            <div class="notify-user-info">
                                <span class="notify-user-name">{{row.username}}
                                    <svg-icon src="assets/icons/confirmed.svg" *ngIf="row.verify"></svg-icon>
                                    <span class="notify-username">@{{row.name}}</span>
                                    <span class="doth"></span>
                                    <span class="date-message">{{helper.returnCurrentDateAndTime(row.date_create)}}</span>
                                </span>
                                <span class="notify-user-messages" *ngIf="row.action === 'like_post'">Понравилась Ваша публикация</span>
                            </div>
                        </div>
                        <ng-container *ngIf="row.action === 'like_post'">
                            <div class="notify-media-post" (click)="viewPost(row.action_id,$event)">
                                <img *ngIf="row.image_type === 'image'" [src]="helper.cdn + 'photos/'+ row.image_user_id + '/' + row.image" alt="">
                                <img *ngIf="row.image_type === 'video'" [src]="helper.cdn + 'videos/'+ row.image_user_id + '/' + row.image" alt="">
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages danger-color" >Ваша подписка на <b class="danger-color">Rina Evans</b> подходит к концу.</span>
                        </div>
                    </div>
                    <div class="badge-messages danger-background" >
                        Продлить
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/icons/logo-with-back.svg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">coc.lol
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages">Ваш баланс пополнен на € 0</span>
                        </div>
                    </div>
                    <div class="badge-messages danger-background" >
                        Пополнить
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages primary-color" >Запустила трансляцию!</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages" >Отметила Вас на публикации</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages">Понравился Ваш комментарий</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages danger-color">Опубликовала новый пост!</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages danger-color">Опубликовала новый Flick!</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages danger-color">Добавила новую коллекцию!</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages">Понраилась Ваша публикация</span>
                        </div>
                    </div>
                    <div class="notify-media-post" >
                        <img src="assets/tested/one.png" alt="">
                    </div>
                </div>
                <div class="notify">
                    <div class="main-notify-user-info">
                        <img src="assets/images/noimage.jpg" alt="">
                        <!--<span class="online"></span>-->
                        <div class="notify-user-info">
                            <span class="notify-user-name">Rina Evans
                                <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                <span class="notify-username">@rinaevans</span>
                                <span class="doth"></span>
                                <span class="date-message">01:45 pm, 7 apr</span>
                            </span>
                            <span class="notify-user-messages">Теперь отслеживает вас</span>
                        </div>
                    </div>
                    <div class="badge-messages" >
                        Подписаться
                    </div>
                </div>
            </div>
            <div class="notify-types">
                <div class="notify-types-info">
                    <div class="notify-type-button active">Все</div>
                    <div class="notify-type-button">Лайки</div>
                    <div class="notify-type-button">Комментарии</div>
                    <div class="notify-type-button">Фанаты</div>
                    <div class="notify-type-button">Бесплатные подписчики</div>
                    <div class="notify-type-button">Финансы</div>
                    <div class="notify-type-button">Истёкшие подписки</div>
                    <div class="notify-type-button">Промоушн</div>
                    <div class="notify-type-button">Чаевые</div>
                </div>
            </div>
        </div>
    </div>
</div>
