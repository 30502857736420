import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {ChatDialogComponent} from "../../dialogs/chat-dialog/chat-dialog.component";
import {AddLinkComponent} from "../../dialogs/add-link/add-link.component";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isLoading:boolean = false;
  background:boolean = false;
  avatar:boolean = false;
  imageAvatar;
  imageAvatarEvent;
  imageBackground;
  imageBackgroundEvent;

  firstnameLastname:string = '';
  name:string = '';
  about:string = '';
  constructor(
      public authService: AuthenticationService,
      public dialog: MatDialog,
      public helper: HelperService
  ) { }

  ngOnInit(): void {
    this.firstnameLastname = this.authService.currentUser.firstname + ' ' + this.authService.currentUser.lastname;
    this.name = this.authService.currentUser.username;
    this.about = this.authService.currentUser.about;
    this.imageBackground = this.authService.currentUser.background ? this.helper.cdn +'background/'+this.authService.currentUser.id+'/'+this.authService.currentUser.background : '../assets/images/coclolpattern.png';
    this.imageAvatar = this.authService.currentUser.avatar !== '../assets/images/noimage.jpg' ? this.helper.cdn +'avatar/'+this.authService.currentUser.id+'/'+this.authService.currentUser.avatar : '../assets/images/noimage.jpg';
  }
  async addLink(){
    let dialogRef = this.dialog.open(AddLinkComponent, {
      panelClass: 'view-chat-modal',
      data:{}
    });
    const resd = await dialogRef.afterClosed().toPromise();
    if (resd){
      console.log(resd)
    }
  }
  async removeLink(id){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите удалить ссылку?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const resd = await dialogRef.afterClosed().toPromise();
    if (resd){
      const res = await this.helper.removeLink(id).toPromise()
      if (res.status){
        const index = this.authService.currentUser.links.findIndex(e => e.id === id)
        if (index>=0){
          this.authService.currentUser.links.splice(index,1)
        }
      }
    }
  }
  async saveUserInfo(){
    this.isLoading = true;
    let formData = new FormData();
    formData.append("avatar", this.imageAvatarEvent);
    formData.append("background", this.imageBackgroundEvent);
    const data = {
      name:this.name.replace(' ',''),
      firstname:this.firstnameLastname.split(' ')[0],
      lastname:this.firstnameLastname.split(' ')[1],
      about:this.about,
    }
    formData.append('data',JSON.stringify(data))
    const res = await fetch("https://coc.lol:9125/main/saveUserInfo", {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': 'Bearer ' + AuthenticationService.jwt,
        'Authorizationuuid': `${AuthenticationService.uuid}`,
      }
    });
    let resData = await res.json();
    if (resData.status){
      if (this.imageAvatarEvent){
        this.authService.currentUser.avatar = this.helper.cdn +'avatar/'+this.authService.currentUser.id+'/'+resData.avatar
      }
      if (this.imageBackgroundEvent){
        this.authService.currentUser.background = this.helper.cdn +'background/'+this.authService.currentUser.id+'/'+resData.background
      }
      this.authService.currentUser.firstname = this.firstnameLastname.split(' ')[0];
      this.authService.currentUser.lastname = this.firstnameLastname.split(' ')[1];
      this.authService.currentUser.about = this.about;
      this.authService.currentUser.username = this.name;

      this.firstnameLastname = this.authService.currentUser.firstname + ' ' + this.authService.currentUser.lastname;
      this.name = this.authService.currentUser.username;
      this.about = this.authService.currentUser.about;

      this.background = false;
      this.avatar = false;
      this.isLoading = false;
      await this.helper.alertMessage('Отлично', 'Информация профиля успешно обновлена')
    }else {
      this.isLoading = false;
      await this.helper.alertMessage('Ошибка', this.name+' '+ resData.error)
    }
  }
  public uploadBackground() {
    this.background = true;
    this.avatar = false;
    let fileInput = document.getElementById('fileInput');
    this.clearFileInput(document.getElementById('fileInput'));
    console.log(fileInput);
    if (fileInput) fileInput.click();
    else console.log('ERROR: cannot find file input');
  }
  public uploadAvatar() {
    this.background = false;
    this.avatar = true;
    let fileInput = document.getElementById('fileInput');
    this.clearFileInput(document.getElementById('fileInput'));
    console.log(fileInput);
    if (fileInput) fileInput.click();
    else console.log('ERROR: cannot find file input');
  }
  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  resetName(ev){
    this.name = ev.target.value.replace(' ','')
  }
  onChange(ev) {
    const reader = new FileReader();
    let file = ev.target.files[0];
    reader.onloadend = () => {
      if (this.avatar){
        this.imageAvatar = reader.result;
        this.imageAvatarEvent = ev.target.files[0];
      }else {
        this.imageBackground = reader.result;
        this.imageBackgroundEvent = ev.target.files[0];
      }

    }
    reader.readAsDataURL(file);
  }

}
