<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Мои устройства</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Текущий сеанс</h3>
                <div class="emails">
                    <ng-container *ngFor="let row of authService.currentUser.sessions">
                        <div class="shadow-button mt-20" *ngIf="authService.getUUID() === row.uuid">
                            <p class="fw-300 text-flex-center"><span class="color-white">{{row.client_name}} {{row.client_version}}, {{row.device_name}} {{row.device_version}}, {{row.device_brand}}</span> <span class="doth"></span><span class="fs-13">{{row.ip}}</span><span class="doth"></span> <span class="fs-13">{{row.country}}, {{row.city}}</span></p>
                        </div>
                    </ng-container>
                </div>
                <ng-container *ngIf="authService.currentUser.sessions.length > 1">
                    <h3 class="mt-20">Другие сеансы</h3>
                    <div class="emails">
                        <ng-container *ngFor="let row of authService.currentUser.sessions">
                            <div class="shadow-button mt-20" *ngIf="authService.getUUID() !== row.uuid">
                                <p class="fw-300 text-flex-center"><span class="color-white">{{row.client_name}} {{row.client_version}}, {{row.device_name}} {{row.device_version}}, {{row.device_brand}}</span> <span class="doth"></span><span class="fs-13">{{row.ip}}</span><span class="doth"></span> <span class="fs-13">{{row.country}}, {{row.city}}</span></p>
                                <svg-icon class="danger-color" src="assets/icons/close.svg" (click)="logoutDevice(row.id)"></svg-icon>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>

            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button active" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
