<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Популярное</h3>
        </div>
        <div class="popular-page pt-25">
            <div class="loading-full-page" *ngIf="isLoading">
                <ng-lottie [options]="emptyAdsAnim"></ng-lottie>
            </div>
            <div class="grid" *ngIf="!isLoading">
                <ng-container *ngFor="let row of populars">
                    <div class="box blue" [ngClass]="returnClass(row.isUser,row.isFlick,row.isPost)">
                        <div *ngIf="row.isUser" class="popular-user" (click)="helper.viewUser(row.username,$event)">
                            <div class="popular-user-info">
                                <img [src]="helper.returnAvatar(row.id,row.avatar)" alt="">
                                <h3>{{row.firstname}} {{row.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                            </div>
                            <img [src]="helper.returnBackground(row.id,row.background)" alt="" class="user-image">
                        </div>
                        <div *ngIf="row.isPost" class="popular-post" (click)="helper.viewPost(row,$event)">
                            <ng-container *ngIf="row.image_one">
                                <ng-container *ngIf="row.image_one.includes('data:image/png;base64')">
                                    <img [src]="helper.cdn + 'photos/'+ row.user_id + '/' + row.image_one_full" alt="{{row.image_one}}" class="post-image">
                                </ng-container>
                                <ng-container *ngIf="!row.image_one.includes('data:image/png;base64')">
                                    <video [id]="'media1'+row.id"
                                           [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.image_one_full"
                                           class="post-image"
                                           autoplay
                                           playsinline
                                           [muted]="muted"
                                           loop>
                                    </video>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div *ngIf="row.isFlick" class="popular-flick" (click)="helper.viewFlick(row,$event)">
                            <svg-icon src="assets/icons/flick.svg"></svg-icon>
                            <video [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.flick_link" alt="" class="flick-video" autoplay playsinline [muted]="muted" loop></video>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
