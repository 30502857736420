<div class="main-container">
    <h3 *ngIf="!data.type">Новое сообщение</h3>
    <h3 *ngIf="data.type">Выберите волонтера</h3>
    <small *ngIf="data.type" class="ta-c">В coc.lol отвечают волонтеры. Не волнуйтесь администрация сайта всегда с ними на связи.</small>
    <input *ngIf="!data.type" type="text" class="main-input" placeholder="Кому" [(ngModel)]="searchtext" (input)="searchInput($event)">
    <div class="users" *ngIf="!noitem && searchtext.length">
        <ng-container *ngFor="let row of users">
            <div class="chat" (click)="selectUser(row)">
                <div class="main-chat-user-info">
                    <img [src]="row.avatar ? helper.returnAvatar(row.id,row.avatar) : '../assets/images/noimage.jpg'" alt="">
                    <div class="chat-user-info">
                        <span class="chat-user-name">{{row.firstname}} {{row.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></span>
                        <span class="chat-user-messages">@{{row.name}}</span>
                    </div>
                </div>
                <div class="badge-messages">
                    <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="no-find" *ngIf="noitem">Не найдено</div>
    <div class="start-find" *ngIf="!searchtext.length">Введите в поиске имя пользователя c кем хотите начать общение</div>
    <!--<button class="primary-button">
        Открыть диалог
    </button>-->
    <div class="close-button" [mat-dialog-close]="true">
        <svg-icon src="assets/icons/close.svg"></svg-icon>
    </div>
</div>
