<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="backButton()"></svg-icon>Все коллекции {{helper.userInfoView.firstname}} {{helper.userInfoView.lastname}}</h3>
            <div class="button-header" [routerLink]="'/createCollections'">Создать коллекцию</div>
        </div>
        <div class="no-items-with-recommended" *ngIf="!helper.userInfoView.collections">
            <div class="no-item-text">
                Здесь пока нет коллекций.
            </div>
            <app-recommended></app-recommended>
        </div>
        <ng-container *ngIf="helper.userInfoView.collections">
            <div class="collections-page">
                <div class="collections pt-25"
                     id="scrollContainer">
                    <ng-container *ngFor="let collection of helper.userInfoView.collections">
                        <div class="collection" (click)="helper.viewCollection(helper.userInfoView,collection)">
                            <img [src]="helper.cdn + 'collections/' + helper.userInfoView.id + '/' + collection.cover" alt="">
                            <div class="collection-name">
                                <p>{{collection.name}}</p>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <app-recommended class="pt-25"></app-recommended>
            </div>
        </ng-container>
    </div>
</div>
