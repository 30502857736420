<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Электронная почта</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Добавить электронную почту</h3>
                <input type="text" class="main-input mt-20" [disabled]="sendcodeverify" (input)="checkEmail($event)" placeholder="Эл. почта" [(ngModel)]="email">
                <small class="color-disabled mt-10 db" *ngIf="sendcodeverify">Проверочный код был отправлен на эл почту <span class="link">{{email}}</span> введите его в поле ниже для подтверждения.</small>
                <input type="text" class="main-input mt-20" *ngIf="sendcodeverify" placeholder="Провероччный код" [(ngModel)]="verifycode">
                <div class="three-grid">
                    <button class="primary-button mt-20" *ngIf="!sendcodeverify" (click)="changeEmail()" [disabled]="email === authService.currentUser.email || !validateEmail">Добавить</button>
                    <button class="primary-button mt-20" *ngIf="sendcodeverify" (click)="verifyNewEmail()" [disabled]="!verifycode">Подтвердить</button>
                </div>
                <h3 class="mt-20">Эл. почты которым разрешен вход в аккаунт</h3>
                <div class="emails">
                    <div class="shadow-button mt-20" *ngFor="let row of authService.currentUser.authdata">
                        <p>{{row.email}}
                            <br>
                            <small>Добавлен: {{helper.returnCurrentDateAndTime(row.date_create)}}</small>
                        </p>
                        <svg-icon class="danger-color" src="assets/icons/close.svg" (click)="delEmail(row.email)"></svg-icon>
                    </div>
                </div>
            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button active" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
