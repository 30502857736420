import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {firstValueFrom} from "rxjs";

@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss']
})
export class AddLinkComponent implements OnInit,OnDestroy {
  name_link:string = '';
  link:string = '';
  typeLink:string = 'other';
  loading:boolean = false;
  constructor(
      public helper: HelperService,
      public dialog: MatDialog,
      public authService: AuthenticationService,
  ) { }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {

  }
  async saveLink(){
    let checkLink = false;
    if (this.link.toLowerCase().includes('coc.lol') && this.typeLink === 'coclol'){
      checkLink = true
    }else if (this.link.toLowerCase().includes('instagram.com') && this.typeLink === 'instagram'){
      checkLink = true
    }else if (this.link.toLowerCase().includes('t.me') && this.typeLink === 'telegram'){
      checkLink = true
    }else if ((this.link.toLowerCase().includes('twitter.com') || this.link.toLowerCase().includes('x.com') && this.typeLink === 'x')){
      checkLink = true
    }else if ((this.link.toLowerCase().includes('facebook.com') || this.link.toLowerCase().includes('fb.com') && this.typeLink === 'facebook')){
      checkLink = true
    }else if (this.link.toLowerCase().includes('tiktok.com') && this.typeLink === 'tiktok'){
      checkLink = true
    }else if ((this.link.toLowerCase().includes('onlyfans.com') || this.link.toLowerCase().includes('of.com') || this.link.toLowerCase().includes('oftv.com') && this.typeLink === 'onlyfans')){
      checkLink = true
    }else if ((this.link.toLowerCase().includes('fansly.com') || this.link.toLowerCase().includes('fans.ly') && this.typeLink === 'fansly')){
      checkLink = true
    }else if (this.link.toLowerCase().includes('boosty.to') && this.typeLink === 'boosty'){
      checkLink = true
    }else if (this.link.toLowerCase().includes('vk/com') && this.typeLink === 'vk'){
      checkLink = true
    }
    if (checkLink || this.typeLink === 'other'){
      if (this.name_link.length){

        this.loading = true;
        const res = await firstValueFrom(this.helper.addLink(this.name_link,this.link,this.typeLink))
        if (res.status){
          this.authService.currentUser.links.push({
            id: res.id,
            icon: this.typeLink,
            date_create: new Date().toString(),
            name_link: this.name_link,
            link: this.link,
            user_id: this.authService.currentUser.id,
          });
          this.loading = false;
          this.dialog.closeAll();
        }else {
          this.loading = false;
          await this.helper.alertMessage('Ошибка','Что то пошло не так повторите запрос еще раз.')
        }


      }else {
        await this.helper.alertMessage('Ошибка','Пожалуйста укажите название ссылки.')
      }
    }else {
      this.typeLink = 'other';
      await this.helper.alertMessage('Ошибка','Ваша ссылка не принадлежит выбранной социальной сети. Мы выбрали универсальный логотип для вашей ссылки. ')
    }
  }
  detectSocial(ev){
    let text = ev.target.value;
    if (text.toLowerCase().includes('coc.lol')){
      this.typeLink = 'coclol'
    }else if (text.toLowerCase().includes('instagram.com')){
      this.typeLink = 'instagram'
    }else if (text.toLowerCase().includes('t.me')){
      this.typeLink = 'telegram'
    }else if (text.toLowerCase().includes('twitter.com') || text.toLowerCase().includes('x.com')){
      this.typeLink = 'x'
    }else if (text.toLowerCase().includes('facebook.com') || text.toLowerCase().includes('fb.com')){
      this.typeLink = 'facebook'
    }else if (text.toLowerCase().includes('tiktok.com')){
      this.typeLink = 'tiktok'
    }else if (text.toLowerCase().includes('onlyfans.com') || text.toLowerCase().includes('of.com') || text.toLowerCase().includes('oftv.com')){
      this.typeLink = 'onlyfans'
    }else if (text.toLowerCase().includes('fansly.com') || text.toLowerCase().includes('fans.ly') ){
      this.typeLink = 'fansly'
    }else if (text.toLowerCase().includes('boosty.to')){
      this.typeLink = 'boosty'
    }else if (text.toLowerCase().includes('vk/com')){
      this.typeLink = 'vk'
    }

  }
}
