import {Component, Input, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [
    trigger('heightInOut', [
      transition(':enter', [
        style({opacity:0,height:'0px'}),
        animate(150, style({height:'*'})),
        animate(0, style({opacity:1})),
      ]),
      transition(':leave', [
        animate(50, style({opacity:0})),
        animate(250, style({height:'0px',paddingBottom:0})),
      ])
    ])
  ]
})
export class AccordionComponent implements OnInit {
  @Input() data: {header: string, content: string};
  viewContainer:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }
  setViewContainer(){
    this.viewContainer = !this.viewContainer;
  }

}
