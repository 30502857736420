<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Отслеживаемые</h3>
        </div>

        <div class="profile-page">
            <div class="profile" id="scrollContainer">
                <div class="subscribers">
                    <ng-container *ngFor="let row of helper.subscribers">
                        <div class="subscriber mb-20">
                            <div class="subscriber-info" (click)="helper.viewUser(row.username,$event)">
                                <img [src]="helper.returnAvatar(row.to_user_id,row.avatar)" alt="">
                                <span>
                                <h3 class="m-0">{{row.username}} <svg-icon *ngIf="row.verify" src="assets/icons/confirmed.svg"></svg-icon></h3>
                                <small>@{{row.name}}</small>
                            </span>
                            </div>
                            <div class="subscriber-button" [ngClass]="helper.detectSubscriber(row.to_user_id) ? 'color-disabled':'active'" (click)="helper.subscribeUser(row.to_user_id,$event)">
                                <svg-icon *ngIf="!helper.detectSubscriber(row.to_user_id)" src="assets/icons/favorite.svg"></svg-icon>
                                <svg-icon *ngIf="helper.detectSubscriber(row.to_user_id)" src="assets/icons/unfavorite.svg"></svg-icon>
                                <span>{{helper.detectSubscriber(row.to_user_id) ? 'Отслеживаю':'Отслеживать'}}</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="profile-types">
                <app-recommended></app-recommended>
            </div>
        </div>
    </div>
</div>
