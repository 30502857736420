import { Component, OnInit } from '@angular/core';
import {HelperService} from "../../services/helper.service";

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent implements OnInit {
  items:any[] = [];
  fullrecommended:boolean = false;
  constructor(
      public helper: HelperService
  ) { }

  ngOnInit(): void {
    let recommended = this.helper.recommended;
    this.items = recommended.slice(0,3);
  }
  addNewReccomended(){
    if (this.fullrecommended){
      this.fullrecommended = false;
      this.items = this.items.slice(0,3)
    }else {
      this.fullrecommended = true;
      this.items = this.helper.recommended
    }
  }
}
