import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";
import {InViewportMetadata} from "ng-in-viewport";
import {AuthenticationService} from "../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  muted:boolean = true;
  viewPostType: string = 'all';
  constructor(
      private route: ActivatedRoute,
      public helper: HelperService,
      public router: Router,
      public location: Location,
      public authService: AuthenticationService,
  ) {

  }
  async ngOnInit() {
    if (!this.authService.isAuthenticated()){
      await this.router.navigate(['home'], { replaceUrl: true });
    }
    const path = this.location.path();
    if (path.includes('/post/')){
      let postId = +this.route.snapshot.paramMap.get('id');
      const post = await this.helper.getPost(+postId).toPromise();
      await this.helper.viewPost(post,null)
    }
    if (path.includes('/flick/')){
      let flickId = this.route.snapshot.paramMap.get('id');
      const flick = await this.helper.getFlick(+flickId).toPromise();
      await this.helper.viewFlick(flick,null)
    }
  }

  onIntersection(ev){
    const { [InViewportMetadata]: { entry }, target } = ev;
    const ratio = entry.intersectionRatio;
    if(ratio >= 0.5){
      setTimeout(() => {
        target.play()
      }, 310);
    }else if (ratio < 0.5){
      setTimeout(() => {
        target.pause();
        target.currentTime = 0;
      }, 310);
    }
  }
  async onScroll(){
    const newPosts = await this.helper.getPosts(this.helper.posts.length).toPromise();
    if (newPosts.length){
      this.helper.posts =  this.helper.posts.concat(newPosts);
    }
  }
}
