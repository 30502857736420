<div class="profile" id="scrollContainer">
    <div class="header-user">
        <input id="fileInput" style="display:none" type="file" accept="image/png,image/jpg,video/*" (change)="onChange($event)">
        <img [src]="imageBackground ? imageBackground:'assets/images/coclolpattern.png'" alt="" class="background-image">
        <div class="upload-background" (click)="uploadBackground()">
            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
            <h3>Загрузите обложку</h3>
        </div>
        <div class="user-avatar-buttons">
            <img [src]="imageAvatar ? imageAvatar:'assets/images/noimage.jpg'" alt="" class="avatar">
            <div class="upload-avatar" (click)="uploadAvatar()">
                <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                <h3>Загрузите <br> фото</h3>
            </div>
        </div>
    </div>
    <div class="userinfo mb-20">
        <div class="username">
            <h3>Никнейм</h3>
            <input class="main-input" placeholder="coc.lol/никнейм" [(ngModel)]="name" (input)="resetName($event)" (keyup.space)="resetName($event)" type="text">
        </div>
        <div class="firstname-lastname">
            <h3>Имя Фамилия</h3>
            <input class="main-input" placeholder="Имя Фамилия" [(ngModel)]="firstnameLastname" type="text">
        </div>
    </div>
    <input class="main-input" [value]="authService.currentUser.phone" [(ngModel)]="authService.currentUser.phone" mask="+0 (000) 000 00 00" style="margin-top: 22px" disabled>
    <!--<input class="main-input" [value]="authService.currentUser.email" style="margin-top: 22px" disabled>-->
    <textarea class="main-textarea" placeholder="О себе" [(ngModel)]="about" type="text" style="margin-top: 20px"></textarea>

    <h3 class="with-button" *ngIf="authService.currentUser" >Ссылки на социальные сети {{authService.currentUser.links && authService.currentUser.links.length ? authService.currentUser.links.length + '/5':''}}</h3>
    <div class="social-networks">
        <div *ngFor="let row of authService.currentUser.links" class="socialnetwork">
            <svg-icon src="assets/icons/socials/{{row.icon}}.svg"></svg-icon>
            <p class="m-0">{{row.name_link}} |</p>
            <svg-icon src="assets/icons/delete.svg" (click)="removeLink(row.id)"></svg-icon>
        </div>
        <div class="socialnetwork-addnew" (click)="addLink()">
            <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
            <p class="m-0">Добавить</p>
        </div>
    </div>

    <h3>Подписки</h3>

    <div class="button-with-next-page" (click)="helper.goToPage('priceandpost')">
        <p>Цены и пакеты</p>
        <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
    </div>

    <button class="primary-button" (click)="saveUserInfo()">
        <svg-icon class="loading-svg" src="assets/icons/loading.svg" *ngIf="isLoading"></svg-icon>
        {{!isLoading ? 'Сохранить':''}}
    </button>
</div>
