<div class="recommended">
    <h3>Рекомендации <span (click)="addNewReccomended()">{{fullrecommended ? 'свернуть':'еще'}}</span></h3>
    <div class="recommended-profiles">
        <ng-container *ngFor="let row of items">
            <div class="recommended-profile" (click)="helper.viewUser(row.username,$event)" [ngStyle]="{'background-image': 'url(' + helper.returnBackground(row.id,row.background) + ')'}">
                <div class="background-filter">
                    <img [src]="helper.returnAvatar(row.id,row.avatar)" alt="">
                    <div class="recommended-user">
                        <div class="recommended-user-name">
                            {{row.firstname}} {{row.lastname}}
                            <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                            <span class="live-user" *ngIf="row.status">ONLINE</span>
                        </div>
                        <div class="recommended-user">
                            @{{row.username}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
