import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "./services/authentication.service";
import {SocketService} from "./services/socket.service";
import {HelperService} from "./services/helper.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import {BroadcasterService} from "./services/broadcaster.service";
import {animate, style, transition, trigger} from "@angular/animations";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ]),
      transition(':leave', [
        animate(300, style({opacity:0}))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  constructor(
      public router: Router,
      private deviceService: DeviceDetectorService,
      private socketService: SocketService,
      public authService: AuthenticationService,
      public broadcaster: BroadcasterService,
      public helper: HelperService,
      ) {
  }
  async ngOnInit() {
    AuthenticationService.uuid = this.authService.getUUID();
    this.helper.isDesktopDevice = this.deviceService.isDesktop();
    await this.authService.checkToken();
    this.authService.authenticationState.subscribe(async res => {
      if (res) {
        if (!this.authService.isAuthenticated()) {
          this.authService.authenticationState.next(true);
        }
        await this.checkSession();
        this.socketService.connect();

        this.helper.posts = await this.helper.getPosts(0).toPromise();
        this.helper.flicks = await this.helper.getFlicks(0).toPromise();

        this.helper.likedPost = await this.helper.getLikedPosts().toPromise();
        this.helper.likedFlicks = await this.helper.getLikedFlicks().toPromise();

        this.helper.likedComments = await this.helper.getLikedComments().toPromise();
        this.helper.likedCommentsFlicks = await this.helper.getLikedCommentsFlicks().toPromise();

        this.helper.subscribers = await this.helper.getSubscribers().toPromise();

        this.helper.chats = await this.helper.getChats().toPromise();

        this.helper.popular = await this.helper.getPopular().toPromise();

        this.helper.recommended = await this.helper.getRecommended().toPromise();

        this.helper.broadcasts = await this.helper.getBroadcasts().toPromise();

        this.helper.countries = await this.helper.getCountries().toPromise();

        this.helper.notify = await this.helper.getNotify(0,50).toPromise();

        this.socketService.checkLogOut().subscribe(async (data)=> {
          if (this.authService.getUUID() === data){
            this.authService.logout();
          }
        });
        this.socketService.getNewNotify().subscribe(async (data)=> {
          console.log(data)
        });
        this.socketService.getNewMessageChat().subscribe(async (data)=> {
          const index = this.helper.chats.findIndex(e => e.id === data.chat_id)
          if (index >= 0){
            console.log(this.helper.chats[index].lastmessage);
            console.log(new Date().toISOString())
            this.helper.chats[index].lastmessage = new Date().toISOString();
            this.helper.chats[index].messages.unshift(data.message)
          }else {
            this.helper.chats = await this.helper.getChats().toPromise();
          }
          console.log(this.helper.chats)
        });
        this.socketService.delMessageChat().subscribe(async (data)=> {
          const indexChat = this.helper.chats.findIndex(e => e.id === data.chat_id)
          if (indexChat>=0){
            const indexMessage = this.helper.chats[indexChat].messages.findIndex(e => e.id === data.messageid)
            if (indexMessage>=0){
              this.helper.chats[indexChat].messages.splice(indexMessage,1);
            }
          }
        });
        this.socketService.newBroadcast().subscribe(async (data)=> {
          this.helper.broadcasts = await this.helper.getBroadcasts().toPromise();
        });
        this.socketService.endBroadcast().subscribe(async (data)=> {
          console.log(data)
          const index = this.helper.broadcasts.findIndex(e => e.room_id === data)
          if (index>=0){
            this.helper.broadcasts.splice(index,1)
          }
          if (this.broadcaster.room_id === data){
            await this.router.navigate(['broadcasts'], { replaceUrl: true });
            await this.helper.alertMessage('Завершено','Трансляция завершена')
          }
        });
        this.helper.isLoading = false;
      } else {
        await this.router.navigate(['login'], { replaceUrl: true });
        this.helper.isLoading = false;
      }
    })
  }
  async checkSession(){
    await this.authService.checkSession().toPromise().then(async (res) => {
      if (res) {
        this.authService.currentUser = res;
        this.authService.currentUser.avatar = this.authService.currentUser.avatar ? this.helper.returnAvatar(this.authService.currentUser.id,this.authService.currentUser.avatar) : '../assets/images/noimage.jpg';
      }
    });
  }
}
