<div class="loading" *ngIf="helper.isLoading">
    <svg-icon src="assets/images/logo.svg"></svg-icon>
</div>

<div *ngIf="helper.isDesktopDevice && !helper.isLoading" class="coclol" (wheel)="helper.updateScroll($event)">
    <app-sidebar *ngIf="authService.isAuthenticated()"></app-sidebar>
    <router-outlet></router-outlet>
    <app-xxx *ngIf="helper.xxx" @fadeInOut></app-xxx>
</div>

<div *ngIf="!helper.isDesktopDevice" class="mobile">
    <img src="assets/images/coclolpattern.png" alt="">
    <h3>
        <svg-icon src="assets/images/logo.svg"></svg-icon>
        <br>Ты явно попал сюда не зря, но мы еще не начали разработку мобильной версии сайта. Ты можешь зайти к нам позже
    </h3>
</div>
<div *ngIf="helper.globalLoading" @fadeInOut class="global-loading">
    <div class="container-loading">
        <ng-lottie [options]="helper.emptyAdsAnim"></ng-lottie>
        <h3>Загружаем</h3>
    </div>
</div>
