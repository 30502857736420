import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  constructor(
      public authService: AuthenticationService,
      public dialog: MatDialog,
      public helper: HelperService
  ) { }

  ngOnInit(): void {
  }

}
