<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Удаление аккаунта</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Нам очень жаль что так получилось</h3>
                <textarea class="main-textarea mt-20" placeholder="Расскажи почему ты решил удалить аккаунт?"></textarea>
                <small class="color-disabled">Аккаунт не будет полностью удален из системы но мы скроем уго от всех глаз. Когда ты вернешься мы все восстановим как было.</small>
                <input type="text" class="main-input mt-10" placeholder="Код из письма">
                <div class="three-grid">
                    <button class="danger-button mt-20 h-40">Удалить</button>
                </div>
            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button active" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
