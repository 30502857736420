<div class="container">
    <!--<svg-icon src="assets/images/logo.svg"></svg-icon>-->
    <h3>{{data.header}}</h3>
    <p>{{data.text ? data.text:'Что то пошло не так. Мы уже в курсе проблемы. Попробуйте повторить запрос позже'}}</p>
    <div class="buttons">
        <ng-container *ngFor="let button of data.buttons" >
            <button class='primary-button' [mat-dialog-close]="button.status">
                {{button.text}}
            </button>
        </ng-container>
    </div>
</div>
