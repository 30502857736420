import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-send-money',
  templateUrl: './send-money.component.html',
  styleUrls: ['./send-money.component.scss']
})
export class SendMoneyComponent implements OnInit {
  amount:string = '';
  message:string = '';
  //userinfo,type
  userinfo: any;
  type: number = 0
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public authService: AuthenticationService,
      public helper: HelperService
  ) { }

  ngOnInit(): void {
    this.userinfo = this.data.userinfo;
    this.type = this.data.type;
  }
  returnType(){
    if (this.type === 1){
      return 'чаевые'
    }else if(this.type === 2){
      return 'донат'
    }else {
      return ''
    }
  }
}
