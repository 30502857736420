<div class="wrapper wrapper-login">
    <div class="main-page">
        <div class="login-page">
            <div class="welcome-info">
                <div class="logo">
                    <img src="assets/images/logo.svg" alt="">
                </div>
                <div class="welcome-text">
                    Твори, <br>
                    транслируй <span>или</span><br>
                    поддержи<br>
                    любимых<br>
                    авторов!
                </div>
                <div class="welcome-text-small">
                    Платформа для создания и публикации эксклюзивного контента, <br>
                    где фанаты могут поддержать своих любимых творческих личностей <br>
                    и получать доступ к уникальным материалам.
                </div>
            </div>
            <div class="auth">
                <ng-container *ngIf="step === 1">
                    <div class="auth-title">Войди или создай аккаунт</div>
                    <!--<input type="text" class="main-input" [ngClass]="error ? 'error':''" placeholder="Электронная почта" [(ngModel)]="email" (input)="checkEmail($event)">
                    <button class="primary-button" (click)="loginEmail()" [disabled]="!validateEmail">Войти</button>-->

                    <input type="text" class="main-input" [ngClass]="error ? 'error':''" placeholder="Номер телефона" [(ngModel)]="phone" prefix="+7 " mask="(000) 000 00 00">
                    <button class="primary-button" (click)="loginPhone()" [disabled]="phone.length !== 10">Войти</button>

                    <hr>
                    <span>
                    Продолжая, вы соглашаетесь c Правилами
                    и Конфиденциальностью и подтверждаете,
                    что вам уже исполнилось 18 лет.
                </span>
                </ng-container>
                <ng-container *ngIf="step === 2">
                    <div class="auth-title">Подтвердите телефон</div>
                    <span>
                    Мы <b>позвонили</b> вам на номер телефона.
                    Введите последний 4 цифры с номера который вам позвонит
                </span>
                    <input type="text" class="main-input" [ngClass]="error ? 'error':''" placeholder="Код из письма" [(ngModel)]="code" (input)="checkCode($event)">
                    <button class="primary-button" (click)="verifyCodePhone()" [disabled]="!validateCode">Войти</button>
                    <button class="other-button" (click)="step = 1">Назад</button>
                </ng-container>
            </div>
        </div>
    </div>
</div>
