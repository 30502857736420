<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Новый Flick</h3>
        </div>
        <div class="create-post-page pt-25">
            <div class="create-post">
                <div class="select-video">
                    <div class="video-selected" (click)="!one_image ? initUpload(1):false">
                        <input id="fileInput1" style="display:none" type="file" accept="video/*" (change)="onChange($event)">
                        <ng-container *ngIf="!one_image">
                            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                            <p>Загрузите <br> Ваше видео</p>
                        </ng-container>
                        <ng-container *ngIf="one_image">
                            <video [src]="one_image" loop muted autoplay></video>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="more-information">
                <h3>Дополнительно</h3>
                <div class="info">
                    <div class="settings">
                        <div class="small-text">* ”NSFW контент” на данном ресурсе подразумевает изображения или видео содержащие исключительно наготу, порнографию и сексуальные сцены.</div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb1">
                            <label for="cb1">NSFW контент </label>
                        </div>
                    </div>
                </div>
                <h3>Информация</h3>
                <textarea class="main-textarea" rows="3" placeholder="Введите описание" [(ngModel)]="data.about"></textarea>
                <button class="primary-button" (click)="createPost()" style="width: 100%;margin-top: 20px">
                    <svg-icon class="loading-svg" src="assets/icons/loading.svg" *ngIf="loading"></svg-icon>
                    {{!loading ? 'Опубликовать':''}}
                </button>
            </div>
        </div>



    </div>
</div>
<div class="loading" *ngIf="loading">

</div>
