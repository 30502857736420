import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss']
})
export class SubscribersComponent implements OnInit {

  constructor(
      public helper:HelperService
  ) { }

  ngOnInit(): void {
  }

}
