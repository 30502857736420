import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";

@Component({
  selector: 'app-xxx',
  templateUrl: './xxx.component.html',
  styleUrls: ['./xxx.component.scss']
})
export class XxxComponent implements OnInit {

  constructor(
      public helper: HelperService
  ) { }

  ngOnInit(): void {
  }

}
