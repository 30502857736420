<div class="profile" id="scrollContainer">
    <h3>Профиль</h3>
    <div class="button-with-next-page">
        <p class="color-white">Не показывать статус активности</p>
        <div class="checkbox-setting">
            <input type="checkbox" id="cb1" [checked]="authService.currentUser.hide_status" (change)="updateStatusHide()">
            <label for="cb1"></label>
        </div>
    </div>
    <h3>Добавить заблокированное местоположение</h3>
    <small class="color-disabled">Вы можете запретить пользователям из отдельных стран доступ к вашему профилю. Помните, что IP-адрес не всегда соответствует реальному региону или городу, в котором находится пользователь. Вам необходимо самостоятельно найти баланс между конфиденциальностью и минимизацией упущенной выгоды.</small>
    <div class="one-grid mt-20">
        <mat-form-field class="main-form-field" floatLabel="always">
            <mat-label>Введите страну</mat-label>
            <mat-select>
                <input class="main-input" autofocus placeholder="Начните вводить страну" (input)="searchCountry($event)">
                <mat-option *ngFor="let row of countries" (click)="addCountryToBlocked(row)">{{row.en_short_name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="blocked-countries mt-20">
        <ng-container  *ngFor="let row of countriesBlocked">
            <div class="country-block">
                <p>{{helper.returnCountry(row)}}</p>
                <svg-icon src="assets/icons/delete.svg" (click)="delCountry(row)"></svg-icon>
            </div>
        </ng-container>
    </div>
</div>

