import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcasterService {
  connection: any;
  connected:boolean = false;
  room_id:string = '';

  constructor() { }
}
