import {Injectable} from '@angular/core';
import {map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import * as moment from "moment/moment";
import 'moment/locale/ru'  // without this line it didn't work
moment.locale('ru')
import {MatDialog} from "@angular/material/dialog";
import {ViewPostComponent} from "../dialogs/view-post/view-post.component";
import {AuthenticationService} from "./authentication.service";
import {ViewFlickComponent} from "../dialogs/view-flick/view-flick.component";
import {Router} from "@angular/router";
import {ViewMediaComponent} from "../dialogs/view-media/view-media.component";
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {ViewAttachComponent} from "../dialogs/view-attach/view-attach.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ViewCollectionComponent} from "../dialogs/view-collection/view-collection.component";
import {AnimationOptions} from "ngx-lottie";
import {SendMoneyComponent} from "../dialogs/send-money/send-money.component";
import {ChatDialogComponent} from "../dialogs/chat-dialog/chat-dialog.component";


const API_URL = 'https://coc.lol:9125/';
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  defaultImage = '/assets/images/coclolpattern.png';
  settingType:string = 'profile';
  globalLoading:boolean = false;
  userInfoView: any;
  xxx :boolean = false;
  viewMaxi :boolean = false;
  isDesktopDevice:boolean = false;

  viewAccordion:boolean = false;

  posts:any[]=[];
  flicks:any[]=[];
  cdn = 'https://coclol.ams3.cdn.digitaloceanspaces.com/';
  isLoading:boolean = true;

  audioFile:string = '';
  audioElement:any;
  audioIsPlayed:boolean = false;

  likedPost:any[]=[];
  likedFlicks:any[]=[];

  likedComments:any[]=[];
  likedCommentsFlicks:any[]=[];

  subscribers:any[]=[];

  chats:any[]=[];

  recommended:any[]=[];

  broadcasts:any[]=[];

  countries:any[]=[];

  notify:any[]=[];

  supportInfo =
    {
      id:1,
      firstname:'Support',
      lastname:'COC.LOL',
      name:'support',
      avatar:'avatar.png',
    };

  chatInfo:any

  popular:any;
  months:any;
  years:any;
  days:any;
  emptyAdsAnim: AnimationOptions = {
    loop: true,
    path: 'assets/lottie/loading.json',
  };
  constructor(
      public dialog: MatDialog,
      public router: Router,
      private snackBar: MatSnackBar,
      public authService: AuthenticationService,
      private http: HttpClient
  ) {
    this.months = moment.months()
    this.days = Array.from(Array(moment('2020-12').daysInMonth()), (_, i) => i + 1)
    const years = (back) => {
      const year = new Date().getFullYear() - 18;
      return Array.from({length: back}, (v, i) => year - back + i + 1);
    }
    this.years = years(100)

    /*let calcZoomWidth = (+document.getElementById("body").offsetWidth/1400) * 100
    if (+document.getElementById("body").offsetWidth < 1400){
      document.getElementById("body").setAttribute("style","zoom:"+calcZoomWidth+"%");
    }*/
  }
  playAudio(){
    this.audioElement = new Audio(this.audioFile);
    this.audioElement.play();
    this.audioIsPlayed = true;
  }
  pauseAudio(){
    this.audioElement.pause();
    this.audioIsPlayed = false;
  }
  detectSubscriber(userid){
    return this.subscribers.findIndex(e => e.user_id === this.authService.currentUser.id && e.to_user_id === userid) >= 0;
  }
  async goToPage(link){
    await this.router.navigate(['/'+link]);
  }
  returnCurrentTypeUser(type){
    if (type === 1){
      return 'Автор';
    }else {
      return 'Фанат';
    }
  }
  returnAvatar(user_id,filename){
    if (filename){
      return this.cdn +'avatar/'+user_id+'/'+filename
    }else {
      return '../assets/images/noimage.jpg'
    }
  }
  returnBackground(user_id,filename){
    if (filename){
      return this.cdn +'background/'+user_id+'/'+filename
    }else {
      return '../assets/images/coclolpattern.png'
    }
  }
  async alertMessage(head,text){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:head,text:text,
        buttons:[{text:'Хорошо',status:true,}]
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  detectUnreadMessages(){
    let i = 0;
    for (let row of this.chats){
      for (let message of row.messages){
        if (!message.date_read && message.user_id !== this.authService.currentUser.id){
          i = i + 1
        }
      }
    }
    return i;/*
    setTimeout(() => {
    },1000)*/
  }
  detectUnreadChats(){
    let i = 0;
    for (let row of this.chats){
      for (let message of row.messages){
        if (!message.date_read && message.user_id !== this.authService.currentUser.id){
          i = i + 1;
          break;
        }
      }
    }
    return i;
  }
  updateScroll(ev) {
    ev.stopPropagation();
    let delta = ev.deltaY;
    document.getElementById('scrollContainer').scrollTop += delta;
  }
  async viewUser(username,ev){
    ev.stopPropagation();
    await this.router.navigate(['/'+username]);
  }
  openLink(link){
    window.open("//"+link, "_blank");
  }
  getPosts(lengthbox:number) {
    const sUrl = API_URL + 'main/getPosts';
    const body = JSON.stringify({
      lengthbox
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getPost(id:number) {
    const sUrl = API_URL + 'main/getPost';
    const body = JSON.stringify({
      id
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data[0];
      } else {
        return [];
      }
    }));
  }
  getFlicks(lengthbox:number) {
    const sUrl = API_URL + 'main/getFlicks';
    const body = JSON.stringify({
      lengthbox
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getFlick(id:number) {
    const sUrl = API_URL + 'main/getFlick';
    const body = JSON.stringify({
      id
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data[0];
      } else {
        return [];
      }
    }));
  }
  getLikedPosts() {
    const sUrl = API_URL + 'main/getLikedPosts';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getUserInfo(username) {
    const sUrl = API_URL + 'main/getUserInfo';
    const body = JSON.stringify({
      username
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.user;
      } else {
        return res.status;
      }
    }));
  }
  getLikedFlicks() {
    const sUrl = API_URL + 'main/getLikedFlicks';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }

  findUsersChat(text:string) {
    const sUrl = API_URL + 'main/findUsersChat';
    const body = JSON.stringify({
      text
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      console.log(res);
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getValanterUsers() {
    const sUrl = API_URL + 'main/getValanterUsers';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      console.log(res);
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getLikedComments() {
    const sUrl = API_URL + 'main/getLikedComments';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getMessages(user_id) {
    const sUrl = API_URL + 'main/getMessages';
    const body = JSON.stringify({
      user_id
    });
    return this.http.post<any>(sUrl, body);
  }
  readMessage(messageid) {
    const sUrl = API_URL + 'main/readMessage';
    const body = JSON.stringify({
      messageid
    });
    return this.http.post<any>(sUrl, body);
  }
  addLink(name_link,link,typeLink) {
    const sUrl = API_URL + 'main/addLink';
    const body = JSON.stringify({
      name_link,link,typeLink
    });
    return this.http.post<any>(sUrl, body);
  }
  removeLink(id) {
    const sUrl = API_URL + 'main/removeLink';
    const body = JSON.stringify({
      id
    });
    return this.http.post<any>(sUrl, body);
  }
  delMessage(messageid,chatid) {
    const sUrl = API_URL + 'main/delMessage';
    const body = JSON.stringify({
      messageid,chatid
    });
    return this.http.post<any>(sUrl, body);
  }
  returnArray(text){
    return text.split(',')
  }
  getLikedCommentsFlicks() {
    const sUrl = API_URL + 'main/getLikedCommentsFlicks';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getSubscribers() {
    const sUrl = API_URL + 'main/getSubscribers';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getChats() {
    const sUrl = API_URL + 'main/getChats';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getPopular() {
    const sUrl = API_URL + 'main/getPopular';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res;
      } else {
        return [];
      }
    }));
  }
  getRecommended() {
    const sUrl = API_URL + 'main/getRecommended';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getBroadcasts() {
    const sUrl = API_URL + 'main/getBroadcasts';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getCountries() {
    const sUrl = API_URL + 'main/getCountries';
    const body = JSON.stringify({});
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  getNotify(from,to) {
    const sUrl = API_URL + 'main/getNotify';
    const body = JSON.stringify({
      from,to
    });
    return this.http.post<any>(sUrl, body).pipe(map(res => {
      if (res.status) {
        return res.data;
      } else {
        return [];
      }
    }));
  }
  likePostsFunc(post_id) {
    const sUrl = API_URL + 'main/likePosts';
    const body = JSON.stringify({
      post_id
    });
    return this.http.post<any>(sUrl, body);
  }
  async subscribeUser(user_id,ev) {
    ev.stopPropagation();
    const sUrl = API_URL + 'main/subscribeUser';
    const body = JSON.stringify({
      user_id
    });
    const res =  await this.http.post<any>(sUrl, body).toPromise();
    if (res.status){
      const index = this.subscribers.findIndex(e => e.user_id === this.authService.currentUser.id && e.to_user_id === user_id)
      if (index >=0){
        this.subscribers.splice(index,1);
      }else {
        this.subscribers.push({
          user_id:this.authService.currentUser.id,
          to_user_id:user_id,
          date:new Date().toString(),
        })
      }
    }
  }
  likeFlickFunc(post_id) {
    const sUrl = API_URL + 'main/likeFlick';
    const body = JSON.stringify({
      post_id
    });
    return this.http.post<any>(sUrl, body);
  }
  viewPostFunc(post_id) {
    const sUrl = API_URL + 'main/viewPost';
    const body = JSON.stringify({
      post_id
    });
    return this.http.post<any>(sUrl, body);
  }
  changeEmail(email) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/changeEmail';
    const body = JSON.stringify({
      email
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  changePhone(phone) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/changePhone';
    const body = JSON.stringify({
      phone
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  verifyNewEmail(email,code) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/verifyNewEmail';
    const body = JSON.stringify({
      email,code
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  verifyNewPhone(phone,code) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/verifyNewPhone';
    const body = JSON.stringify({
      phone,code
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  deleteEmail(email) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/deleteEmail';
    const body = JSON.stringify({
      email
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  deletePhone(phone) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/deletePhone';
    const body = JSON.stringify({
      phone
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  saveCountryBlocked(countries) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/saveCountryBlocked';
    const body = JSON.stringify({
      countries
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  updateStatusHide(status) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/updateStatusHide';
    const body = JSON.stringify({
      status
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  logoutDevice(id) {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/logoutDevice';
    const body = JSON.stringify({
      id
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  logoutAllDevices() {
    this.globalLoading = true;
    const sUrl = API_URL + 'main/logoutAllDevices';
    const body = JSON.stringify({
    });
    this.globalLoading = false;
    return this.http.post<any>(sUrl, body);
  }
  viewFlickFunc(post_id) {
    const sUrl = API_URL + 'main/viewFlick';
    const body = JSON.stringify({
      post_id
    });
    return this.http.post<any>(sUrl, body);
  }
  likeComment(id) {
    const sUrl = API_URL + 'main/likeComment';
    const body = JSON.stringify({
      id
    });
    return this.http.post<any>(sUrl, body);
  }
  createBroadcast(data) {
    const sUrl = API_URL + 'main/createBroadcast';
    const body = JSON.stringify({
      data
    });
    return this.http.post<any>(sUrl, body);
  }
  connectUserBroadcast(room_id) {
    const sUrl = API_URL + 'main/connectUserBroadcast';
    const body = JSON.stringify({
      room_id
    });
    return this.http.post<any>(sUrl, body);
  }
  disconnectUserBroadcast(room_id) {
    const sUrl = API_URL + 'main/disconnectUserBroadcast';
    const body = JSON.stringify({
      room_id
    });
    return this.http.post<any>(sUrl, body);
  }
  getBroadcastInfo(room_id) {
    const sUrl = API_URL + 'main/getBroadcastInfo';
    const body = JSON.stringify({
      room_id
    });
    return this.http.post<any>(sUrl, body);
  }
  sendMessageBroadCast(message,room_id) {
    const sUrl = API_URL + 'main/sendMessageBroadCast';
    const body = JSON.stringify({
      message,room_id
    });
    return this.http.post<any>(sUrl, body);
  }
  endBroadcast(room_id) {
    const sUrl = API_URL + 'main/endBroadcast';
    const body = JSON.stringify({
      room_id
    });
    return this.http.post<any>(sUrl, body);
  }
  sendComment(post_id,to_userid,comment,to_comment) {
    const sUrl = API_URL + 'main/sendComment';
    const body = JSON.stringify({
      post_id,to_userid,comment,to_comment
    });
    return this.http.post<any>(sUrl, body);
  }
  getApiStreams() {
    const sUrl =  'https://coc.lol:8443/api/streams';
    return this.http.get<any>(sUrl).pipe(map(res => {
      if (res.live) {
        return res.live;
      } else {
        return [];
      }
    }));
  }
  sendCommentFlicks(post_id,to_userid,comment,to_comment) {
    const sUrl = API_URL + 'main/sendCommentFlicks';
    const body = JSON.stringify({
      post_id,to_userid,comment,to_comment
    });
    return this.http.post<any>(sUrl, body);
  }
  async likePosts(post_id,ev) {
    ev.stopPropagation();
    const res = await this.likePostsFunc(post_id).toPromise();
    if (res.status) {
      if (res.isset){
        this.likedPost.push({
          id: res.isset,
          post_id: post_id,
          user_id: this.authService.currentUser.id,
          date_create: new Date().toString()
        })
        const index = this.posts.findIndex(e => e.id === post_id)
        if (index>=0){
          this.posts[index].likes = this.posts[index].likes + 1
        }
      }else {
        const index = this.likedPost.findIndex(e => e.post_id === post_id)
        if (index>=0){
          this.likedPost.splice(index,1)
        }
        const indexLike = this.posts.findIndex(e => e.id === post_id)
        if (indexLike>=0){
          this.posts[indexLike].likes = this.posts[indexLike].likes - 1
        }
      }
    }
  }
  async likeFlick(post_id,ev) {
    ev.stopPropagation();
    const res = await this.likeFlickFunc(post_id).toPromise();
    if (res.status) {
      if (res.isset){
        this.likedFlicks.push({
          id: res.isset,
          post_id: post_id,
          user_id: this.authService.currentUser.id,
          date_create: new Date().toString()
        })
        const index = this.flicks.findIndex(e => e.id === post_id)
        if (index>=0){
          this.flicks[index].likes = this.flicks[index].likes + 1
        }
      }else {
        const index = this.likedFlicks.findIndex(e => e.post_id === post_id)
        if (index>=0){
          this.likedFlicks.splice(index,1)
        }
        const indexLike = this.flicks.findIndex(e => e.id === post_id)
        if (indexLike>=0){
          this.flicks[indexLike].likes = this.flicks[indexLike].likes - 1
        }
      }
    }
  }
  detectLikedPost(id){
    return this.likedPost.findIndex(e => e.post_id === id) >= 0;
  }
  detectLikedFlicks(id){
    return this.likedFlicks.findIndex(e => e.post_id === id) >= 0;
  }
  async openChat(userinfo){
    let dialogRef = this.dialog.open(ChatDialogComponent, {
      panelClass: 'view-chat-modal',
      data:{
        userinfo
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  reloadApp(){
    location.reload()
  }
  async sendMoney(userinfo,type){
    let dialogRef = this.dialog.open(SendMoneyComponent, {
      panelClass: 'view-send-money-modal',
      data:{
        userinfo,type
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  async viewAttach(messages,text){
    let dialogRef = this.dialog.open(ViewAttachComponent, {
      panelClass: 'view-attach-modal',
      data:{
        messages
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  async viewCollection(userinfo,collection){
    let dialogRef = this.dialog.open(ViewCollectionComponent, {
      panelClass: 'view-collection-modal',
      data:{
        userinfo,collection
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  async viewPost(data,ev){
    if (ev){ev.stopPropagation();}
    let dialogRef = this.dialog.open(ViewPostComponent, {
      panelClass: 'view-post-modal',
      data:{
        data
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  async viewMediaFile(link,ev){
    console.log('123')
    console.log(link)
    ev.stopPropagation();
    let dialogRef = this.dialog.open(ViewMediaComponent, {
      panelClass: 'view-media-modal',
      data:{
        link
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }
  async viewFlick(data,ev){
    if (ev){
      ev.stopPropagation();
    }
    let dialogRef = this.dialog.open(ViewFlickComponent, {
      panelClass: 'view-flick-modal',
      data:{
        data
      }
    });
    return await dialogRef.afterClosed().toPromise();
  }

  returnLocaleBalance(num){
    return new Intl.NumberFormat().format(num)
  }
  returnDate(date){
    date = new Date(date)
    let exp = moment(date, 'DD.MM.YYYY HH:mm:ss');
    let minutes = moment().diff(exp, 'minutes');
    if (minutes < 1){
      return 'Меньше минуты';
    }else if (minutes < 10){
      return 'Меньше 10 минут';
    }else if (minutes < 30){
      return 'Меньше 30 минут';
    }else if (minutes < 60){
      return 'Меньше часа';
    }else if(minutes < 180){
      return 'Меньше 3 часов';
    }else if(minutes < 360){
      return 'Меньше 6 часов';
    }else if(minutes < 720){
      return 'Меньше 12 часов';
    }else{
      return moment(date).format('Do MMMM YYYY, h:mm a');
    }
  }

  returnDateMessage(dateMessage){
    dateMessage = new Date(dateMessage)
    let currentDate = new Date();

    let today = moment(dateMessage);
    let yesterday = moment(currentDate);

    if (today.isSame(yesterday, 'd')) {
      return moment(dateMessage).format('HH:mm');
    } else {
      return moment(dateMessage).format('Do MMMM YYYY , HH:mm');
    }
  }
  returnToday(dateMessage){
    dateMessage = new Date(dateMessage)
    let currentDate = new Date();

    let today = moment(dateMessage);
    let yesterday = moment(currentDate);

    if (today.isSame(yesterday, 'd')){
      return true
    }else {
      return false
    }
  }
  returnCurrentDate(date){
    return moment(date).format('Do MMMM YYYY');
  }
  returnCurrentDateAndTime(date){
    return moment(date).format('Do MMMM YYYY , HH:mm');
  }
  returnClass(num){
    if(num === 2){
      return  'two-images'
    }else if(num === 3){
      return  'three-images'
    }else {
      return 'one-images'
    }
  }
  snackBarMessage(message,textclose = 'Закрыть') {
    this.snackBar.open(message, textclose, {
      panelClass: ['snackbar'],
      duration: 5000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }
  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBarMessage('Скопировано')
  }
  returnCountry(alpha_2_code){
    const index = this.countries.findIndex(e => e.alpha_2_code === alpha_2_code)
    if (index>=0){
      return this.countries[index].en_short_name
    }else {
      return 'Не определено'
    }
  }
}
