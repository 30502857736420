import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import * as io from 'socket.io-client';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  socket: any;
  constructor() { }
  disconect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
  connect() {
    // @ts-ignore
    this.socket = io('https://coc.lol:9125');
    this.socket.on('connect', (res:any) => {
      this.socket.emit('authenticate', {token: AuthenticationService.jwt})
        .on('authenticated', (data:any) => {
          console.warn('подключился к сокету')
        })
        .on('unauthorized', function(msg:any) {
          console.log('unauthorized: ' + JSON.stringify(msg.data));
        });
    });
  }
  connectToRoom(chat_id) {
    this.socket.emit('connect-to-room', chat_id);
  }
  disConnectToRoom(chat_id) {
    this.socket.emit('leave-from-room', chat_id);
  }
  printsMessage(data) {
    this.socket.emit('prints', data);
  }
  emit(event:any, ...args: any[]) {
    this.socket.emit(event, ...args);
  }
  on(name:any, data:any) {
    this.socket.on(name, data);
  }
  updateRooms() {
    return new Observable<any>(observer => {
      this.socket.on('updateRooms', (data:any) => {
        observer.next(data);
      });
    });
  }
  userPrintsMessage() {
    return new Observable<any>(observer => {
      this.socket.on('user-prints', (data) => {
        observer.next(data);
      });
    });
  }
  getNewMessageChat() {
    return new Observable<any>(observer => {
      this.socket.on('newMessageChat', (message:any) => {
        observer.next(message);
      });
    });
  }
  checkLogOut() {
    return new Observable<any>(observer => {
      this.socket.on('logout-user', (message:any) => {
        observer.next(message);
      });
    });
  }
  delMessageChat() {
    return new Observable<any>(observer => {
      this.socket.on('delMessageChat', (message:any) => {
        observer.next(message);
      });
    });
  }
  getNewNotify() {
    return new Observable<any>(observer => {
      this.socket.on('updateNotify', (message:any) => {
        observer.next(message);
      });
    });
  }
  newBroadcast() {
    return new Observable<any>(observer => {
      this.socket.on('new-broadcast', (message:any) => {
        observer.next(message);
      });
    });
  }
  userBroadcastConnect(roomid) {
    return new Observable<any>(observer => {
      this.socket.on('user-broadcast-connect-'+roomid, (message:any) => {
        observer.next(message);
      });
    });
  }
  userBroadcastDisconnect(roomid) {
    return new Observable<any>(observer => {
      this.socket.on('user-broadcast-disconnect-'+roomid, (message:any) => {
        observer.next(message);
      });
    });
  }
  getMessagesBroadcast(roomid) {
    return new Observable<any>(observer => {
      this.socket.on('messages-broadcast-'+roomid, (message:any) => {
        observer.next(message);
      });
    });
  }
  endBroadcast() {
    return new Observable<any>(observer => {
      this.socket.on('end-broadcast', (message:any) => {
        observer.next(message);
      });
    });
  }
  connectUser() {
    return new Observable<any>(observer => {
      this.socket.on('connectUser', (data:any) => {
        observer.next(data);
      });
    });
  }
  mute(event:any,roomid:any) {
    const data = {
      event,roomid
    }
    this.socket.emit('iam-mute', data);
  }
  getmute() {
    return new Observable<any>(observer => {
      this.socket.on('mute-man', (data:any) => {
        observer.next(data);
      });
    });
  }
  detectOnline() {
    return new Observable<any>(observer => {
      this.socket.on('users-changed', (data) => {
        observer.next(data);
      });
    });
  }
}
