<div class="add-link">
    <h3 class="m-0">Текст ссылки</h3>
    <input type="text" class="main-input mt-20 mb-20" placeholder="Текст, который будет отображаться на кнопке" [(ngModel)]="name_link">
    <h3 class="m-0 ">Ссылка</h3>
    <input type="text" class="main-input mt-20 mb-20" placeholder="Вставьте ссылку" (input)="detectSocial($event)" [(ngModel)]="link">
    <h3 class="m-0">Социальная сеть</h3>
    <div class="socialnetworks mt-20">
        <svg-icon src="assets/icons/socials/other.svg" [ngClass]="typeLink === 'other' ? 'active':''" (click)="typeLink = 'other'"></svg-icon>
        <svg-icon src="assets/icons/socials/coclol.svg" [ngClass]="typeLink === 'coclol' ? 'active':''" (click)="typeLink = 'coclol'"></svg-icon>
        <svg-icon src="assets/icons/socials/instagram.svg" [ngClass]="typeLink === 'instagram' ? 'active':''" (click)="typeLink = 'instagram'"></svg-icon>
        <svg-icon src="assets/icons/socials/telegram.svg" [ngClass]="typeLink === 'telegram' ? 'active':''" (click)="typeLink = 'telegram'"></svg-icon>
        <svg-icon src="assets/icons/socials/x.svg" [ngClass]="typeLink === 'x' ? 'active':''" (click)="typeLink = 'x'"></svg-icon>
        <svg-icon src="assets/icons/socials/facebook.svg" [ngClass]="typeLink === 'facebook' ? 'active':''" (click)="typeLink = 'facebook'"></svg-icon>
        <svg-icon src="assets/icons/socials/tiktok.svg" [ngClass]="typeLink === 'tiktok' ? 'active':''" (click)="typeLink = 'tiktok'"></svg-icon>
        <svg-icon src="assets/icons/socials/onlyfans.svg" [ngClass]="typeLink === 'onlyfans' ? 'active':''" (click)="typeLink = 'onlyfans'"></svg-icon>
        <svg-icon src="assets/icons/socials/fansly.svg" [ngClass]="typeLink === 'fansly' ? 'active':''" (click)="typeLink = 'fansly'"></svg-icon>
        <svg-icon src="assets/icons/socials/boosty.svg" [ngClass]="typeLink === 'boosty' ? 'active':''" (click)="typeLink = 'boosty'"></svg-icon>
        <svg-icon src="assets/icons/socials/vk.svg" [ngClass]="typeLink === 'vk' ? 'active':''" (click)="typeLink = 'vk'"></svg-icon>
    </div>
    <button class="primary-button mt-20" (click)="saveLink()">
        <svg-icon class="loading-svg" src="assets/icons/loading.svg" *ngIf="loading"></svg-icon>
        {{!loading ? 'Добавить ссылку':''}}
    </button>
</div>
