<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Новая коллекция</h3>
        </div>
        <div class="create-collection-page">
            <div class="create-collection pt-25">
                <div class="select-photo">

                    <input id="fileInput" style="display:none" type="file" accept="image/png,image/jpg" (change)="onChangeFile($event)">

                    <div class="photo-selected" (click)="!cover ? attachFile():false">
                        <ng-container *ngIf="!cover">
                            <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                            <p>Загрузите обложку коллекции</p>
                        </ng-container>
                        <ng-container *ngIf="cover">
                            <img [src]="cover" alt="one_image">
                            <div class="edit-image">
                                <svg-icon class="delete-image" (click)="clearImage()" src="assets/icons/delete.svg"></svg-icon>
                            </div>
                        </ng-container>
                    </div>

                </div>
                <h3>Информация</h3>
                <input placeholder="Название коллекции" class="main-input" [(ngModel)]="data.name">
                <div class="textarea-container">
                    <textarea rows="3" placeholder="Описание"  class="main-textarea" [(ngModel)]="data.about"></textarea>
                    <span class="charecter-count" [ngClass]="data.about.length > 255 ? 'danger-color':''">{{data.about.length}}/255</span>
                </div>
                <div class="dropped-images">
                    <ngx-file-drop dropZoneClassName='drop-zone-collections' (onFileDrop)="dropped($event)"
                                   (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                            <div class="no-files" *ngIf="!files.length">
                                <svg-icon src="assets/icons/select-image.svg"></svg-icon>
                                <p>Перетащите файлы сюда или</p>
                                <button class="primary-button-outline" (click)="openFileSelector()">Загрузите файлы</button>
                            </div>
                            <div class="attached-files" *ngIf="files.length">
                                <ng-container *ngFor="let row of files;let i = index">
                                    <div class="attached-file">
                                        <img *ngIf="row.type==='image'" [src]="row.preview" alt="">
                                        <video *ngIf="row.type==='video'" [src]="row.preview" autoplay playsinline loop [muted]="muted"></video>
                                        <span class="delete-file-button-attach" (click)="deleteFile(i)">
                                    <svg-icon src="assets/icons/delete.svg" class="loading-send"></svg-icon>
                                </span>
                                    </div>
                                </ng-container>
                                <svg-icon class="addSvg" src="assets/icons/add.svg" (click)="openFileSelector()"></svg-icon>
                            </div>
                        </ng-template>
                    </ngx-file-drop>
                </div>
            </div>
            <div class="more-information pt-25">
                <h3 style="margin-top: 0;">Дополнительно</h3>
                <div class="info">
                    <div class="settings">
                        <div class="small-text">* ”NSFW контент” на данном ресурсе подразумевает изображения или видео содержащие исключительно наготу, порнографию и сексуальные сцены.</div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb1" [checked]="data.nfsw" (change)="setNSFW($event)">
                            <label for="cb1">NSFW контент </label>
                        </div>
                    </div>
                    <div class="audit">
                        <h3>Цена</h3>
                        <input id="price" placeholder="Сумма покупки" class="main-input" type="number" style="margin-bottom: 10px" [(ngModel)]="data.price">
                    </div>
                    <button class="primary-button" (click)="createCollection()" style="width: 100%">
                        <svg-icon class="loading-svg" src="assets/icons/loading.svg" *ngIf="loading"></svg-icon>
                        {{!loading ? 'Опубликовать':''}}
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="loading" *ngIf="loading">

</div>
