<div class="sidebar-page">
    <div class="logo" [routerLink]="'/home'">
        <img src="../../../assets/images/logo.svg" alt="">
    </div>
    <div class="logo-tablet" [routerLink]="'/home'">
        <img src="../../../assets/images/logo-mini.svg" alt="">
    </div>
    <div class="mini-profile" *ngIf="authService.isAuthenticated() && authService.currentUser && !helper.viewMaxi" (click)="helper.viewMaxi = true" @fadeInOut>
        <img [src]="authService.currentUser.avatar ? authService.currentUser.avatar : '../assets/images/noimage.jpg'" alt="">
        <p><span>{{authService.currentUser.username? authService.currentUser.username : authService.currentUser.email}}</span></p>
        <svg-icon src="assets/icons/right-arrow.svg"></svg-icon>
    </div>
    <div class="profile-tablet" *ngIf="authService.isAuthenticated() && authService.currentUser && !helper.viewMaxi" (click)="helper.viewMaxi = true" @fadeInOut>
        <img [src]="authService.currentUser.avatar ? authService.currentUser.avatar : '../assets/images/noimage.jpg'" alt="">
    </div>
    <ng-container *ngIf="helper.viewMaxi">
        <div class="maxi-profile" @fadeInOut>
            <div class="close" (click)="helper.viewMaxi = false">
                <svg-icon src="assets/icons/close.svg"></svg-icon>
            </div>
            <div class="user-info-maxi">
                <img (click)="helper.viewMaxi = false" [src]="authService.currentUser.avatar ? authService.currentUser.avatar : '../assets/images/noimage.jpg'" alt="">
                <h3>{{authService.currentUser.firstname}} {{authService.currentUser.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                <span class="user-info-username">@{{authService.currentUser.username}}</span>
                <div class="user-info-likes">
                    <span><b>117,8k</b> фанатов</span>
                    <span class="doth"></span>
                    <span><b>19</b> подписок</span>
                </div>
                <div class="user-info-balance">
                    <span>Баланс:</span>
                    <span class="user-info-amount">€ {{helper.returnLocaleBalance(authService.currentUser.coin)}}</span>
                    <span><svg-icon src="assets/icons/add-circle.svg"></svg-icon></span>
                </div>
            </div>
            <div class="maxi-profile-buttons">
                <div class="maxi-profile-button" [ngClass]="active === authService.currentUser.username ? 'active':''" [routerLink]="authService.currentUser.username" (click)="helper.viewUser(authService.currentUser.username,$event);active = authService.currentUser.username;">
                    <svg-icon src="assets/icons/profile.svg"></svg-icon>
                    <h3>Мой профиль</h3>
                </div>
                <hr>
                <div class="maxi-profile-button">
                    <svg-icon src="assets/icons/favorite.svg"></svg-icon>
                    <h3>Платные подписки</h3>
                </div>
                <div class="maxi-profile-button">
                    <svg-icon src="assets/icons/collections.svg"></svg-icon>
                    <h3>Покупки</h3>
                </div>
                <div class="maxi-profile-button" [ngClass]="active === 'subscribers' ? 'active':''" routerLink="/profile" (click)="goToPage('subscribers')">
                    <svg-icon src="assets/icons/favorite.svg"></svg-icon>
                    <h3>Отслеживаемые</h3>
                </div>
                <hr>
                <div class="maxi-profile-button" [ngClass]="active === 'bectoauthor' ? 'active':''" routerLink="/profile" (click)="goToPage('bectoauthor')">
                    <svg-icon src="assets/icons/stylus.svg"></svg-icon>
                    <h3>Стать автором</h3>
                </div>
                <div class="maxi-profile-button" [ngClass]="active === 'support' ? 'active':''" routerLink="/support" (click)="goToPage('support')">
                    <svg-icon src="assets/icons/faq.svg"></svg-icon>
                    <h3>Поддержка</h3>
                </div>
                <div class="maxi-profile-button" [ngClass]="active === 'setting' ? 'active':''" routerLink="/profile" (click)="helper.settingType = 'profile';goToPage('setting')">
                    <svg-icon src="assets/icons/settings.svg"></svg-icon>
                    <h3>Настройки</h3>
                </div>
                <hr>
                <div class="maxi-profile-button logout" (click)="logOut()">
                    <svg-icon src="assets/icons/logout.svg"></svg-icon>
                    <h3>Выйти</h3>
                </div>
            </div>
        </div>
    </ng-container>

    <a class="button-menu" [ngClass]="active === 'home' || active === '' ? 'active':''" routerLink="/home" (click)="goToPage('home')">
        <svg-icon src="assets/icons/home.svg"></svg-icon>
        <span>Главная</span>
    </a>

    <a class="button-menu" [ngClass]="active.toLowerCase().includes('broadcast') ? 'active':''" routerLink="/broadcasts" (click)="goToPage('broadcasts')">
        <svg-icon src="assets/icons/translations.svg"></svg-icon>
        <span>Трансляции</span>
    </a>

    <a class="button-menu" [ngClass]="active === 'flicks' ? 'active':''" routerLink="/flicks" (click)="goToPage('flicks')">
        <svg-icon src="assets/icons/flick.svg"></svg-icon>
        <span>Flicks</span>
    </a>

    <a class="button-menu" [ngClass]="active === 'popular' ? 'active':''" routerLink="/popular" (click)="goToPage('popular')">
        <svg-icon src="assets/icons/crown.svg"></svg-icon>
        <span>Популярное</span>
    </a>

    <a class="button-menu" [ngClass]="active === 'chat' ? 'active':''"  routerLink="/chat" (click)="goToPage('chat')">
        <svg-icon src="assets/icons/chat.svg"></svg-icon>
        <span>Сообщения</span>
        <span class="badge-messages" *ngIf="helper.detectUnreadChats()">{{helper.detectUnreadChats()}}</span>
    </a>

    <a class="button-menu"  [ngClass]="active === 'notify' ? 'active':''" routerLink="/notify" (click)="goToPage('notify')">
        <svg-icon src="assets/icons/notify.svg"></svg-icon>
        <span>Уведомления</span>
        <span class="badge"></span>
    </a>

    <a class="button-menu" [ngClass]="active === 'search' ? 'active':''" routerLink="/search" (click)="goToPage('search')">
        <svg-icon src="assets/icons/search.svg"></svg-icon>
        <span>Поиск</span>
    </a>
    <!--<a class="button-menu" [ngClass]="active === 'rtmp' ? 'active':''" routerLink="/rtmp" (click)="goToPage('rtmp')">
        <svg-icon src="assets/icons/dick.svg"></svg-icon>
        <span>TEST</span>
    </a>-->

    <a class="button-menu" [matMenuTriggerFor]="menuAfterHeader">
        <svg-icon src="assets/icons/add.svg"></svg-icon>
        <span>Добавить</span>
        <mat-menu #menuAfterHeader="matMenu" [overlapTrigger]="true" xPosition="after" class="more-panel-header">
            <div class="more-panel-buttons">
                <div [routerLink]="'/createPost'">
                    <svg-icon src="assets/icons/post.svg"></svg-icon>
                    Пост
                </div>
                <div [routerLink]="'/createBroadcast'">
                    <svg-icon src="assets/icons/translations.svg"></svg-icon>
                    Трансляция
                </div>
                <div [routerLink]="'/createFlick'">
                    <svg-icon src="assets/icons/flick.svg"></svg-icon>
                    Flick
                </div>
                <div [routerLink]="'/createFlick'">
                    <svg-icon src="assets/icons/web-video.svg"></svg-icon>
                    Видео
                </div>
                <div [routerLink]="'/createCollections'">
                    <svg-icon src="assets/icons/collections.svg"></svg-icon>
                    Коллекция
                </div>
            </div>
        </mat-menu>
    </a>

    <hr>
    <div class="footer">
        <a routerLink="">About</a> • <a routerLink="">Help</a> • <a routerLink="">Press</a> • <a routerLink="">API</a> • <a routerLink="">Jobs</a> • <a routerLink="">Privacy</a> •
        <a routerLink="">Terms</a> • <a routerLink="">Lotations</a> • <a routerLink="">Language</a>
        <br>
        <span class="coc-lol">
            © 2024 COC.LOL with <svg-icon (click)="getClick($event)" src="assets/images/logo-mini.svg"></svg-icon>
        </span>
    </div>

</div>
