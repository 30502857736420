<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Поиск</h3>
        </div>
        <div class="search-page pt-25" id="scrollContainer">
            <input class="main-input" placeholder="Введите текст для поиска">
            <h3>Для вас</h3>
            <div class="users-search">
                <div class="user">
                    <img src="assets/tested/one.png" alt="">
                    <div class="info">
                        <img src="assets/tested/one.png" alt="">
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasdasdfasdfasdfasdf</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="user">
                    <img src="assets/tested/one.png" alt="">
                    <div class="info">
                        <img src="assets/tested/one.png" alt="">
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="user">
                    <img src="assets/tested/one.png" alt="">
                    <div class="info">
                        <img src="assets/tested/one.png" alt="">
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
            <h3>Трансляции</h3>
            <div class="broadcasts-search">
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
                <div class="broadcast">
                    <img src="assets/tested/one.png" alt="">
                    <div class="broadcast-info">
                        <div class="views">
                            <span>
                                <svg-icon src="assets/icons/view.svg"></svg-icon> 173
                            </span>
                        </div>
                        <div class="user-info">
                            <b>VICTORIA_asdasdasdasd</b> <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                        </div>
                    </div>
                </div>
            </div>
            <h3>Пост</h3>
            <div class="post-search">
                <div class="post">
                    <img src="assets/tested/one.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
