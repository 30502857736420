<div class="profile" id="scrollContainer">
    <div class="three-grid mb-20">
        <div class="money-container" [ngClass]="type === 'in' ? 'active':''" (click)="type = 'in'">
            <span>
                <span class="m-0">
                € <br>
                    {{authService.currentUser.coin.toLocaleString()}}
                </span>
                <span class="text-more color-disabled">
                        нажмите чтобы пополнить
                 </span>
            </span>
            <span class="button-bottom">
                <span>Баланс</span>
                <svg-icon *ngIf="type === 'in'" src="assets/icons/arrow-down.svg"></svg-icon>
                <svg-icon *ngIf="type !== 'in'" src="assets/icons/arrow-right.svg"></svg-icon>
            </span>
        </div>
        <div class="money-container" [ngClass]="type === 'out' ? 'active':''" (click)="type = 'out'">
            <span>
                <span class="m-0">
                € <br>
                    {{authService.currentUser.coin.toLocaleString()}}
                </span>
                <span class="text-more color-disabled">
                        В этом месяце
                 </span>
            </span>
            <span class="button-bottom">
                <span>Вы заработали</span>
                <svg-icon *ngIf="type === 'out'" src="assets/icons/arrow-down.svg"></svg-icon>
                <svg-icon *ngIf="type !== 'out'" src="assets/icons/arrow-right.svg"></svg-icon>
            </span>
        </div>
        <div class="money-container" [ngClass]="type === 'history' ? 'active':''" (click)="type = 'history'">
            <span>
                <span class="m-0">
                € <br>
                    {{authService.currentUser.coin.toLocaleString()}}
                </span>
                <span class="text-more color-disabled">
                        Подписка на @hardcorelite
                 </span>
            </span>
            <span class="button-bottom">
                <span>История</span>
                <svg-icon *ngIf="type === 'history'" src="assets/icons/arrow-down.svg"></svg-icon>
                <svg-icon *ngIf="type !== 'history'" src="assets/icons/arrow-right.svg"></svg-icon>
            </span>
        </div>
    </div>
    <div class="container-payment" *ngIf="type === 'in'">
        <h3 class="m-0">Пополнение</h3>
        <input class="main-input mt-20" placeholder="Сумма пополнения">
        <div class="three-grid mt-20">
            <div class="shadow-button">
                <p>Оплатить картой</p>
            </div>
            <div class="shadow-button">
                <p>Оплатить картой</p>
            </div>
            <button class="primary-button">
                Продолжить
            </button>
        </div>
    </div>
    <div class="container-payment" *ngIf="type === 'out'">
        <h3 class="m-0">Май 2024</h3>
        <table class="main-table ">
            <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Описание</th>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
        </table>
        <p class="download-more m-0 color-disabled">Загрузить еще</p>
    </div>
    <div class="container-payment" *ngIf="type === 'history'">
        <h3 class="m-0">Май 2024</h3>
        <table class="main-table ">
            <tr>
                <th>Дата</th>
                <th>Сумма</th>
                <th>Описание</th>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
            <tr>
                <td>12.05.2024 17:52</td>
                <td>+ 500</td>
                <td>@hardcorelite купил Вашу коллекцию “So Cute”. </td>
            </tr>
        </table>
        <p class="download-more m-0 color-disabled">Загрузить еще</p>
    </div>
</div>
