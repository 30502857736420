<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>Flicks</h3>
            <div class="button-header" [routerLink]="'/createFlick'">Новый Flick</div>
        </div>
        <div class="flicks-page pt-25" id="scrollContainer">
            <div class="flicks">
                <ng-container *ngFor="let row of helper.flicks">
                    <div class="flick" >
                        <div class="video-container">
                            <div class="video" >
                                <video
                                        [id]="'media'+row.id"
                                        inViewport
                                        [inViewportOptions]="{ threshold: [0, 0.5], partial: true }"
                                        (inViewportAction)="onIntersection($event)"
                                        [muted]="muted"
                                        [src]="helper.cdn + 'videos/'+ row.user_id + '/' + row.flick_link" playsinline loop
                                ></video>
                                <div class="other-info" (mousedown)="videoPaused($event,row.id)" (mouseup)="videoUnPaused($event,row.id)">
                                    <div class="buttons-video" (click)="changeVideoAudio($event,row.id)">
                                        <div class="mute-video" [ngClass]="mutedAnimation ? 'mutedAnimation':''">
                                            <svg-icon src="assets/icons/mute-video.svg" *ngIf="muted"></svg-icon>
                                            <svg-icon src="assets/icons/unmute-video.svg" *ngIf="!muted"></svg-icon>
                                        </div>
                                    </div>
                                    <div class="flick-info">
                                        <div class="user-info" (click)="helper.viewUser(row.username,$event)">
                                            <img [src]="helper.returnAvatar(row.user_id,row.avatar)"  alt="avatar">
                                            <p>{{row.firstname}} {{row.lastname}}</p>
                                            <svg-icon src="assets/icons/confirmed.svg"></svg-icon>
                                        </div>
                                        <div class="flick-about" *ngIf="row.description">
                                            {{row.description}}
                                        </div>
                                        <div class="flick-ads">
                                            <span><b>Стань фанатом этого автора</b> 30 фото и 7 видео</span>
                                            <svg-icon src="assets/icons/right-ads.svg"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="video-buttons">
                            <div class="button-like with-text" [ngClass]="[helper.detectLikedFlicks(row.id) ? 'active':'',row.likes ? 'liked':'not-liked']" (click)="helper.likeFlick(row.id,$event)">
                                <svg-icon src="assets/icons/heart.svg"></svg-icon>
                                <span class="count-like" *ngIf="row.likes">{{row.likes}}</span>
                            </div>
                            <div class="button-like" [ngClass]="helper.detectSubscriber(row.user_id) ? 'active':''" (click)="helper.subscribeUser(row.user_id,$event)">
                                <svg-icon *ngIf="!helper.detectSubscriber(row.user_id)" src="assets/icons/favorite.svg"></svg-icon>
                                <svg-icon *ngIf="helper.detectSubscriber(row.user_id)" src="assets/icons/unfavorite.svg"></svg-icon>
                            </div>
                            <span class="number" *ngIf="row.subscribers">{{row.subscribers}}</span>
                            <div class="button-like" (click)="helper.viewFlick(row,$event)">
                                <svg-icon src="assets/icons/comment.svg"></svg-icon>
                            </div>
                            <span class="number" *ngIf="row.comments && row.comments.length">{{row.comments.length}}</span>
                            <div class="button-like" (click)="helper.snackBarMessage('Скопировано','Закрыть')">
                                <svg-icon src="assets/icons/repost.svg"></svg-icon>
                            </div>
                            <div class="button-like">
                                <svg-icon src="assets/icons/info.svg"></svg-icon>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
            <!--new-->
        </div>
    </div>
</div>
