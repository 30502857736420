<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>RTMP TEST <button class="primary-button" (click)="playVideo()">PLAY</button></h3>
        </div>
        <div class="rtmp-page pt-25" id="scrollContainer">
            <video id="videoElement"></video>
        </div>
    </div>
</div>
