<div class="profile" id="scrollContainer">
    <h3>Номер телефона</h3>
    <div class="button-with-next-page" (click)="helper.goToPage('changePhone')">
        <p>{{authService.currentUser.phone | mask: '+0 (000) 000 00 00'}}</p>
        <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
    </div>
    <h3>Мои устройства</h3>
    <div class="button-with-next-page" (click)="helper.goToPage('myDevices')">
        <p>История активности</p>
        <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
    </div>
    <div class="button-with-next-page mt-20" (click)="logoutAllDevices()">
        <p class="danger-color">Завершить другие сеансы</p>
    </div>
    <div class="button-with-next-page mt-20" (click)="helper.goToPage('faqhackacc')" >
        <p>Что делать, если аккаунт взломали</p>
        <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
    </div>
    <h3>Действия с аккаунтом</h3>
    <div class="button-with-next-page mt-20" (click)="helper.goToPage('delacc')">
        <p>Удаление аккаунта</p>
        <svg-icon src="assets/icons/arrow-right.svg"></svg-icon>
    </div>
</div>
