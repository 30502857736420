import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from "../services/helper.service";
import {AnimationOptions} from "ngx-lottie";
import {NavigationEnd, Router} from "@angular/router";
import {takeUntil} from "rxjs";
@Component({
  selector: 'app-popular',
  templateUrl: './popular.component.html',
  styleUrls: ['./popular.component.scss']
})
export class PopularComponent implements OnInit,OnDestroy {
  flicks:any[]=[];
  users:any[]=[];
  posts:any[]=[];
  populars:any[]=[];
  muted:boolean = true;
  isLoading:boolean = true;
  emptyAdsAnim: AnimationOptions = {
    loop: true,
    path: 'assets/lottie/loading.json',
  };
  constructor(
      private router: Router,
      public helper:HelperService
  ) {
  }

  async ngOnInit() {
    console.warn('canLoad')
    this.flicks = this.helper.popular.flicks;
    this.users = this.helper.popular.users;
    this.posts = this.helper.popular.posts;
    this.flicks = this.flicks.map(obj => ({ ...obj, isFlick: true,isUser: false,isPost: false, }))
    this.users = this.users.map(obj => ({ ...obj, isFlick: false,isUser: true,isPost: false, }))
    this.posts = this.posts.map(obj => ({ ...obj, isFlick: false,isUser: false,isPost: true, }))
    this.populars = this.flicks.concat(this.users, this.posts);
    this.shuffle(this.populars)
    this.isLoading = false;
  }
  ngOnDestroy(){
    this.isLoading = true;
  }
  returnClass(isUser,isFlick,isPost){
    if (isUser){
      return 'square'
    }else if(isPost){
      //return 'wide'
      return 'square'
    }else {
      return 'tall'
    }
  }
  shuffle(array) {
    let currentIndex = array.length;
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }
}
