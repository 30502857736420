<div class="send-money">
    <h3 class="m-0">Отправить {{returnType()}}</h3>
    <div class="user-container mt-20">
        <img [src]="helper.returnAvatar(userinfo.id,userinfo.avatar)" alt="">
        <div class="user-info">
            <h3>{{userinfo.firstname}} {{userinfo.lastname}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
            <p>@{{userinfo.name}}</p>
        </div>
    </div>
    <input type="number" class="main-input mt-20" placeholder="€ Сумма" [(ngModel)]="amount">
    <textarea class="main-textarea mt-20" placeholder="Сообщение (опционально)" [(ngModel)]="message"></textarea>
    <button class="primary-button mt-20">Отправить</button>
    <span class="fs-15 danger-color mt-10" *ngIf="+amount > +authService.currentUser.coin ">На Вашем счету недостаточно средств.</span>
    <span class="fs-15 mt-10">Вы можете пополнить аккаунт <span class="link">здесь.</span></span>
</div>
