<div class="wrapper">
    <div class="main-page" id="scrollContainer">
        <div class="header">
            <h3>Ваша трансляция <span class="status" *ngIf="!isStream">Сейчас не активна</span><span class="status danger-color" *ngIf="isStream">Сейчас активна</span></h3>
            <div class="button-header-grey" (click)="back()" *ngIf="!isStream">Отмена</div>
        </div>

        <div class="create-broadcasts-page pt-25" *ngIf="typeBroadcast === 'web'">

            <div class="camera-settings">
                <div class="video-container-info" *ngIf="isStream">
                    <div class="viewers">Зрителей: {{users ? users.length : 0}}</div>
                    <div class="video-container-buttons">
                        <button class="danger-button" (click)="disableMicrophone()"><svg-icon src="assets/icons/microphone-on.svg" *ngIf="!muted"></svg-icon></button>
                        <button class="danger-button" (click)="helper.copyMessage('https://coc.lol/b/'+broadcaster.room_id)"><svg-icon src="assets/icons/link.svg"></svg-icon>Ссылка на трансляцию</button>
                    </div>
                </div>
                <video *ngIf="isStream" autoplay [id]="streamAudio[0].id" [muted]="streamAudio[0].type === 'local'" [srcObject]="streamAudio[0].url"></video>
                <video *ngIf="!isStream" autoplay muted id="stream"></video>
            </div>

            <div class="chat-broadcast" *ngIf="isStream">
                <div class="chat">
                    <div class="donate" style="background: linear-gradient(90deg, #EC3131 70%, #0B0B0D 50%);">
                        <p>На Дайсон: €700</p>
                    </div>
                    <div class="chat-messages">
                        <ng-container *ngFor="let row of messages">
                            <div class="message" *ngIf="row.type === 1">
                                <span>{{row.name}}:</span> {{row.message}}
                            </div>
                            <div class="message-connect" *ngIf="row.type === 2">
                                {{row.name}}: присоединился(ась)
                            </div>
                        </ng-container>
                    </div>
                    <div class="input-chat">
                        <svg-icon src="assets/icons/emojis.svg"></svg-icon>
                        <input class="main-input" type="text" placeholder="Написать в чат" [(ngModel)]="message" (keyup.enter)="sendMessageBroadCast()">
                        <svg-icon src="assets/icons/send.svg" (click)="sendMessageBroadCast()"></svg-icon>
                    </div>
                </div>
                <button class="danger-button"  (click)="endBroadcast()">Остановить трансляцию</button>
            </div>

            <div class="more-information" *ngIf="!isStream">
                <h3 style="margin-top: 0;">Дополнительно</h3>
                <div class="info">
                    <div class="settings">
                        <div class="small-text">* ”NSFW контент” на данном ресурсе подразумевает изображения или видео содержащие исключительно наготу, порнографию и сексуальные сцены.</div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb1" [(ngModel)]="nsfw" [checked]="nsfw" >
                            <label for="cb1">NSFW контент * </label>
                        </div>
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb2" [(ngModel)]="private" [checked]="private">
                            <label for="cb2">Включить приватные трансляции </label>
                        </div>
                        <input placeholder="Сумма приватной комнаты" [disabled]="!private" [(ngModel)]="private_price" class="main-input" type="number" style="margin-top: 15px">
                        <div class="checkbox-setting">
                            <input type="checkbox" id="cb3" [(ngModel)]="donate" [checked]="donate">
                            <label for="cb3">Донат </label>
                        </div>
                        <input placeholder="Цель доната (На чай)" [disabled]="!donate" [(ngModel)]="donate_name" class="main-input" type="text" style="margin-top: 15px">
                        <input placeholder="Целевая сумма доната (1000)" [disabled]="!donate" [(ngModel)]="donate_price" class="main-input" type="number" style="margin-top: 15px">
                    </div>
                </div>
                <h3 style="margin-top: 0;">Информация</h3>
                <div class="info">
                    <input placeholder="Название трансляции" class="main-input" type="text" [(ngModel)]="name">
                    <input placeholder="Теги" class="main-input" type="text" style="margin-top: 15px"  [(ngModel)]="tags">
                    <textarea placeholder="Описание трансляции" class="main-textarea" rows="5" style="margin-top: 15px"  [(ngModel)]="description"></textarea>
                </div>
                <button class="primary-button" (click)="createBroadcast()">Запустить трансляцию</button>
            </div>

        </div>

        <div class="create-broadcasts-page pt-25" *ngIf="typeBroadcast === 'obs'">
            <div class="video-obs-caontainer">
                <ng-container *ngIf="!isStream">
                    <h3>Настройка программы для трансляции</h3>
                    <p>Перейдите в настройки вашей программы для трансляции и вставьте ссылку URL и ключ потока.</p>
                    <p>URL: <b (click)="helper.copyMessage('rtmp://coc.lol:1935/live')">rtmp://coc.lol:1935/live <svg-icon src="assets/icons/copy.svg"></svg-icon></b></p>
                    <p>Ключ потока: <b (click)="helper.copyMessage(authService.currentUser.media_server_key)">{{authService.currentUser.media_server_key}} <svg-icon src="assets/icons/copy.svg"></svg-icon></b></p>
                    <small class="danger-color">Никогда и никому не передавайте ссылку и ключ потока! С их помощью любой желающий может провести трансляцию от вашего имени.</small>
                    <h3>Информация</h3>
                    <div class="info">
                        <input placeholder="Название трансляции" class="main-input" type="text" [(ngModel)]="name">
                        <input placeholder="Теги" class="main-input" type="text" style="margin-top: 15px"  [(ngModel)]="tags">
                        <textarea placeholder="Описание трансляции" class="main-textarea" rows="5" style="margin-top: 15px"  [(ngModel)]="description"></textarea>
                    </div>
                    <button class="primary-button" (click)="createBroadcast()">Запустить трансляцию</button>
                </ng-container>
                <ng-container *ngIf="isStream">
                    <button class="primary-button" (click)="endBroadcast()">Остановить трансляцию</button>
                </ng-container>
                <video id="videoElement"></video>
            </div>
        </div>

    </div>
</div>
<ng-template #selectTypeBroadcastDialog>
    <div class="select-type-broadcast">
        <h3>Создать трансляцию</h3>
        <p>Выберите тип трансляции</p>
        <div class="type-broadcast" (click)="selectTypeBroadcast('web')" [mat-dialog-close]="true">
            <span><svg-icon src="assets/icons/web-video.svg"></svg-icon></span>
            <span>
                Веб камера <br>
                <small>Проведите эфир, используя веб-камеру своего компьютера или телефона</small>
            </span>
        </div>
        <div class="type-broadcast" (click)="selectTypeBroadcast('web')" [mat-dialog-close]="true">
            <span><svg-icon src="assets/icons/web-audio.svg"></svg-icon></span>
            <span>
                Аудиотрансляция <br>
                <small>Транлсяция с обсуждением БЕЗ видео.Вы сможете общаться и разрешать людям подключаться к вашей трансляции.</small>
            </span>
        </div>
        <div class="type-broadcast" (click)="selectTypeBroadcast('obs')" [mat-dialog-close]="true">
            <span><svg-icon src="assets/icons/app.svg"></svg-icon></span>
            <span>
                Приложение <br>
                <small>Для профессиональных трансляций через OBS и другие программы используйте этот вариант</small>
            </span>
        </div>
    </div>
    <div class="close-button" [mat-dialog-close]="true" (click)="back()">
        <svg-icon src="assets/icons/close.svg"></svg-icon>
    </div>
</ng-template>
