import { Component, OnInit } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {base64ToFile} from "ngx-image-cropper";
import {AuthenticationService} from "../services/authentication.service";
import {HelperService} from "../services/helper.service";


@Component({
  selector: 'app-create-collections',
  templateUrl: './create-collections.component.html',
  styleUrls: ['./create-collections.component.scss']
})
export class CreateCollectionsComponent implements OnInit {
  loading:boolean = false;
  muted:boolean = true;
  canExit:boolean = false;
  cover:any;
  cover_file:any;
  files:any[] = [];
  data = {
    about:'',
    name:'',
    price:0,
    nfsw:false,
  }
  constructor(
      public dialog: MatDialog,
      public helper: HelperService
      ) { }

  ngOnInit(): void {
  }

  async canDeactivate() {
    if (!this.canExit){
      let dialogRef = this.dialog.open(DialogComponent, {
        disableClose: true,
        panelClass: 'main-modal',
        data:{header:'Подтверждение',text:'Ваша коллекция не будет сохранена! Вы уверены что хотите покинуть страницу создания коллекции?',
          buttons:[{text:'Нет',status:false,},{text:'Да',status:true,},]
        }
      });
      return await dialogRef.afterClosed().toPromise();
    }
  }

  setNSFW(ev){
    this.data.nfsw = ev.target.checked
  }
  attachFile(){
    let fileInput = document.getElementById('fileInput');
    this.clearFileInput(document.getElementById('fileInput'));
    if (fileInput) fileInput.click();
  }

  onChangeFile(ev) {
    let file = ev.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      this.cover = reader.result
      this.cover_file = file
    }
    reader.readAsDataURL(file);
  }
  clearFileInput(ctrl) {
    try {
      ctrl.value = null;
    } catch(ex) { }
    if (ctrl.value) {
      ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
    }
  }
  clearImage(){
    this.cover = null;
    this.cover_file = null;
  }
  deleteFile(index){
    this.files.splice(index,1)
  }
  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(file);
          console.log(this.files.length)
          const reader = new FileReader();
          reader.onloadend = () => {
            this.files.push({
              preview:reader.result,
              file:file,
              size:file.size,
              type:file.type.split('/')[0],
            });
          }
          reader.readAsDataURL(file);
          console.log(this.files)
        });
      }
      //else {
        // It was a directory (empty directories are added, otherwise only files)
        //const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      //}
    }
  }

  fileOver(event){
    //console.log(event);
  }
  fileLeave(event){
    //console.log(event);
  }

  async createCollection(){
    if (this.data.name && this.data.about && this.data.price > 0 && this.cover_file && this.files.length){
      this.loading = true;
      let formData = new FormData();
      formData.append("cover", this.cover_file);
      for (let row of this.files){
        formData.append("files", row.file);
      }
      formData.append('data',JSON.stringify(this.data))
      const res = await fetch("https://coc.lol:9125/main/createCollection", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': 'Bearer ' + AuthenticationService.jwt,
          'Authorizationuuid': `${AuthenticationService.uuid}`,
        }
      });
      let data = await res.json();
      if (data.status){
        this.loading = false;
        this.canExit = true;
        // @ts-ignore
        await this.router.navigate('/'+this.authService.currentUser.name, { replaceUrl: true });
      }
    }else {
      await this.helper.alertMessage('Ошибка','Пожалуйста заполните все поля')
    }
  }
}
