import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  type:string = 'in';
  constructor(
      public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

}
