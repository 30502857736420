import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";
declare var flvjs: any;
@Component({
  selector: 'app-rtmp',
  templateUrl: './rtmp.component.html',
  styleUrls: ['./rtmp.component.scss']
})
export class RtmpComponent implements OnInit {
  live:any;
  flvPlayer:any;
  constructor(
      public helper: HelperService
  ) { }

  async ngOnInit() {
    this.live = await this.helper.getApiStreams().toPromise();
  }
  playVideo(){
    let videoElement = document.getElementById('videoElement');
    this.flvPlayer = flvjs.createPlayer({
      type: 'flv',
      url: 'https://coc.lol:8443/live/coclol.flv'
    });
    this.flvPlayer.attachMediaElement(videoElement);
    this.flvPlayer.load();
    this.flvPlayer.play();
    console.log(this.live)
  }

}
