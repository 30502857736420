<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3><svg-icon class="back-button" src="assets/icons/arrow-left.svg" (click)="back()"></svg-icon>Цены и пакеты</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <h3 class="m-0">Чаевые</h3>
                <small class="small-text color-disabled">Получайте дополнительный доход.</small>
                <div class="checkbox-setting mt-20">
                    <input type="checkbox" id="cb1">
                    <label for="cb1">Включить чаевые </label>
                </div>

                <hr class="main-hr mt-20 mb-20">

                <h3 class="m-0">Пакеты подписок</h3>
                <small class="small-text color-disabled">Предложите одну или несколько подписок на месяц в виде пакета. Минимум € 4.99 EURO или бесплатно.</small>
                <small class="small-text color-disabled">Вы должны <span class="link">добавить банковский счет или платежную информацию</span>, прежде чем вы сможете установить свою цену или принять чаевые.</small>
                <div class="three-grid mt-20">
                    <div class="shadow-button">
                        <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                        <p>Добавить пакет</p>
                    </div>
                    <div class="shadow-button">
                        <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                        <p>Добавить пакет</p>
                    </div>
                    <div class="shadow-button">
                        <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                        <p>Добавить пакет</p>
                    </div>
                </div>
                <hr class="main-hr mt-20 mb-20">

                <h3 class="m-0">Промо-компания</h3>
                <small class="small-text color-disabled">Предложите скидку на свой профиль для ограниченного числа новых или уже истекших подписчиков.</small>
                <div class="four-grid mt-20">
                    <div class="shadow-button">
                        <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                        <p>Добавить</p>
                    </div>
                </div>
                <hr class="main-hr mt-20 mb-20">

                <h3 class="m-0">Промо-пост</h3>
                <small class="small-text color-disabled">Продвиньте свой пост в общей ленте, тем самым увеличив показы потенциальным покупателям.</small>
                <div class="four-grid mt-20">
                    <div class="shadow-button">
                        <svg-icon src="assets/icons/acc-add.svg"></svg-icon>
                        <p>Добавить</p>
                    </div>
                </div>
            </div>
            <div class="profile-types">
                <div class="profile-types-info">
                    <div class="profile-type-button active" (click)="helper.settingType = 'profile';back()">Профиль</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'account';back()">Учетная запись</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'safety';back()">Конф. и безопасность</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'payment';back()">Платежи</div>
                    <div class="profile-type-button" (click)="helper.settingType = 'info';back()">Информация</div>
                </div>
            </div>

        </div>

    </div>
</div>
