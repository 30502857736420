<div class="wrapper">
    <div class="main-page">
        <div class="header">
            <h3>
                <svg-icon *ngIf="viewChat" class="back-button" src="assets/icons/arrow-left.svg" (click)="backButton()"></svg-icon>
                {{!viewChat ? 'Сообщения':'Диалог с ' + chat_name}}
                <span *ngIf="viewChat && printing" class="message-text printing"></span>
                <span class="button-header-width-page" [matMenuTriggerFor]="menuAfterHeader" *ngIf="viewChat"><svg-icon src="assets/icons/more.svg"></svg-icon></span>
                <mat-menu #menuAfterHeader="matMenu" [overlapTrigger]="true" xPosition="before" class="more-panel-header">
                    <div class="more-panel-buttons">
                        <div (click)="helper.viewAttach(messages,$event)">
                            <svg-icon src="assets/icons/attach.svg"></svg-icon>
                            Показать вложения
                        </div>
                        <div (click)="helper.subscribeUser(user_id,$event)">
                            <svg-icon *ngIf="!helper.detectSubscriber(user_id)" src="assets/icons/favorite.svg"></svg-icon>
                            <svg-icon *ngIf="helper.detectSubscriber(user_id)" src="assets/icons/unfavorite.svg"></svg-icon>
                            {{helper.detectSubscriber(user_id) ? 'Перестать отслеживать':'Отслеживать'}}
                        </div>
                        <div class="more-panel-button-danger"><svg-icon src="assets/icons/delete.svg"></svg-icon>Удалить диалог</div>
                        <div class="more-panel-button-danger"><svg-icon src="assets/icons/warning.svg"></svg-icon>Пожаловаться!</div>
                        <div class="more-panel-button-danger"><svg-icon src="assets/icons/block.svg"></svg-icon>Заблокировать</div>
                    </div>
                </mat-menu>
            </h3>
            <div class="button-header" (click)="createMessages()" *ngIf="!viewChat">Новое сообщение</div>
        </div>

        <div class="chats-page">

            <div class="chats" *ngIf="!viewChat">

                <ng-container *ngFor="let chat of helper.chats | orderByImpure: ['lastmessage'] | reverse">
                    <div class="chat" (click)="goToChat(chat)">
                        <div class="main-chat-user-info">
                            <img [src]="chat.chat_image ? helper.returnAvatar(chat.user_id,chat.chat_image) : '../assets/images/noimage.jpg'" alt="">
                            <span class="online" *ngIf="chat.status"></span>
                            <div class="chat-user-info">
                                <span class="chat-user-name">{{chat.chat_name}}<svg-icon src="assets/icons/confirmed.svg"></svg-icon> <span class="doth"></span> <span class="date-message">{{helper.returnDate(chat.messages[0].date_create)}}</span> <svg-icon src="assets/icons/more.svg"></svg-icon></span>
                                <span class="chat-user-messages"><b *ngIf="chat.messages[0].user_id === authService.currentUser.id">Вы: </b> {{chat.messages[0].text ? chat.messages[0].text:'Медиафайл'}}</span>
                            </div>
                        </div>
                        <div class="badge-messages" *ngIf="detectunreadMessages(chat.messages)">{{detectunreadMessages(chat.messages)}}</div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!helper.chats.length">
                    <div class="no-items">
                        <svg-icon src="assets/images/logo.svg"></svg-icon>
                        Напишите первым или
                        дождитесь сообщений
                    </div>
                </ng-container>

            </div>
            <ng-container *ngIf="viewChat">
                <div class="loading-chat" *ngIf="isLoading">
                    <ng-lottie [options]="helper.emptyAdsAnim"></ng-lottie>
                </div>
                <div class="view-chat" *ngIf="!isLoading">

                    <div class="chat-empty" *ngIf="!messages.length">
                        <img [src]="chat_avatar ? helper.returnAvatar(user_id,chat_avatar) : '../assets/images/noimage.jpg'"  alt="">
                        <h3>{{chat_name}} <svg-icon src="assets/icons/confirmed.svg"></svg-icon></h3>
                        <span>@{{chat_username}}</span>
                        <h3>Начните общение</h3>
                    </div>

                    <div class="chat-messages" [ngClass]="files.length ? 'padding-plus':''" *ngIf="messages.length" #chatContainer id="scrollContainer">

                        <ng-container *ngFor="let message of messages;let i = index;">

                            <ng-container *ngIf="i === 0">
                                <div class="system-date">
                                    <span>{{helper.returnCurrentDate(message.date_create)}}</span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="i > 0">
                                <div class="system-date" *ngIf="helper.returnCurrentDate(message.date_create) !== helper.returnCurrentDate(messages[i - 1].date_create)">
                                    <span>{{helper.returnCurrentDate(message.date_create)}}</span>
                                </div>
                            </ng-container>

                            <div [ngClass]="message.user_id !== authService.currentUser.id ? 'message-left':'message-right'" (mouseover)="viewMoreMessage(true,message.id)" (mouseout)="viewMoreMessage(false,message.id)">

                                <img *ngIf="message.user_id !== authService.currentUser.id" [src]="chat_avatar ? helper.returnAvatar(user_id,chat_avatar) : '../assets/images/noimage.jpg'" alt="">

                                <div class="message-detail" [matMenuTriggerFor]="menuBefore" *ngIf="message.user_id === authService.currentUser.id" [id]="message.id">
                                    <svg-icon src="assets/icons/more-vertical.svg"></svg-icon>
                                </div>
                                <mat-menu #menuBefore="matMenu" [overlapTrigger]="true" xPosition="before" class="more-panel-chat-right">
                                    <div class="more-panel-buttons">
                                        <div (click)="deleteMessage(message.id,chat_id)">Отменить отправку</div>
                                        <div class="more-panel-button-danger">Пожаловаться!</div>
                                    </div>
                                </mat-menu>

                                <div class="message-info">

                                    <ng-container *ngIf="i === 0">
                                        <span class="message-date">{{helper.returnDateMessage(message.date_create)}} <span *ngIf="message.user_id === authService.currentUser.id && message.date_read"><span class="doth"></span>Прочитано</span></span>
                                        <span class="message-text" [ngClass]="!message.text ? 'p-0':''">
                                            <span class="images" *ngIf="message.file">
                                                <ng-container *ngIf="message.id < 1000000000">
                                                    <ng-container *ngFor="let row of helper.returnArray(message.file)" >
                                                        <span class="message-file" *ngIf="row.includes('png')">
                                                            <img (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)"  [src]="helper.cdn + 'chats/' + chat_id + '/'+row" alt="image">
                                                        </span>
                                                        <span class="message-file" *ngIf="row.includes('mp4')">
                                                            <video (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)"  [src]="helper.cdn + 'chats/' + chat_id + '/'+row" autoplay playsinline loop [muted]="muted"></video>
                                                        </span>
                                                        <span class="message-file-audio" *ngIf="row.includes('mp3')">
                                                            <svg-icon *ngIf="!helper.audioIsPlayed"  class="player" src="assets/icons/play.svg" (click)="helper.audioFile = helper.cdn + 'chats/' + chat_id + '/'+row;helper.playAudio()"></svg-icon>
                                                            <svg-icon *ngIf="helper.audioIsPlayed" class="player" src="assets/icons/stop.svg" (click)="helper.audioFile = helper.cdn + 'chats/' + chat_id + '/'+row;helper.pauseAudio()"></svg-icon>
                                                        </span>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="message.id > 1000000000">
                                                    <ng-container *ngFor="let file of message.file">
                                                        <span class="message-file" *ngIf="file.type === 'image'">
                                                            <img  [src]="file.preview" class="loading-mediafile" alt="image">
                                                            <svg-icon src="assets/icons/send-mediafile.svg"></svg-icon>
                                                        </span>
                                                        <span class="message-file" *ngIf="file.type === 'video'">
                                                            <video  [src]="file.preview" class="loading-mediafile" autoplay playsinline loop [muted]="muted"></video>
                                                            <svg-icon src="assets/icons/send-mediafile.svg"></svg-icon>
                                                        </span>
                                                    </ng-container>
                                                </ng-container>
                                            </span>
                                            <span *ngIf="message.text" [ngClass]="message.file ? 'mt-10':''">{{message.text}}</span>
                                        </span>
                                    </ng-container>

                                    <ng-container *ngIf="i > 0">
                                        <span class="message-date" *ngIf="message.user_id !== messages[i - 1].user_id || helper.returnDateMessage(message.date_create) !== helper.returnDateMessage(messages[i - 1].date_create)">{{helper.returnDateMessage(message.date_create)}} <span *ngIf="message.user_id === authService.currentUser.id && message.date_read"><span class="doth"></span>Прочитано</span></span>
                                        <span [ngClass]="[message.user_id !== messages[i - 1].user_id || helper.returnDateMessage(message.date_create) !== helper.returnDateMessage(messages[i - 1].date_create) ? 'message-text':'message-text-not-time',!message.text ? 'p-0 no-background no-border':'']">
                                            <span class="images" *ngIf="message.file">

                                                <ng-container *ngIf="message.id < 1000000000">
                                                    <ng-container *ngFor="let row of helper.returnArray(message.file)" >
                                                        <span class="message-file" *ngIf="row.includes('png')">
                                                            <img (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)"  [src]="helper.cdn + 'chats/' + chat_id + '/'+row" alt="image">
                                                        </span>
                                                        <span class="message-file" *ngIf="row.includes('mp4')">
                                                            <video (click)="helper.viewMediaFile(helper.cdn + 'chats/' + chat_id + '/'+row,$event)"  [src]="helper.cdn + 'chats/' + chat_id + '/'+row" autoplay playsinline loop [muted]="muted"></video>
                                                        </span>
                                                        <span class="message-file-audio" *ngIf="row.includes('mp3')">
                                                            <svg-icon *ngIf="!helper.audioIsPlayed"  class="player" src="assets/icons/play.svg" (click)="helper.audioFile = helper.cdn + 'chats/' + chat_id + '/'+row;helper.playAudio()"></svg-icon>
                                                            <svg-icon *ngIf="helper.audioIsPlayed" class="player" src="assets/icons/stop.svg" (click)="helper.audioFile = helper.cdn + 'chats/' + chat_id + '/'+row;helper.pauseAudio()"></svg-icon>
                                                        </span>
                                                    </ng-container>
                                                </ng-container>

                                                <ng-container *ngIf="message.id > 1000000000">
                                                    <ng-container *ngFor="let file of message.file">
                                                        <span class="message-file" *ngIf="file.type === 'image'">
                                                            <img  [src]="file.preview" class="loading-mediafile" alt="image">
                                                            <svg-icon src="assets/icons/send-mediafile.svg"></svg-icon>
                                                        </span>
                                                        <span class="message-file" *ngIf="file.type === 'video'">
                                                            <video  [src]="file.preview" class="loading-mediafile" autoplay playsinline loop [muted]="muted"></video>
                                                            <svg-icon src="assets/icons/send-mediafile.svg"></svg-icon>
                                                        </span>
                                                    </ng-container>
                                                </ng-container>

                                            </span>
                                            <span *ngIf="message.text" [ngClass]="message.file ? 'mt-10':''">{{message.text}}</span>
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="message-detail" [matMenuTriggerFor]="menuAfter" *ngIf="message.user_id !== authService.currentUser.id" [id]="message.id">
                                    <svg-icon src="assets/icons/more-vertical.svg"></svg-icon>
                                </div>
                                <mat-menu #menuAfter="matMenu" [overlapTrigger]="true" xPosition="after" class="more-panel-chat-left">
                                    <div class="more-panel-buttons">
                                        <div class="more-panel-button-danger">Пожаловаться!</div>
                                    </div>
                                </mat-menu>
                            </div>

                        </ng-container>

                    </div>
                    <div class="emojis" *ngIf="viewEmojis" [@fadeInOut]>
                        <div class="haader-emoji">
                            <svg-icon src="assets/icons/emojis/love.svg" (click)="scrollToGroup('Smileys & Emotion (face-smiling)')"></svg-icon>
                            <svg-icon src="assets/icons/emojis/pets.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/icecream.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/run.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/car.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/light.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/heart.svg"></svg-icon>
                            <svg-icon src="assets/icons/emojis/flags.svg" (click)="scrollToGroup('Flags')"></svg-icon>
                        </div>
                        <div class="emojis-char">
                            <div *ngFor="let row of emojis" [id]="row.group" (click)="addToComment(row.char)">{{row.char}}</div>
                        </div>
                    </div>
                    <div class="attached-files" *ngIf="files.length">
                        <ng-container *ngFor="let row of files;let i = index">
                            <div class="attached-file">
                                <img *ngIf="row.type==='image'" [src]="row.preview" alt="">
                                <video *ngIf="row.type==='video'" [src]="row.preview" autoplay playsinline loop [muted]="muted"></video>
                                <span class="delete-file-button-attach" (click)="deleteFile(i)">
                                    <svg-icon src="assets/icons/delete.svg" class="loading-send"></svg-icon>
                                </span>
                            </div>
                        </ng-container>
                        <svg-icon *ngIf="files.length < 5" class="addSvg" src="assets/icons/add.svg" (click)="attachFile()"></svg-icon>
                    </div>
                    <div class="input-chat">
                        <input id="fileInput" style="display:none" type="file" accept="image/png,image/jpg,image/heic, video/*, audio/mp3" multiple (change)="onChange($event)">
                        <svg-icon style="cursor: pointer;margin-right: 20px;margin-left: 0;" [style.color]="viewEmojis ? 'grey':'white'" (click)="viewEmogi()" src="assets/icons/emojis.svg"></svg-icon>
                        <svg-icon *ngIf="files.length < 5" style="cursor: pointer;margin-right: 20px;margin-left: 0;" class="attachFileButton" src="assets/icons/attach.svg" (click)="attachFile()"></svg-icon>
                        <input class="main-input" placeholder="Напишите сообщение" type="text" [(ngModel)]="message" (keyup.enter)="sendMessage()" (keyup)="printingMessage()">
                        <svg-icon src="assets/icons/send.svg" style="cursor: pointer" *ngIf="!sendMessageLoading" (click)="sendMessage()"></svg-icon>
                        <svg-icon src="assets/icons/loading.svg" class="loading-send" *ngIf="sendMessageLoading"></svg-icon>
                    </div>
                </div>
            </ng-container>
            <div class="chats-types">
                <div class="chats-types-info">
                    <div class="chats-type-button active">Все чаты</div>
                    <div class="chats-type-button">Непрочитанные <span class="chat-type-badge" *ngIf="helper.detectUnreadMessages()">{{helper.detectUnreadMessages()}}</span></div>
                    <div class="chats-type-button">Запрос</div>
                    <div class="chats-type-button">Избранные</div>
                    <div class="chats-type-button">Секстинг</div>
                    <div class="chats-type-button">ДикЧеккер</div>
                </div>
            </div>
        </div>
    </div>
</div>
