import { Component, OnInit } from '@angular/core';
import {HelperService} from "../services/helper.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-bectoauthor',
  templateUrl: './bectoauthor.component.html',
  styleUrls: ['./bectoauthor.component.scss']
})
export class BectoauthorComponent implements OnInit {
  step:number = 1;

  firstname:string = '';
  lastname:string = '';
  city:string = '';
  about:string = '';
  about_social:string = '';
  day:string = '';
  month:string = '';
  year:string = '';
  sex:string = '';
  country:string = '';
  type:number = 3;

  constructor(
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.firstname = this.authService.currentUser.firstname;
    this.lastname = this.authService.currentUser.lastname;
    if (this.authService.currentUser.verify){
      this.step = 3;
    }
  }
  setTypeCOntent(type){
    this.type = type;
  }

}
