import { Injectable } from '@angular/core';
import {BehaviorSubject, map, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {User} from "../../user";
import { DeviceUUID } from 'device-uuid';

const API_URL = 'https://coc.lol:9125/';
const TOKEN_KEY = 'coclol';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  static jwt: any;
  static uuid: any;
  authenticationState = new BehaviorSubject<boolean>(false);
  public currentUser: User | undefined;

  constructor(
      private http: HttpClient
  ) {
  }
  async checkToken() {
    const res = localStorage.getItem(TOKEN_KEY)
    if (res) {
      AuthenticationService.jwt = res;
      this.authenticationState.next(true);
    } else {
      this.authenticationState.next(false);
    }
  }
  isAuthenticated() {
    return this.authenticationState.value;
  }
  setJwt(jwt: string) {
    AuthenticationService.jwt = jwt;
    localStorage.setItem(TOKEN_KEY, jwt);
    this.authenticationState.next(true);
  }
  getUUID(){
    let du = new DeviceUUID().parse();
    let dua = [
      du.language,
      du.platform,
      du.os,
      du.cpuCores,
      du.isAuthoritative,
      du.silkAccelerated,
      du.isKindleFire,
      du.isDesktop,
      du.isMobile,
      du.isTablet,
      du.isWindows,
      du.isLinux,
      du.isLinux64,
      du.isMac,
      du.isiPad,
      du.isiPhone,
      du.isiPod,
      du.isSmartTV,
      du.pixelDepth,
      du.isTouchScreen
    ];
    return du.hashMD5(dua.join(':'));
  }
  updateJwt(jwt: string) {
    AuthenticationService.jwt = jwt;
    localStorage.setItem(TOKEN_KEY, jwt);
  }
  logout() {
    localStorage.removeItem(TOKEN_KEY);
    this.authenticationState.next(false);
    window.location.reload();
  }
  loginUser(email:string) {
    const sUrl = API_URL + 'main/loginUser';
    const body = JSON.stringify(  {
      email
    });
    return this.http.post<any>(sUrl, body);
  }
  loginUserPhone(phone:string) {
    const sUrl = API_URL + 'main/loginUserPhone';
    const body = JSON.stringify(  {
      phone
    });
    return this.http.post<any>(sUrl, body);
  }
  verifyCode(email:string,code:string) {
    const sUrl = API_URL + 'main/verifyCode';
    const body = JSON.stringify(  {
      email,code
    });
    return this.http.post<any>(sUrl, body);
  }
  verifyCodePhone(phone:string,code:string) {
    const sUrl = API_URL + 'main/verifyCodePhone';
    const body = JSON.stringify(  {
      phone,code
    });
    return this.http.post<any>(sUrl, body);
  }
  googleAuth(user) {
    const sUrl = API_URL + 'main/googleAuth';
    const body = JSON.stringify(  {
      user
    });
    return this.http.post<any>(sUrl, body);
  }
  checkSession(): Observable<false | User> {
    const sUrl = API_URL + 'main/checkSession';
    return this.http.get<any>(sUrl)
        .pipe(map(res => {
          console.log(res)
          if (res.user) {
            this.updateJwt(res.user.token);
            return new User(res.user);
          } else {
            console.log(res.error)
            return false;
          }
        }));
  }
  createPost(data) {
    const sUrl = API_URL + 'main/createPost';
    return this.http.post<any>(sUrl, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }
  getInfoRoom(roomid:string) {
    const sUrl = API_URL + 'main/getInfoRoom';
    const body = JSON.stringify(  {
      roomid
    });
    return this.http.post<any>(sUrl, body);
  }
}
