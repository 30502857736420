import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelperService} from "../services/helper.service";
import {Location} from "@angular/common";
import {Router} from "@angular/router";

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit,OnDestroy {

  constructor(
      private location: Location,
      private router: Router,
      public helper: HelperService
  ) { }
  backButton(){
    this.location.back();
  }
  async ngOnInit() {
    if (!this.helper.userInfoView){
      await this.router.navigate(['home']);
    }
    console.log(this.helper.userInfoView)
  }
  ngOnDestroy(): void {
    this.helper.userInfoView = null;
    console.log(this.helper.userInfoView)
  }

}
