import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {HelperService} from "../../services/helper.service";
import {AuthenticationService} from "../../services/authentication.service";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";

@Component({
  selector: 'app-change-phone',
  templateUrl: './change-phone.component.html',
  styleUrls: ['./change-phone.component.scss']
})
export class ChangePhoneComponent implements OnInit {
  phone:string = '';
  verifycode:string = '';
  sendcodeverify:boolean = false;
  constructor(
      private location: Location,
      public dialog: MatDialog,
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  back(){
    this.location.back();
  }
  ngOnInit(): void {

  }
  async changePhone(){
    const res = await this.helper.changePhone(this.phone).toPromise();
    if (!res.status){
      await this.helper.alertMessage('Ошибка','Номер телефона уже зарегистрирован! Проверьте правильно ли введен номер телефона?');
      this.sendcodeverify = false;
    }else {
      this.sendcodeverify = true;
    }
  }
  async delPhone(phone){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите удалить номер телефона?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const res = await dialogRef.afterClosed().toPromise();
    if (res){
      const res = await this.helper.deletePhone(phone).toPromise();
      if (res.status){
        if (phone === this.authService.currentUser.phone){
          const index = this.authService.currentUser.authdata.findIndex(e => e.phone === phone)
          if (index >= 0){
            this.authService.currentUser.authdata.splice(index)
            this.authService.currentUser.phone = this.authService.currentUser.authdata[0].phone;
          }
        }else {
          this.authService.currentUser.phone = this.phone
        }
      }else {
        await this.helper.alertMessage('Ошибка','Должен остаться хотя бы один активный номер теелфона');
      }
    }
  }
  async verifyNewPhone(){
    this.sendcodeverify = true;
    const res = await this.helper.verifyNewPhone(this.phone,this.verifycode).toPromise();
    if (res.status){
      this.sendcodeverify = false;
      this.authService.currentUser.phone = this.phone;
      this.verifycode = '';
      this.authService.currentUser.authdata.push({
        phone:'+7'+this.phone,
        date_create: new Date()
      })
      this.phone = '';
      await this.helper.alertMessage('Отлично','Номер телефона успешно добавлен.');
    }
  }

}
