import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {HelperService} from "../../services/helper.service";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
  email:string = '';
  verifycode:string = '';
  sendcodeverify:boolean = false;
  validateEmail:boolean = true;
  constructor(
      private location: Location,
      public dialog: MatDialog,
      public helper: HelperService,
      public authService: AuthenticationService
  ) { }

  back(){
    this.location.back();
  }
  ngOnInit(): void {

  }

  checkEmail(ev){
    let email = ev.target.value;
    let reEmail = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    this.validateEmail = reEmail.test(email) != false;
  }
  async changeEmail(){
    const res = await this.helper.changeEmail(this.email).toPromise();
    if (!res.status){
      await this.helper.alertMessage('Ошибка','Эл. адрес уже зарегистрирован! Проверьте правильно ли введен адрес электронной почты');
      this.sendcodeverify = false;
    }else {
      this.sendcodeverify = true;
    }
  }
  async delEmail(email){
    let dialogRef = this.dialog.open(DialogComponent, {
      disableClose: true,
      panelClass: 'main-modal',
      data:{header:'Подтверждение',text:'Вы действительно хотите удалить адрес электронной почты?',
        buttons:[{text:'Да',status:true,},{text:'Нет',status:false,},]
      }
    });
    const res = await dialogRef.afterClosed().toPromise();
    if (res){
      const res = await this.helper.deleteEmail(email).toPromise();
      if (res.status){
        if (email === this.authService.currentUser.email){
          const index = this.authService.currentUser.authdata.findIndex(e => e.email === email)
          if (index >= 0){
            this.authService.currentUser.authdata.splice(index)
            this.authService.currentUser.email = this.authService.currentUser.authdata[0].email;
            this.email = this.authService.currentUser.authdata[0].email;
          }
        }else {
          this.authService.currentUser.email = this.email
        }
      }else {
        await this.helper.alertMessage('Ошибка','Должен остаться хотя бы один активный адрес электронной почты');
      }
    }
  }
  async verifyNewEmail(){
    this.sendcodeverify = true;
    const res = await this.helper.verifyNewEmail(this.email,this.verifycode).toPromise();
    if (res.status){
      this.sendcodeverify = false;
      this.authService.currentUser.email = this.email;
      this.verifycode = '';
      this.validateEmail = true;
      this.authService.currentUser.authdata.push({
        email:this.email,
        date_create: new Date()
      })
      await this.helper.alertMessage('Отлично','Электронная почта успешно добавлена.');
    }
  }

}
