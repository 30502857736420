<div class="accordion">
    <div class="acc-header" (click)="setViewContainer()">
        <h3 [ngClass]="viewContainer ? 'acc-opened':''">{{data.header}}</h3>
        <svg-icon *ngIf="!viewContainer" src="assets/icons/acc-add.svg"></svg-icon>
        <svg-icon *ngIf="viewContainer" src="assets/icons/acc-del.svg"></svg-icon>
    </div>
    <div @heightInOut *ngIf="viewContainer" class="acc-content">
        {{data.content}}
    </div>
</div>
