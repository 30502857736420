<div class="wrapper">
    <div class="main-page">

        <div class="header" >
            <h3>Поддержка</h3>
        </div>

        <div class="profile-page pt-25">
            <div class="profile">
                <app-accordion [data]="{header:'Вопрос?',content:'Ответ'}"></app-accordion>
                <app-accordion [data]="{header:'Вопрос?',content:'Ответ'}"></app-accordion>
                <button class="danger-button mt-20 h-40" (click)="helper.openChat(this.helper.supportInfo)">Обратиться в поддержку</button>
            </div>
            <div class="profile-types">
                <app-recommended></app-recommended>
            </div>

        </div>

    </div>
</div>
